//Efeito de Clique

.shake-horizontal {
  -webkit-animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}

.click--Effect {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin: 0 auto;
  width: 70px;
  height: 70px;
  font-size: 52px;
  transition: all 0.2s ease-in;
  span[class^='ion'] {
    position: relative;
  }
  //start click effect
  &:before {
    content: '';
    background-color: aliceblue;
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: scale(0.001, 0.001);
  }
  &:focus {
    outline: 0;
    color: #fff;
    &:before {
      animation: effect_dylan 0.8s ease-out;
    }
  }
}
@keyframes effect_dylan {
  50% {
    transform: scale(1.5, 1.5);
    opacity: 0;
  }
  99% {
    transform: scale(0.001, 0.001);
    opacity: 0;
  }
  100% {
    transform: scale(0.001, 0.001);
    opacity: 1;
  }
}

.slide-in-blurred-bottom {
  -webkit-animation: slide-in-blurred-bottom 0.5s cubic-bezier(0.23, 1, 0.32, 1) both;
  animation: slide-in-blurred-bottom 0.5s cubic-bezier(0.23, 1, 0.32, 1) both;
}

.scale-in-ver-bottom {
  -webkit-animation: scale-in-ver-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-in-ver-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slide-in-fwd-center {
  -webkit-animation: slide-in-fwd-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-fwd-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.flip-in-hor-top {
  -webkit-animation: flip-in-hor-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: flip-in-hor-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

//regular
.fade-in {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.fade-in-fast {
  -webkit-animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

//steped-fade-in
.fade-in-0 {
  -webkit-animation: fade-in-right 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-right 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.fade-in-25 {
  -webkit-animation: fade-in-right 0.25s cubic-bezier(0.39, 0.575, 0.565, 1) 0.2s both;
  animation: fade-in-right 0.25s cubic-bezier(0.39, 0.575, 0.565, 1) 0.2s both;
}
.fade-in-50 {
  -webkit-animation: fade-in-right 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) 0.45s both;
  animation: fade-in-right 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) 0.45s both;
}
.fade-in-75 {
  -webkit-animation: fade-in-right 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) 0.7s both;
  animation: fade-in-right 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) 0.7s both;
}
.fade-in-100 {
  -webkit-animation: fade-in-right 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) 0.95s both;
  animation: fade-in-right 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) 0.95s both;
}

//2sec

.fade-in-right {
  -webkit-animation: fade-in-right 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-right 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.fade-in-left,
.fadeInLeft {
  -webkit-animation: fade-in-left 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-left 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.fade-in-fwd {
  -webkit-animation: fade-in-fwd 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-fwd 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.fade-in-bottom {
  -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

//regular
.fade-in-top {
  -webkit-animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.fade-in-top-fast {
  -webkit-animation: fade-in-top 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-top 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

//.5sec
.fade-in-top-half-sec {
  -webkit-animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 0.5s both;
  animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 0.5s both;
}

//.25sec
.fade-in-top-half-sec2 {
  -webkit-animation: fade-in-top 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) 0.5s both;
  animation: fade-in-top 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) 0.5s both;
}

//1sec
.fade-in-top-1sec {
  -webkit-animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 1s both;
  animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 1s both;
}

.heartbeat {
  -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
  animation: heartbeat 1.5s ease-in-out infinite both;
}

.heartbeat-5sec {
  -webkit-animation: heartbeat 1.5s ease-in-out 5s infinite both;
  animation: heartbeat 1.5s ease-in-out 5s infinite both;
}

.scale-in-center {
  -webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.scale-in-top {
  -webkit-animation: scale-in-top 0.3s ease-in-out both;
  animation: scale-in-top 0.3s ease-in-out both;
}

.text-pop-up-bottom {
  -webkit-animation: text-pop-up-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: text-pop-up-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.text-pop-up-bottom-delayed-half-sec {
  -webkit-animation: text-pop-up-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s both;
  animation: text-pop-up-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s both;
}

.pulsate-fwd,
.pulsateFwd {
  -webkit-animation: pulsate-fwd 0.5s ease-in-out infinite both;
  animation: pulsate-fwd 0.5s ease-in-out infinite both;
}
.pulsate-fwd-1 {
  -webkit-animation: pulsate-fwd 0.5s ease-in-out infinite 0.25s both;
  animation: pulsate-fwd 0.5s ease-in-out infinite 0.25s both;
}
.pulsate-fwd-2 {
  -webkit-animation: pulsate-fwd 0.5s ease-in-out infinite 0.5s both;
  animation: pulsate-fwd 0.5s ease-in-out infinite 0.5s both;
}
.pulsate-fwd-3 {
  -webkit-animation: pulsate-fwd 0.5s ease-in-out infinite 0.75s both;
  animation: pulsate-fwd 0.5s ease-in-out infinite 0.75s both;
}

.call-pulsate-fwd {
  -webkit-animation: pulsate-fwd 0.25s ease-in-out 2 both;
  animation: pulsate-fwd 0.25s ease-in-out 2 both;
}

.shadow-pop-tr {
  -webkit-animation: shadow-pop-tr 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) both;
  animation: shadow-pop-tr 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) both;
}

.blink1 {
  -webkit-animation: blink-1 0.6s both;
  animation: blink-1 0.6s both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-5-15 15:50:8
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation blink-1
 * ----------------------------------------
 */
@-webkit-keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
@keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2019-8-8 11:34:22
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shadow-pop-tr
 * ----------------------------------------
 */
@-webkit-keyframes shadow-pop-tr {
  0% {
    box-shadow: 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff,
      0 0 #ffffff;
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
  }
  100% {
    box-shadow: 1px -1px #ffffff, 2px -2px #ffffff, 3px -3px #ffffff, 4px -4px #ffffff, 5px -5px #ffffff;
    -webkit-transform: translateX(-5px) translateY(5px);
    transform: translateX(-5px) translateY(5px);
  }
}
@keyframes shadow-pop-tr {
  0% {
    box-shadow: 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff,
      0 0 #ffffff;
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
  }
  100% {
    box-shadow: 1px -1px #ffffff, 2px -2px #ffffff, 3px -3px #ffffff, 4px -4px #ffffff, 5px -5px #ffffff;
    -webkit-transform: translateX(-5px) translateY(5px);
    transform: translateX(-5px) translateY(5px);
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2019-3-18 21:43:9
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation pulsate-fwd
 * ----------------------------------------
 */
@-webkit-keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2019-3-18 21:26:18
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation text-pop-up-bottom
 * ----------------------------------------
 */
@-webkit-keyframes text-pop-up-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-shadow: none;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-shadow: 0px 10px 8px rgba(0, 0, 0, 0.33);
    opacity: 1;
    //   text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}
@keyframes text-pop-up-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-shadow: none;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-shadow: 0px 10px 8px rgba(0, 0, 0, 0.33);
    opacity: 1;
    //   text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2019-2-22 12:39:8
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-right
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation fade-in-left
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2019-2-18 21:26:9
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */
@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2019-2-18 21:20:38
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-fwd
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2019-2-18 21:18:34
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-in-hor-top
 * ----------------------------------------
 */
@-webkit-keyframes flip-in-hor-top {
  0% {
    -webkit-transform: rotateX(-80deg);
    transform: rotateX(-80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
}
@keyframes flip-in-hor-top {
  0% {
    -webkit-transform: rotateX(-80deg);
    transform: rotateX(-80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2019-2-18 21:13:54
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-blurred-bottom {
  0% {
    -webkit-transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
    transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide-in-blurred-bottom {
  0% {
    -webkit-transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
    transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2019-2-18 21:15:33
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-ver-bottom
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-ver-bottom {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    opacity: 1;
  }
}
@keyframes scale-in-ver-bottom {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2019-2-18 21:16:40
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-fwd-center
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
    transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
    transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2019-2-22 8:36:15
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2019-2-22 8:40:54
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-top
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-top {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 1;
  }
}
@keyframes scale-in-top {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2019-2-22 12:36:16
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-top
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2019-2-22 12:37:55
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink {
  /**
         * At the start of the animation the dot
         * has an opacity of .2
         */
  0% {
    opacity: 0.2;
  }
  /**
         * At 20% the dot is fully visible and
         * then fades out slowly
         */
  20% {
    opacity: 1;
  }
  /**
         * Until it reaches an opacity of .2 and
         * the animation can start again
         */
  100% {
    opacity: 0.2;
  }
}

.dialing span {
  /**
         * Use the blink animation, which is defined above
         */
  animation-name: blink;
  /**
         * The animation should take 1.4 seconds
         */
  animation-duration: 1.4s;
  /**
         * It will repeat itself forever
         */
  animation-iteration-count: infinite;
  /**
         * This makes sure that the starting style (opacity: .2)
         * of the animation is applied before the animation starts.
         * Otherwise we would see a short flash or would have
         * to set the default styling of the dots to the same
         * as the animation. Same applies for the ending styles.
         */
  animation-fill-mode: both;
}

.dialing span:nth-child(2) {
  /**
         * Starts the animation of the third dot
         * with a delay of .2s, otherwise all dots
         * would animate at the same time
         */
  animation-delay: 0.2s;
}

.dialing span:nth-child(3) {
  /**
         * Starts the animation of the third dot
         * with a delay of .4s, otherwise all dots
         * would animate at the same time
         */
  animation-delay: 0.4s;
}

/* ----------------------------------------------
 * Generated by Animista on 2019-4-1 11:18:34
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shake-horizontal
 * ----------------------------------------
 */
@-webkit-keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
}
@keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2019-12-5 14:49:59
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation cards
 * ----------------------------------------
 */

// Card Inner Down

@keyframes cardScaleIn {
  from {
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(0px, 5px);
  }
}

@keyframes cardScaleOut {
  0% {
    transform: translate(0px, 5px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

@-webkit-keyframes cardScaleIn {
  from {
    -webkit-transform: translate(0px, 0px);
  }
  to {
    -webkit-transform: translate(0px, 5px);
  }
}

@-webkit-keyframes cardScaleOut {
  0% {
    -webkit-transform: translate(0px, 5px);
  }
  100% {
    -webkit-transform: translate(0px, 0px);
  }
}

/**
 * ----------------------------------------
 * animation extras cards
 * ----------------------------------------
 */

// Card Inner Down

@keyframes cardExtrascaleIn {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100px);
  }
}

@keyframes cardExtrasScaleOut {
  0% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0);
  }
}

@-webkit-keyframes cardExtrasScaleIn {
  from {
    -webkit-transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(-100px);
  }
}

@-webkit-keyframes cardExtrasScaleOut {
  0% {
    -webkit-transform: translateY(-100px);
  }
  100% {
    -webkit-transform: translateY(0);
  }
}

/////Paralax

$n: 20;
$PI: 3.141592653589793;

@function number($value) {
  @if type-of($value) == 'number' {
    @return $value;
  } @else if type-of($value) != 'string' {
    $_: log('Value for `to-number` should be a number or a string.');
  }

  $result: 0;
  $digits: 0;
  $minus: str-slice($value, 1, 1) == '-';
  $numbers: (
    '0': 0,
    '1': 1,
    '2': 2,
    '3': 3,
    '4': 4,
    '5': 5,
    '6': 6,
    '7': 7,
    '8': 8,
    '9': 9
  );

  @for $i from if($minus, 2, 1) through str-length($value) {
    $character: str-slice($value, $i, $i);

    @if not(index(map-keys($numbers), $character) or $character == '.') {
      @return to-length(if($minus, -$result, $result), str-slice($value, $i));
    }

    @if $character == '.' {
      $digits: 1;
    } @else if $digits == 0 {
      $result: $result * 10 + map-get($numbers, $character);
    } @else {
      $digits: $digits * 10;
      $result: $result + map-get($numbers, $character) / $digits;
    }
  }

  @return if($minus, -$result, $result);
}

@function getCellPos($pos, $getter: false) {
  $q: $n;
  $p: $pos / $q;

  $decimalIndexInP: str-index('#{$p}', '.');

  $row: 0;
  $col: 0;

  @if $decimalIndexInP {
    $col: number(str-slice('#{$p}', $decimalIndexInP)) * $q;
    $row: floor($pos / $q) + 1;
  } @else {
    $col: $q;
    $row: floor($pos / $q);
  }

  @if $getter {
    @return round($col);
  } @else {
    @return $row;
  }
}

@function linearRangeInterpolation($x1, $x2, $x3, $y1, $y3) {
  @return ((($x2 - $x1) * ($y3 - $y1)) / ($x3 - $x1)) + $y1;
}

@function atan($x) {
  $i: 24;
  $sgn: 0;
  $a: 0;
  @if $x > 1 {
    $sgn: 1;
    $x: 1 / $x;
  } @else if $x < -1 {
    $sgn: -1;
    $x: 1 / $x;
  }
  @while $i > 0 {
    $a: ($i * $i * $x * $x) / (2 * $i + 1 + $a);
    $i: $i - 1;
  }
  @if $sgn > 0 {
    @return $PI / 2 - $x / (1 + $a);
  } @else if $sgn < 0 {
    @return -$PI / 2 - $x / (1 + $a);
  } @else {
    @return $x / (1 + $a);
  }
}

@function atan2($y, $x) {
  @if $x > 0 {
    @return atan($y / $x);
  } @else if $x < 0 {
    @if $y < 0 {
      @return atan($y / $x) - $PI;
    } @else {
      @return atan($y / $x) + $PI;
    }
  } @else {
    @if $y < 0 {
      @return -$PI / 2;
    } @else if $y > 0 {
      @return $PI / 2;
    } @else {
      @if 1 / $x == 1 / 0 {
        @return 0;
      } @else {
        @if 1 / $y == 1 / 0 {
          @return $PI;
        } @else {
          @return -$PI;
        }
      }
    }
  }
}

.card {
  width: 320px;
  height: 512px;
}

.cell {
  width: calc(100% / #{$n});
  height: calc(100% / #{$n});

  position: absolute;

  z-index: 2;

  @for $i from 1 through 400 {
    &:nth-child(#{$i}) {
      $col: getCellPos($i, true);
      $row: getCellPos($i);

      left: calc(#{$col} * calc(100% / #{$n}) - calc(100% / #{$n}));
      top: calc(#{$row} * calc(100% / #{$n}) - calc(100% / #{$n}));

      &:hover ~ .poster {
        $sx: linearRangeInterpolation(1, $col, $n, 45, -45) + px;
        $sy: linearRangeInterpolation(1, $row, $n, 90, 0) + px;
        $ry: linearRangeInterpolation(1, $col, $n, -15, 15) + deg;
        $rx: linearRangeInterpolation(1, $row, $n, 15, -15) + deg;

        --sx: #{$sx};
        --sy: #{$sy};
        --ry: #{$ry};
        --rx: #{$rx};
      }

      // 				GLARE WIP

      &:hover ~ .poster > .poster__layer:nth-child(5) {
        $bgx: linearRangeInterpolation(1, $col, $n, -10, 10) + px;
        $bgy: linearRangeInterpolation(1, $row, $n, 10, -10) + px;

        transform: translate3d($bgx, $bgy, 0px);
      }
    }
  }
}

.poster {
  --sx: 0;
  --sy: 45px;
  --rx: 0deg;
  --ry: 0deg;

  overflow: hidden;
  width: 100%;
  height: 100%;

  border-radius: 5px;
  box-shadow: var(--sx) var(--sy) 100px rgba(0, 0, 0, 0.4);

  transform: perspective(1000px) rotateX(var(--rx)) rotateY(var(--ry)) rotateZ(0deg);

  // no need to apply transition to box-shadow, since the blurriness already creates transition effect
  transition: transform 0.15s linear;

  will-change: transform, box-shadow;

  &:after {
    width: 100%;
    height: 100%;

    left: 0;
    top: 0;
    position: absolute;

    background: linear-gradient(0deg, rgba(red, 1) 0%, rgba(red, 0) 0%);
    content: '';

    pointer-events: none;
    z-index: 3;
  }
}
