@charset "UTF-8";
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Bold Italic"), local("Montserrat-BoldItalic"), url("/assets/fonts/subset-Montserrat-BoldItalic.woff2") format("woff2"), url("/assets/fonts/subset-Montserrat-BoldItalic.woff") format("woff"), url("/assets/fonts/subset-Montserrat-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Thin"), local("Montserrat-Thin"), url("/assets/fonts/subset-Montserrat-Thin.woff2") format("woff2"), url("/assets/fonts/subset-Montserrat-Thin.woff") format("woff"), url("/assets/fonts/subset-Montserrat-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat ExtraLight"), local("Montserrat-ExtraLight"), url("/assets/fonts/subset-Montserrat-ExtraLight.woff2") format("woff2"), url("/assets/fonts/subset-Montserrat-ExtraLight.woff") format("woff"), url("/assets/fonts/subset-Montserrat-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Black"), local("Montserrat-Black"), url("/assets/fonts/subset-Montserrat-Black.woff2") format("woff2"), url("/assets/fonts/subset-Montserrat-Black.woff") format("woff"), url("/assets/fonts/subset-Montserrat-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Regular"), local("Montserrat-Regular"), url("/assets/fonts/subset-Montserrat-Regular.woff2") format("woff2"), url("/assets/fonts/subset-Montserrat-Regular.woff") format("woff"), url("/assets/fonts/subset-Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat ExtraBold Italic"), local("Montserrat-ExtraBoldItalic"), url("/assets/fonts/subset-Montserrat-ExtraBoldItalic.woff2") format("woff2"), url("/assets/fonts/subset-Montserrat-ExtraBoldItalic.woff") format("woff"), url("/assets/fonts/subset-Montserrat-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Black Italic"), local("Montserrat-BlackItalic"), url("/assets/fonts/subset-Montserrat-BlackItalic.woff2") format("woff2"), url("/assets/fonts/subset-Montserrat-BlackItalic.woff") format("woff"), url("/assets/fonts/subset-Montserrat-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Medium Italic"), local("Montserrat-MediumItalic"), url("/assets/fonts/subset-Montserrat-MediumItalic.woff2") format("woff2"), url("/assets/fonts/subset-Montserrat-MediumItalic.woff") format("woff"), url("/assets/fonts/subset-Montserrat-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Light Italic"), local("Montserrat-LightItalic"), url("/assets/fonts/subset-Montserrat-LightItalic.woff2") format("woff2"), url("/assets/fonts/subset-Montserrat-LightItalic.woff") format("woff"), url("/assets/fonts/subset-Montserrat-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Medium"), local("Montserrat-Medium"), url("/assets/fonts/subset-Montserrat-Medium.woff2") format("woff2"), url("/assets/fonts/subset-Montserrat-Medium.woff") format("woff"), url("/assets/fonts/subset-Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Italic"), local("Montserrat-Italic"), url("/assets/fonts/subset-Montserrat-Italic.woff2") format("woff2"), url("/assets/fonts/subset-Montserrat-Italic.woff") format("woff"), url("/assets/fonts/subset-Montserrat-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat ExtraBold"), local("Montserrat-ExtraBold"), url("/assets/fonts/subset-Montserrat-ExtraBold.woff2") format("woff2"), url("/assets/fonts/subset-Montserrat-ExtraBold.woff") format("woff"), url("/assets/fonts/subset-Montserrat-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat SemiBold Italic"), local("Montserrat-SemiBoldItalic"), url("/assets/fonts/subset-Montserrat-SemiBoldItalic.woff2") format("woff2"), url("/assets/fonts/subset-Montserrat-SemiBoldItalic.woff") format("woff"), url("/assets/fonts/subset-Montserrat-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat ExtraLight Italic"), local("Montserrat-ExtraLightItalic"), url("/assets/fonts/subset-Montserrat-ExtraLightItalic.woff2") format("woff2"), url("/assets/fonts/subset-Montserrat-ExtraLightItalic.woff") format("woff"), url("/assets/fonts/subset-Montserrat-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Bold"), local("Montserrat-Bold"), url("/assets/fonts/subset-Montserrat-Bold.woff2") format("woff2"), url("/assets/fonts/subset-Montserrat-Bold.woff") format("woff"), url("/assets/fonts/subset-Montserrat-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Light"), local("Montserrat-Light"), url("/assets/fonts/subset-Montserrat-Light.woff2") format("woff2"), url("/assets/fonts/subset-Montserrat-Light.woff") format("woff"), url("/assets/fonts/subset-Montserrat-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Thin Italic"), local("Montserrat-ThinItalic"), url("/assets/fonts/subset-Montserrat-ThinItalic.woff2") format("woff2"), url("/assets/fonts/subset-Montserrat-ThinItalic.woff") format("woff"), url("/assets/fonts/subset-Montserrat-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"), url("/assets/fonts/subset-Montserrat-SemiBold.woff2") format("woff2"), url("/assets/fonts/subset-Montserrat-SemiBold.woff") format("woff"), url("/assets/fonts/subset-Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "icomoon";
  src: url("/assets/fonts/icomoon.eot?w7btq8");
  src: url("/assets/fonts/icomoon.eot?w7btq8#iefix") format("embedded-opentype"), url("/assets/fonts/icomoon.ttf?w7btq8") format("truetype"), url("/assets/fonts/icomoon.woff?w7btq8") format("woff"), url("/assets/fonts/icomoon.svg?w7btq8#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}
:root {
  --b1-color-primary: #2c71df;
  --b1-color-primary-rgb: 44, 113, 223;
  --b1-color-primary-contrast: #ffffff;
  --b1-color-primary-contrast-rgb: 255, 255, 255;
  --b1-color-primary-shade: #2763c4;
  --b1-color-primary-tint: #417fe2;
  --b1-color-secondary: #4ba3f3;
  --b1-color-secondary-rgb: 75, 163, 243;
  --b1-color-secondary-contrast: #000000;
  --b1-color-secondary-contrast-rgb: 0, 0, 0;
  --b1-color-secondary-shade: #428fd6;
  --b1-color-secondary-tint: #5dacf4;
  --b1-color-tertiary: #183754;
  --b1-color-tertiary-rgb: 24, 55, 84;
  --b1-color-tertiary-contrast: #ffffff;
  --b1-color-tertiary-contrast-rgb: 255, 255, 255;
  --b1-color-tertiary-shade: #15304a;
  --b1-color-tertiary-tint: #2f4b65;
  --b1-color-success: #7ed421;
  --b1-color-success-rgb: 126, 212, 33;
  --b1-color-success-contrast: #000000;
  --b1-color-success-contrast-rgb: 0, 0, 0;
  --b1-color-success-shade: #6fbb1d;
  --b1-color-success-tint: #8bd837;
  --b1-color-warning: #ffc827;
  --b1-color-warning-rgb: 217, 140, 7;
  --b1-color-warning-contrast: #000000;
  --b1-color-warning-contrast-rgb: 0, 0, 0;
  --b1-color-warning-shade: #bf7b06;
  --b1-color-warning-tint: #dd9820;
  --b1-color-warning-2: #d98c07;
  --b1-color-danger: #d9160f;
  --b1-color-danger-rgb: 217, 22, 15;
  --b1-color-danger-contrast: #ffffff;
  --b1-color-danger-contrast-rgb: 255, 255, 255;
  --b1-color-danger-shade: #bf130d;
  --b1-color-danger-tint: #dd2d27;
  --b1-color-dark: #2b3035;
  --b1-color-dark-rgb: 43, 48, 53;
  --b1-color-dark-contrast: #ffffff;
  --b1-color-dark-contrast-rgb: 255, 255, 255;
  --b1-color-dark-shade: #262a2f;
  --b1-color-dark-tint: #404549;
  --b1-color-dark-high-opacity: rgba(0, 0, 0, 0.7);
  --b1-color-dark-medium-opacity: rgba(0, 0, 0, 0.5);
  --b1-color-dark-low-opacity: rgba(0, 0, 0, 0.3);
  --b1-color-medium: #7b7b7b;
  --b1-color-medium-rgb: 123, 123, 123;
  --b1-color-medium-contrast: #ffffff;
  --b1-color-medium-contrast-rgb: 255, 255, 255;
  --b1-color-medium-shade: #6c6c6c;
  --b1-color-medium-tint: #888888;
  --b1-color-light: #f4f5f8;
  --b1-color-light-rgb: 244, 244, 244;
  --b1-color-light-contrast: #000000;
  --b1-color-light-contrast-rgb: 0, 0, 0;
  --b1-color-light-shade: #d7d8da;
  --b1-color-light-tint: #f5f6f9;
  --b1-color-light-high-opacity: rgba(244, 245, 248, 0.8);
  --b1-color-light-medium-opacity: rgba(244, 245, 248, 0.5);
  --b1-color-light-low-opacity: rgba(244, 245, 248, 0.3);
  --b1-color-gray-1: #353a40;
  --b1-color-gray-2: #d7d7d7;
}

body {
  padding-right: 0 !important;
  font-size: 16px;
  font: 120% !important;
  font-family: "Montserrat", sans-serif !important;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  overflow: auto !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body:before {
  content: "";
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: white;
  background: url("/assets/img/wallpapers/b1-670925-unsplash.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  will-change: transform;
  z-index: -2;
}

label {
  margin: 0;
  text-transform: uppercase;
  font-size: 0.6rem;
  font-weight: 700;
  letter-spacing: 0.5px;
}

a {
  text-transform: uppercase;
  font-size: 0.6rem;
  font-weight: 600;
  letter-spacing: 0.5px;
}
a.-icon {
  color: white;
  font-size: inherit;
  cursor: pointer;
  transition: all 300ms;
}
a.-icon:hover {
  transform: scale(1.2);
}
a.-name {
  color: white;
}

ul {
  list-style: none;
  padding-inline-start: 0;
}

h3 {
  text-transform: uppercase;
  font-size: 1rem;
  color: white;
}

h4 {
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

p {
  font-weight: 500;
}

.modal-header .modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.5px;
}
.modal-header .close {
  margin: 0;
  width: 30px;
  height: 30px;
}
.modal-header .close span {
  font-size: 1.3rem;
  text-align: center;
  padding-left: 2px;
}

:host .swal2-popup .swal2-content {
  font-weight: 600;
}
:host .swal2-popup .swal2-title {
  line-height: 1rem;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 700;
  letter-spacing: 0.5px;
}

.mat-input-element {
  font-family: "Montserrat" !important;
}

.date-input {
  background: rgba(0, 0, 0, 0.7);
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  height: 50px;
}
.date-input .mat-form-field-infix {
  border-top: 0 solid transparent !important;
}
.date-input .mat-form-field {
  font-weight: 600 !important;
  line-height: 30px !important;
}
.date-input .mat-icon-button {
  height: 30px !important;
  width: 30px !important;
  display: flex !important;
  margin-bottom: 0 !important;
}
.date-input .mat-icon-button span {
  font-size: 1rem !important;
}
.date-input .mat-form-field-underline {
  height: 0 !important;
}
.date-input .mat-form-field-hide-placeholder {
  font-size: 0.6rem !important;
  font-weight: 700 !important;
  letter-spacing: 0.5px !important;
  opacity: 1 !important;
}
.date-input .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.8em 0 !important;
  width: 130px !important;
}
.date-input input {
  font-size: 1rem !important;
  font-weight: 600 !important;
  height: 30px !important;
  padding: 0 0.5rem !important;
  text-align: center !important;
}
.date-input input::placeholder {
  font-size: 0.6rem !important;
  font-weight: 700 !important;
  letter-spacing: 0.5px !important;
}
.date-input input:focus {
  outline: none;
  box-shadow: inherit !important;
}
.date-input button {
  background-color: transparent !important;
}

input,
textarea,
select {
  border-radius: 2rem;
  border: 0;
  height: 2.5rem;
  padding: 0 1rem;
  font-size: 0.85rem;
  font-weight: 500;
  background-color: var(--b1-color-light);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
input:focus,
textarea:focus,
select:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(183, 183, 183, 0.5);
}

input:read-only,
textarea:read-only {
  background-color: rgba(183, 183, 183, 0.08);
  padding: 0 1rem;
  font-weight: 700;
}
input:read-only::placeholder,
textarea:read-only::placeholder {
  color: white;
  opacity: 1;
}

textarea {
  border-radius: 1rem;
  width: 100%;
  min-height: 200px;
  height: inherit;
  padding: 1rem;
}

input[type=radio] {
  height: inherit;
}

input[type=checkbox] {
  height: inherit;
  padding: 0.3rem 0;
}

.h30w30 {
  width: 30px;
  height: 30px;
}

button,
a.button {
  height: 2.5rem;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: inherit;
  background-color: var(--b1-color-primary);
  text-align: center;
  color: white;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 0.55rem;
  font-weight: 800;
  padding: 0 0.75rem;
  position: relative;
  border: 0;
  transition: all 0.15s ease-in-out;
}
button:hover,
a.button:hover {
  box-shadow: 0 16px 21px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(180deg, var(--b1-color-primary), var(--b1-color-secondary-tint) 98%) !important;
  cursor: pointer;
  color: white !important;
  text-decoration: none;
}
button:focus,
a.button:focus {
  outline: none;
}

.btn--25px-h {
  height: 1.56rem;
}

.btn--Orange {
  background-color: var(--b1-color-warning) !important;
  color: black !important;
}

.btn--White {
  background-color: white !important;
  color: black !important;
}

.btn--White-o {
  background-color: rgba(255, 255, 255, 0.1) !important;
  border: 2px solid white !important;
  color: white !important;
}

.btn--Danger {
  background-color: var(--b1-color-danger) !important;
  color: #fff !important;
}
.btn--Danger:hover {
  background-color: var(--b1-color-danger) !important;
  background-image: linear-gradient(180deg, var(--b1-color-danger), var(--b1-color-danger-tint) 98%) !important;
}

.btn--Warning {
  background-color: var(--b1-color-warning) !important;
  color: var(--b1-color-warning-contrast) !important;
}
.btn--Warning:hover {
  background-color: var(--b1-color-warning-tint) !important;
}

.btn--Success {
  background-color: var(--b1-color-success-shade) !important;
  color: #fff !important;
}
.btn--Success:hover {
  background-color: var(--b1-color-success) !important;
}

.btn--Circle {
  width: 2.5rem;
  height: 2.5rem;
}

.overflow-visible {
  overflow: visible;
}

.main--Grid {
  display: grid;
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
  grid-template-rows: [topmenu] 70px [body] 100%;
  grid-template-columns: [mainmenu] 65px [conteudo] 12fr;
  min-height: 100%;
  padding: 0.5rem;
}

.top--Nav {
  grid-row: topmenu;
  grid-column: conteudo;
  padding-top: 0.3rem;
  z-index: 999;
}

.main--Menu {
  grid-row: topmenu;
  grid-column: mainmenu;
  margin: 0.3rem;
  position: sticky;
  left: 0;
  top: 0;
  bottom: 0;
}

.conteudo {
  grid-row: body;
  grid-column: conteudo;
  min-height: 100%;
  min-width: 0;
}

app-home {
  grid-row: body;
  grid-column: conteudo;
  min-height: 100%;
  position: relative;
}

app-filas {
  grid-row: topmenu;
  grid-column: conteudo;
  grid-column-start: span 2;
  min-height: 100%;
  position: relative;
}

app-operador {
  grid-row: body;
  grid-column: conteudo;
}

app-admin {
  grid-row: body;
  grid-column: conteudo;
}

.footer {
  grid-row: footer;
  grid-column: conteudo;
  grid-row-start: 3;
  grid-row-end: 3;
  display: none;
  align-items: center;
  justify-content: center;
}
.footer .footer--Text {
  color: white;
  text-transform: uppercase !important;
  font-size: 0.6rem;
  letter-spacing: 1px;
  font-weight: 600;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}
.footer .logo--Footer {
  max-width: 60px;
  height: auto;
  padding-right: 1rem;
}

.ico--Login {
  background-image: url("/assets/img/icos/ico-inicio.svg");
}

.ico--Inicio {
  background-image: url("/assets/img/icos/ico-inicio.svg");
}

.ico--Dashboard {
  background-image: url("/assets/img/icos/2020/ico-dashboard.svg");
}

.ico--Vendas {
  background-image: url("/assets/img/icos/2020/ico-vendas.svg");
}

.ico--Supervisao {
  background-image: url("/assets/img/icos/ico-supervisao.svg");
}

.ico--Tickets {
  background-image: url("/assets/img/icos/2020/ico-tickets.svg");
}

.ico--Contas {
  background-image: url("/assets/img/icos/2020/ico-conta.svg");
}

.ico--Contato {
  background-image: url("/assets/img/icos/2020/ico-contato.svg");
}

.ico--Sair {
  background-image: url("/assets/img/icos/2020/ico-sair.svg");
}

.ico--Busca {
  background-image: url("/assets/img/icos/ico-busca.svg");
}

.ico--Inbox {
  background-image: url("/assets/img/icos/2020/ico-inbox.svg");
}

.ico--Filtro {
  background-image: url("/assets/img/icos/ico-filtro.svg");
}

.ico--Calendario {
  background-image: url("/assets/img/icos/2020/ico-calendario.svg");
}

.ico--Push {
  background-image: url("/assets/img/icos/ico-push.svg");
}

.ico--Voz {
  background-image: url("/assets/img/icos/ico-voz.svg");
}

.ico--Marketing {
  background-image: url("/assets/img/icos/2020/ico-marketing.svg");
}

.ico--Campanhas {
  background-image: url("/assets/img/icos/ico-campanhas.svg");
}

.ico--Up {
  background-image: url("/assets/img/icos/ico-Up.svg");
}

.ico--AceiteVoz {
  background-image: url("/assets/img/icos/ico-aceite-voz.svg");
}

.ico--Exames {
  background-image: url("/assets/img/icos/ico-exames.svg");
}

.ico--Bpm {
  background-image: url("/assets/img/icos/2020/ico-bpm.svg");
}

.ico--Reload {
  background-image: url("/assets/img/icos/2020/ico-reload.svg");
}

.ico--Components {
  background-image: url("/assets/img/icos/2020/ico-components.svg");
}

.ico--Admin {
  background-image: url("/assets/img/icos/2020/ico-admin.svg");
}

.ico--Projetos {
  background-image: url("/assets/img/icos/2020/ico-projeto.svg");
}

.ico--Recording {
  background-image: url("/assets/img/icos/ico-recordings.svg");
}

.ico--Operador {
  background-image: url("/assets/img/icos/2020/ico-operador.svg");
}

.ico--Supervisor {
  background-image: url("/assets/img/icos/2020/ico-users.svg");
}

.ico--Lead {
  background-image: url("/assets/img/icos/2020/ico-lead.svg");
}

.ico--Monitoring {
  background-image: url("/assets/img/icos/2020/ico-monitoring.svg");
}

.ico--Copy {
  width: 26px;
  line-height: 26px;
  margin: 0 0 0 1.5px !important;
  font-family: "icomoon" !important;
  font-weight: 600;
  font-size: 1rem;
  display: block;
  transition: all 0.2s ease-in;
}

.ico--Copy:before {
  content: "";
}

.icon.settings {
  font-size: 1rem;
  width: 24px;
  height: 24px;
  text-align: center;
  border: 1px solid transparent;
}
.icon.settings:hover {
  cursor: pointer;
  color: var(--b1-color-warning);
  animation: rotation 3s infinite linear;
  -webkit-animation: rotation 3s infinite linear;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
.user--Avatar {
  display: block;
  background-color: transparent !important;
  background-size: contain;
  background-position: center;
  background-image: url("/assets/img/avatar.png");
  background-repeat: no-repeat;
  object-fit: cover;
  object-position: center;
}
.user--Avatar div {
  background-color: transparent !important;
}

.box--white-default {
  background: white;
  border: 1px solid white;
  box-shadow: 0 2px 12px 0 rgba(var(--b1-color-dark-high-opacity));
  border-radius: 1rem;
  padding: 1px !important;
}
.box--white-default .box--DarkInner {
  background: rgba(0, 0, 0, 0.58) !important;
  color: white !important;
}
.box--white-default .box--DarkInner hr {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.1);
  height: 1px;
  border: none;
}
.box--white-default .input--Width {
  max-width: 320px;
  width: 320px;
}

.box--black-default {
  background: var(--b1-color-dark-high-opacity);
  border: 1px solid var(--b1-color-dark-high-opacity);
  box-shadow: 0 16px 21px 0 rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  padding: 1px !important;
}
.box--black-default .box--Inner {
  background: var(--b1-color-dark-high-opacity);
  color: var(--b1-color-light);
  padding: 0.3rem;
}
.box--black-default .box--Inner hr {
  width: 100%;
  background-color: var(--b1-color-light-low-opacity);
  color: var(--b1-color-light-low-opacity);
  height: 1px;
  border: none;
}
.box--black-default input:read-only {
  color: white;
}

.box--blue-primary {
  background: var(--b1-color-primary);
  border: 1px solid var(--b1-color-primary);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
  border-radius: 1rem;
  padding: 1px !important;
}
.box--blue-primary .box--Inner {
  background-color: white;
  padding: 1rem;
}
.box--blue-primary .input--Width {
  max-width: 320px;
  width: 320px;
}

.box--blue-secondary {
  background: var(--b1-color-secondary);
  border: 1px solid var(--b1-color-secondary);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
  border-radius: 1rem;
  padding: 1px !important;
}
.box--blue-secondary .input--Width {
  max-width: 320px;
  width: 320px;
}

.box--green-default {
  background: var(--b1-color-success);
  border: 1px solid var(--b1-color-success);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  padding: 1px !important;
}
.box--green-default .input--Width {
  max-width: 320px;
  width: 320px;
}

.box--gray-default-dark {
  background-color: var(--b1-color-dark) !important;
  border: 1px solid var(--b1-color-dark);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
  border-radius: 1rem !important;
  padding: 1px !important;
}
.box--gray-default-dark .box--Inner {
  background-color: var(--b1-color-medium);
  color: white;
}
.box--gray-default-dark .box--Inner .input--Width {
  max-width: 320px;
  width: 320px;
}
.box--gray-default-dark .box--Inner label {
  color: white;
}
.box--gray-default-dark input:read-only {
  color: white;
}

.box--gray-default-medium {
  background-color: var(--b1-color-light) !important;
  border: 1px solid var(--b1-color-light);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
  border-radius: 1rem !important;
  padding: 1px !important;
}
.box--gray-default-medium .box--Inner {
  background-color: var(--b1-color-medium);
  color: white;
}
.box--gray-default-medium .box--Inner .input--Width {
  max-width: 320px;
  width: 320px;
}
.box--gray-default-medium .box--Inner label {
  color: white;
}
.box--gray-default-medium input:read-only {
  color: white;
}

.box--gray-default-light {
  background-color: var(--b1-color-dark) !important;
  border: 1px solid var(--b1-color-dark);
  box-shadow: 0 2px 12px 0 var(--b1-color-dark-low-opacity);
  border-radius: 1rem !important;
}
.box--gray-default-light .box--Inner {
  background-color: var(--b1-color-light);
  color: var(--b1-color-light-contrast);
  padding: 0.3rem;
}
.box--gray-default-light input:read-only {
  color: var(--b1-color-dark);
}

.box--ReddefaultAlpha {
  background-color: rgba(211, 87, 87, 0.6) !important;
  border: 1px solid var(--b1-color-danger);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
  border-radius: 1rem !important;
  padding: 1px !important;
  background-blend-mode: multiply;
}
.box--ReddefaultAlpha .box--Inner {
  background-color: var(--b1-color-light);
}
.box--ReddefaultAlpha .box--Inner .input--Width {
  max-width: 320px;
  width: 320px;
}

.box--Reddefault {
  background-color: var(--b1-color-danger) !important;
  border: 1px solid var(--b1-color-danger);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
  border-radius: 1rem !important;
  padding: 1px !important;
}
.box--Reddefault .box--Inner {
  background-color: var(--b1-color-light);
}
.box--Reddefault .box--Inner .input--Width {
  max-width: 320px;
  width: 320px;
}

.box--Inner {
  border-radius: 0.95rem;
  display: flex;
  justify-content: center;
}

.border--Radius {
  padding: 1rem;
  border-radius: 0.95rem;
}

.box--Title {
  font-size: 0.7rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  height: 2.5rem;
  color: white;
  display: flex;
  align-items: center;
  padding: 0 calc(1rem + 1px);
  overflow: hidden;
}
.box--Title label {
  margin: 0;
}
.box--Title a {
  display: inline-block;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 0;
}

.name--Listed {
  text-transform: uppercase;
  background-color: white;
  color: var(--b1-color-secondary);
  font-weight: 700;
  font-size: 0.75rem;
  letter-spacing: 0.5px;
  border-radius: 20px;
  padding: 1px 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  display: block;
  text-align: center;
}

.fix-height {
  height: 2.5rem !important;
}

button:disabled {
  opacity: 0.7;
}

.modal--CreateLead-wrapper {
  max-width: 1280px !important;
  width: 100% !important;
  padding-left: 40px;
  padding-right: 40px;
}
.modal--CreateLead-wrapper .modal-body {
  max-height: 75vh;
  overflow-x: auto;
}
.modal--CreateLead-wrapper .modal-body .form-box {
  border: 1px solid #dee2e6;
  padding: 1rem;
  border-radius: 15px;
  margin-bottom: 1rem;
}
.modal--CreateLead-wrapper input:read-only {
  color: white;
}

.tools fa-icon {
  color: #000 !important;
}

hr {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.1);
  height: 1px;
  border: none;
}

.b1-table {
  border-radius: 1rem;
  overflow: hidden;
}
.b1-table .header th {
  border-top: 0;
}
.b1-table tr:hover {
  cursor: pointer;
}

.drop-image {
  padding: 40px;
  border-radius: 15px;
  background: #f0f0f0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.1s all ease-in;
}
.drop-image:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
}
.drop-image h4 {
  font-size: 14px;
  font-weight: bold;
  color: #444;
}
.drop-image input {
  display: none;
}

.modal-title {
  color: #000;
}

.modal-create-lead-body .btn-group-toggle,
.modal--Account-body .btn-group-toggle,
.person-type-toggler .btn-group-toggle,
.custom-toggler .btn-group-toggle,
.btn-toggler .btn-group-toggle {
  position: relative;
}
.modal-create-lead-body .btn-group-toggle label,
.modal--Account-body .btn-group-toggle label,
.person-type-toggler .btn-group-toggle label,
.custom-toggler .btn-group-toggle label,
.btn-toggler .btn-group-toggle label {
  background-color: #c9c8cd !important;
  color: #000 !important;
  font-size: 0.55rem !important;
  height: 28px;
  border-radius: 2rem !important;
  text-transform: uppercase;
  border: 0 !important;
  display: flex;
  align-items: center;
  font-weight: 800 !important;
  z-index: 4;
  justify-content: center !important;
  line-height: 1 !important;
}
.modal-create-lead-body .btn-group-toggle label.active,
.modal--Account-body .btn-group-toggle label.active,
.person-type-toggler .btn-group-toggle label.active,
.custom-toggler .btn-group-toggle label.active,
.btn-toggler .btn-group-toggle label.active {
  background-color: var(--b1-color-primary) !important;
  color: #fff !important;
  z-index: 5;
}
.modal-create-lead-body .btn-group-toggle label.active:first-of-type,
.modal--Account-body .btn-group-toggle label.active:first-of-type,
.person-type-toggler .btn-group-toggle label.active:first-of-type,
.custom-toggler .btn-group-toggle label.active:first-of-type,
.btn-toggler .btn-group-toggle label.active:first-of-type {
  padding-right: 0.9rem !important;
}
.modal-create-lead-body .btn-group-toggle label.active:last-of-type,
.modal--Account-body .btn-group-toggle label.active:last-of-type,
.person-type-toggler .btn-group-toggle label.active:last-of-type,
.custom-toggler .btn-group-toggle label.active:last-of-type,
.btn-toggler .btn-group-toggle label.active:last-of-type {
  margin-left: -2rem !important;
  padding-left: 0.9rem !important;
}
.modal-create-lead-body .btn-group-toggle label:first-of-type,
.modal--Account-body .btn-group-toggle label:first-of-type,
.person-type-toggler .btn-group-toggle label:first-of-type,
.custom-toggler .btn-group-toggle label:first-of-type,
.btn-toggler .btn-group-toggle label:first-of-type {
  padding-right: 2.4rem !important;
}
.modal-create-lead-body .btn-group-toggle label:last-of-type,
.modal--Account-body .btn-group-toggle label:last-of-type,
.person-type-toggler .btn-group-toggle label:last-of-type,
.custom-toggler .btn-group-toggle label:last-of-type,
.btn-toggler .btn-group-toggle label:last-of-type {
  margin-left: -2rem !important;
  padding-left: 2.4rem !important;
}
.modal-create-lead-body .section-title label,
.modal--Account-body .section-title label,
.person-type-toggler .section-title label,
.custom-toggler .section-title label,
.btn-toggler .section-title label {
  padding-top: 0 !important;
  padding-left: 0 !important;
}

.readonly-field {
  color: #fff;
}

.text-white {
  color: #fff !important;
}

.tools fa-icon {
  color: white;
}
.tools fa-icon:hover {
  cursor: pointer;
  color: var(--amarelo-neon);
}

.error-helper {
  color: var(--b1-color-danger);
  font-size: 0.8rem;
  margin-top: 0.5rem;
  display: block;
  padding-left: 0.5rem;
}

.cursor-pointer {
  cursor: pointer !important;
}

.tab-toggler .btn-group-toggle {
  position: relative;
}
.tab-toggler .btn-group-toggle label {
  background-color: #c9c8cd !important;
  color: #000 !important;
  font-size: 0.55rem !important;
  height: 28px;
  border-radius: 2rem !important;
  text-transform: uppercase;
  border: 0 !important;
  display: flex;
  align-items: center;
  font-weight: 800 !important;
  z-index: 4;
  justify-content: center !important;
  line-height: 1 !important;
}
.tab-toggler .btn-group-toggle label.active {
  background-color: var(--b1-color-primary) !important;
  color: #fff !important;
  z-index: 5;
}
.tab-toggler .btn-group-toggle label.active:first-of-type {
  padding-right: 0.9rem !important;
}
.tab-toggler .btn-group-toggle label.active:last-of-type {
  margin-left: -2rem !important;
  padding-left: 0.9rem !important;
}
.tab-toggler .btn-group-toggle label:first-of-type {
  padding-right: 2.4rem !important;
}
.tab-toggler .btn-group-toggle label:last-of-type {
  margin-left: -2rem !important;
  padding-left: 2.4rem !important;
}

.break {
  flex-basis: 100%;
  height: 0;
}

input.ng-invalid.ng-touched,
select.ng-invalid.ng-touched,
textarea.ng-invalid.ng-touched {
  border: 1px solid var(--b1-color-danger);
}

.form-group label {
  display: block;
}
.form-group input,
.form-group select {
  width: 100%;
}

.form-group label.has-error {
  color: var(--b1-color-danger) !important;
}

.required {
  color: var(--b1-color-danger);
  font-weight: 600;
}

.formulario,
::ng-deep .formulario {
  width: 100%;
  max-width: 500px;
  margin: auto;
}

.dropdown-menu li .dropdown-item {
  padding: 0.75rem;
  font-size: 0.7rem;
}
.dropdown-menu li .dropdown-item:focus, .dropdown-menu li .dropdown-item:active {
  background-color: var(--b1-color-primary) !important;
}

bs-datepicker-container {
  z-index: 9999;
}

.orders-header {
  width: calc(100%);
  display: grid;
  grid-template-columns: [avatar] 50px [nome] 1fr [conta] 1fr [telefone] 1fr [tipo] 1fr;
}
.orders-header .orders-header--col {
  text-transform: uppercase;
  font-size: 0.6rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  color: #fff;
  text-align: center;
  padding: 0.5rem;
  flex: 1;
}

.oex-item {
  height: auto;
  margin: 2px auto;
  width: 100%;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
}
.oex-item.active .oex-item--inner, .oex-item:hover .oex-item--inner {
  background-image: linear-gradient(180deg, var(--b1-color-primary), var(--b1-color-secondary-tint) 98%) !important;
}
.oex-item.expanded {
  height: 141px;
}
.oex-item.expanded .oex-item--inner {
  background-image: linear-gradient(180deg, var(--azul-quarteario), var(--b1-color-secondary) 98%) !important;
}
.oex-item.expanded .oex-item--inner .info--expandable-info {
  display: block !important;
}
.oex-item .oex-item--inner {
  display: flex;
  background-image: linear-gradient(180deg, #343a40 0%, #2b3035 98%);
  border-radius: 1rem;
  transition: all 0.15s ease-in-out;
}
.oex-item .oex-item--inner.delayed {
  background-image: linear-gradient(180deg, black 0%, #2c2c2c 98%);
}
.oex-item .oex-item--inner .info--expandable-info {
  display: none;
  position: relative;
}
.oex-item .oex-item--inner .info--expandable-info .info--steps {
  display: flex;
}
.oex-item .oex-item--inner .info--expandable-info .info--steps .info--step {
  flex: 1;
  padding: 1.5rem 1rem;
  text-align: center;
  font-size: 0.6rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
.oex-item .oex-item--inner .info--expandable-info .info--steps .info--step:first-of-type {
  text-align: left;
}
.oex-item .oex-item--inner .info--expandable-info .info--steps .info--step:last-of-type {
  text-align: right;
}
.oex-item .oex-item--inner .info--expandable-info .info--steps .info--step .step--title {
  color: #fff;
}
.oex-item .oex-item--inner .info--expandable-info .info--steps .info--step .step--title fa-icon {
  margin-left: 0.5rem;
}
.oex-item .oex-item--inner .info--expandable-info .info--steps .info--step .step--description {
  margin-top: 1rem;
  font-size: 0.6rem !important;
  line-height: 25px;
  position: relative;
  z-index: 5;
}
.oex-item .oex-item--inner .info--expandable-info .info--steps .info--step .step--description .step--status {
  height: 25px;
  padding: 0 1rem;
  background-color: var(--amarelo-neon);
  border-radius: 25px;
  display: inline-block;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
}
.oex-item .oex-item--inner .info--expandable-info .info--progress {
  background-color: #fff;
  position: relative;
  border-radius: 1rem;
  height: 20px;
  width: calc(100% - 1rem);
  margin: auto;
  margin-top: calc(1.5rem * (-1) - 22px);
  z-index: 2;
}
.oex-item .oex-item--inner .info--expandable-info .info--progress .current-progress {
  background-color: var(--b1-color-success);
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 1rem;
}
.oex-item .oex-item--inner .oex-item--info {
  flex: 1;
}
.oex-item .oex-item--inner .oex-item--info .info--details {
  display: grid;
  grid-template-columns: repeat(4, minmax(50px, 1fr));
  height: auto;
}
.oex-item .oex-item--inner .oex-item--info .info--details .oex-item--col {
  color: black;
  align-items: center;
  text-transform: uppercase;
  font-size: 0.6rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  word-wrap: break-word;
}
.oex-item .oex-item--inner .oex-item--info .info--details .oex-item--col button {
  background-color: transparent !important;
  color: #fff !important;
  padding: 0 !important;
  font-size: 16px !important;
}
.oex-item .oex-item--inner .oex-item--info .info--details .oex-item--col.col-avatar {
  grid-column: avatar;
}
.oex-item .oex-item--inner .oex-item--info .info--details .oex-item--col.col-name {
  justify-content: flex-start;
  grid-column: nome;
  background: white;
  border-bottom-left-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  margin: 0.2rem 0;
}
.oex-item .oex-item--inner .oex-item--info .info--details .oex-item--col.col-account {
  grid-column: conta;
  background: white;
  margin: 0.2rem 0;
}
.oex-item .oex-item--inner .oex-item--info .info--details .oex-item--col.col-phone {
  grid-column: telefone;
  grid-column: conta;
  background: white;
  margin: 0.2rem 0;
}
.oex-item .oex-item--inner .oex-item--info .info--details .oex-item--col.col-type {
  grid-column: tipo;
  background: white;
  border-bottom-right-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  margin: 0.2rem 0;
}
.oex-item .oex-item--inner .oex-item--actions {
  width: 150px;
  display: flex;
  flex-direction: column;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  overflow: hidden;
}
.oex-item .oex-item--inner .oex-item--actions .actions--status {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: var(--b1-color-success);
  text-transform: uppercase;
  font-size: 0.6rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  padding: 0.5rem 1rem;
  box-shadow: inset 0 2px 12px 0 rgba(0, 0, 0, 0.3);
}
.oex-item .oex-item--inner .oex-item--actions .actions--status.pending {
  background-color: var(--b1-color-warning) !important;
}
.oex-item .oex-item--inner .oex-item--actions .actions--status.provided {
  background-color: var(--amarelo-neon) !important;
  color: black !important;
}
.oex-item .oex-item--inner .oex-item--actions .actions--status.delayed {
  background-color: var(--b1-color-danger) !important;
}
.oex-item .oex-item--inner .oex-item--actions .actions--status.finished {
  background-color: var(--b1-color-success) !important;
}
.oex-item .oex-item--inner .oex-item--actions .actions--status.canceled {
  background-color: var(--b1-color-danger) !important;
}
.oex-item .oex-item--inner .oex-item--actions .actions--buttons {
  display: flex;
  background-color: #fff;
  padding: 0 1rem;
  justify-content: center;
}
.oex-item .oex-item--inner .oex-item--actions .actions--buttons button {
  background-color: transparent !important;
  padding: 0.3rem 0.5rem !important;
  margin-right: 0.5rem !important;
  height: auto !important;
  font-size: 14px !important;
  color: var(--azul-terciario) !important;
}
.oex-item .oex-item--inner .oex-item--actions .actions--buttons button:last-of-type {
  margin-right: 0;
}
.oex-item .oex-item--inner .oex-item--actions .actions--buttons button.cancel {
  color: var(--b1-color-danger) !important;
}

.b1-list {
  border-radius: 10px;
  padding: 3px;
  min-height: 52px;
  display: flex;
  align-items: center;
  margin: 0.15rem 0;
  background-color: rgba(0, 0, 0, 0.7);
  transition: background-color 0.5s ease, transform 0.25s ease;
}
.b1-list:hover {
  background-color: rgba(0, 0, 0, 0.95);
  backface-visibility: hidden;
  cursor: pointer;
}
.b1-list .--avatar {
  width: 44px;
  min-width: 44px;
  height: 44px;
  margin: 0 0.3rem;
  border-radius: 22px;
  overflow: hidden;
  background-color: var(--b1-color-primary-contrast);
}
.b1-list .--avatar img {
  width: 100%;
  height: auto;
}
.b1-list .--rows {
  width: 100%;
  background-color: white;
  display: grid;
  grid-template-columns: repeat(4, minmax(50px, 1fr));
  height: auto;
  text-transform: uppercase;
  color: black;
  border-radius: 7px;
  padding: 0.5rem 0.3rem;
  font-size: 0.6rem;
  font-weight: 700 !important;
  justify-items: center;
  align-items: center;
}
.b1-list .--rows .--cells {
  width: 100%;
  text-align: center;
}
.b1-list .--rows .--cells.--name {
  text-align: left;
  padding-left: 0.5rem;
}
.b1-list .--rows .--cells .display_name {
  font-size: 0.875rem !important;
  line-height: 1.1;
}

.gray-round-background {
  letter-spacing: normal !important;
  height: auto;
  padding: 0.344rem 0.7rem !important;
  border-radius: 25px;
  text-align: center;
  background-color: var(--b1-color-light);
}

.modal--Details .form-group label,
.formulario .form-group label {
  padding-left: 1rem;
}

ngx-avatar .avatar-content {
  object-fit: cover;
  object-position: center;
}

.simple-keyboard .hg-row .hg-button span,
.name-keyboard .hg-row .hg-button span {
  color: #252525 !important;
  font-weight: 600;
}

.shake-horizontal {
  -webkit-animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}

.click--Effect {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin: 0 auto;
  width: 70px;
  height: 70px;
  font-size: 52px;
  transition: all 0.2s ease-in;
}
.click--Effect span[class^=ion] {
  position: relative;
}
.click--Effect:before {
  content: "";
  background-color: aliceblue;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: scale(0.001, 0.001);
}
.click--Effect:focus {
  outline: 0;
  color: #fff;
}
.click--Effect:focus:before {
  animation: effect_dylan 0.8s ease-out;
}

@keyframes effect_dylan {
  50% {
    transform: scale(1.5, 1.5);
    opacity: 0;
  }
  99% {
    transform: scale(0.001, 0.001);
    opacity: 0;
  }
  100% {
    transform: scale(0.001, 0.001);
    opacity: 1;
  }
}
.slide-in-blurred-bottom {
  -webkit-animation: slide-in-blurred-bottom 0.5s cubic-bezier(0.23, 1, 0.32, 1) both;
  animation: slide-in-blurred-bottom 0.5s cubic-bezier(0.23, 1, 0.32, 1) both;
}

.scale-in-ver-bottom {
  -webkit-animation: scale-in-ver-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-in-ver-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slide-in-fwd-center {
  -webkit-animation: slide-in-fwd-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-fwd-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.flip-in-hor-top {
  -webkit-animation: flip-in-hor-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: flip-in-hor-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.fade-in {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.fade-in-fast {
  -webkit-animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.fade-in-0 {
  -webkit-animation: fade-in-right 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-right 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.fade-in-25 {
  -webkit-animation: fade-in-right 0.25s cubic-bezier(0.39, 0.575, 0.565, 1) 0.2s both;
  animation: fade-in-right 0.25s cubic-bezier(0.39, 0.575, 0.565, 1) 0.2s both;
}

.fade-in-50 {
  -webkit-animation: fade-in-right 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) 0.45s both;
  animation: fade-in-right 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) 0.45s both;
}

.fade-in-75 {
  -webkit-animation: fade-in-right 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) 0.7s both;
  animation: fade-in-right 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) 0.7s both;
}

.fade-in-100 {
  -webkit-animation: fade-in-right 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) 0.95s both;
  animation: fade-in-right 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) 0.95s both;
}

.fade-in-right {
  -webkit-animation: fade-in-right 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-right 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.fade-in-left,
.fadeInLeft {
  -webkit-animation: fade-in-left 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-left 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.fade-in-fwd {
  -webkit-animation: fade-in-fwd 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-fwd 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.fade-in-bottom {
  -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.fade-in-top {
  -webkit-animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.fade-in-top-fast {
  -webkit-animation: fade-in-top 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-top 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.fade-in-top-half-sec {
  -webkit-animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 0.5s both;
  animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 0.5s both;
}

.fade-in-top-half-sec2 {
  -webkit-animation: fade-in-top 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) 0.5s both;
  animation: fade-in-top 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) 0.5s both;
}

.fade-in-top-1sec {
  -webkit-animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 1s both;
  animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 1s both;
}

.heartbeat {
  -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
  animation: heartbeat 1.5s ease-in-out infinite both;
}

.heartbeat-5sec {
  -webkit-animation: heartbeat 1.5s ease-in-out 5s infinite both;
  animation: heartbeat 1.5s ease-in-out 5s infinite both;
}

.scale-in-center {
  -webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.scale-in-top {
  -webkit-animation: scale-in-top 0.3s ease-in-out both;
  animation: scale-in-top 0.3s ease-in-out both;
}

.text-pop-up-bottom {
  -webkit-animation: text-pop-up-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: text-pop-up-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.text-pop-up-bottom-delayed-half-sec {
  -webkit-animation: text-pop-up-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s both;
  animation: text-pop-up-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s both;
}

.pulsate-fwd,
.pulsateFwd {
  -webkit-animation: pulsate-fwd 0.5s ease-in-out infinite both;
  animation: pulsate-fwd 0.5s ease-in-out infinite both;
}

.pulsate-fwd-1 {
  -webkit-animation: pulsate-fwd 0.5s ease-in-out infinite 0.25s both;
  animation: pulsate-fwd 0.5s ease-in-out infinite 0.25s both;
}

.pulsate-fwd-2 {
  -webkit-animation: pulsate-fwd 0.5s ease-in-out infinite 0.5s both;
  animation: pulsate-fwd 0.5s ease-in-out infinite 0.5s both;
}

.pulsate-fwd-3 {
  -webkit-animation: pulsate-fwd 0.5s ease-in-out infinite 0.75s both;
  animation: pulsate-fwd 0.5s ease-in-out infinite 0.75s both;
}

.call-pulsate-fwd {
  -webkit-animation: pulsate-fwd 0.25s ease-in-out 2 both;
  animation: pulsate-fwd 0.25s ease-in-out 2 both;
}

.shadow-pop-tr {
  -webkit-animation: shadow-pop-tr 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) both;
  animation: shadow-pop-tr 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) both;
}

.blink1 {
  -webkit-animation: blink-1 0.6s both;
  animation: blink-1 0.6s both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-5-15 15:50:8
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation blink-1
 * ----------------------------------------
 */
@-webkit-keyframes blink-1 {
  0%, 50%, 100% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}
@keyframes blink-1 {
  0%, 50%, 100% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2019-8-8 11:34:22
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation shadow-pop-tr
 * ----------------------------------------
 */
@-webkit-keyframes shadow-pop-tr {
  0% {
    box-shadow: 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff;
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
  }
  100% {
    box-shadow: 1px -1px #ffffff, 2px -2px #ffffff, 3px -3px #ffffff, 4px -4px #ffffff, 5px -5px #ffffff;
    -webkit-transform: translateX(-5px) translateY(5px);
    transform: translateX(-5px) translateY(5px);
  }
}
@keyframes shadow-pop-tr {
  0% {
    box-shadow: 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff;
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
  }
  100% {
    box-shadow: 1px -1px #ffffff, 2px -2px #ffffff, 3px -3px #ffffff, 4px -4px #ffffff, 5px -5px #ffffff;
    -webkit-transform: translateX(-5px) translateY(5px);
    transform: translateX(-5px) translateY(5px);
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2019-3-18 21:43:9
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation pulsate-fwd
 * ----------------------------------------
 */
@-webkit-keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2019-3-18 21:26:18
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation text-pop-up-bottom
 * ----------------------------------------
 */
@-webkit-keyframes text-pop-up-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-shadow: none;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-shadow: 0px 10px 8px rgba(0, 0, 0, 0.33);
    opacity: 1;
  }
}
@keyframes text-pop-up-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-shadow: none;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-shadow: 0px 10px 8px rgba(0, 0, 0, 0.33);
    opacity: 1;
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2019-2-22 12:39:8
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation fade-in-right
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation fade-in-left
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2019-2-18 21:26:9
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */
@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2019-2-18 21:20:38
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation fade-in-fwd
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2019-2-18 21:18:34
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation flip-in-hor-top
 * ----------------------------------------
 */
@-webkit-keyframes flip-in-hor-top {
  0% {
    -webkit-transform: rotateX(-80deg);
    transform: rotateX(-80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
}
@keyframes flip-in-hor-top {
  0% {
    -webkit-transform: rotateX(-80deg);
    transform: rotateX(-80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2019-2-18 21:13:54
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-blurred-bottom {
  0% {
    -webkit-transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
    transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide-in-blurred-bottom {
  0% {
    -webkit-transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
    transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2019-2-18 21:15:33
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation scale-in-ver-bottom
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-ver-bottom {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    opacity: 1;
  }
}
@keyframes scale-in-ver-bottom {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    opacity: 1;
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2019-2-18 21:16:40
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation slide-in-fwd-center
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
    transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
    transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2019-2-22 8:36:15
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2019-2-22 8:40:54
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation scale-in-top
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-top {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 1;
  }
}
@keyframes scale-in-top {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 1;
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2019-2-22 12:36:16
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation fade-in-top
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2019-2-22 12:37:55
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink {
  /**
         * At the start of the animation the dot
         * has an opacity of .2
         */
  0% {
    opacity: 0.2;
  }
  /**
         * At 20% the dot is fully visible and
         * then fades out slowly
         */
  20% {
    opacity: 1;
  }
  /**
         * Until it reaches an opacity of .2 and
         * the animation can start again
         */
  100% {
    opacity: 0.2;
  }
}
.dialing span {
  /**
         * Use the blink animation, which is defined above
         */
  animation-name: blink;
  /**
         * The animation should take 1.4 seconds
         */
  animation-duration: 1.4s;
  /**
         * It will repeat itself forever
         */
  animation-iteration-count: infinite;
  /**
         * This makes sure that the starting style (opacity: .2)
         * of the animation is applied before the animation starts.
         * Otherwise we would see a short flash or would have
         * to set the default styling of the dots to the same
         * as the animation. Same applies for the ending styles.
         */
  animation-fill-mode: both;
}

.dialing span:nth-child(2) {
  /**
         * Starts the animation of the third dot
         * with a delay of .2s, otherwise all dots
         * would animate at the same time
         */
  animation-delay: 0.2s;
}

.dialing span:nth-child(3) {
  /**
         * Starts the animation of the third dot
         * with a delay of .4s, otherwise all dots
         * would animate at the same time
         */
  animation-delay: 0.4s;
}

/* ----------------------------------------------
 * Generated by Animista on 2019-4-1 11:18:34
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation shake-horizontal
 * ----------------------------------------
 */
@-webkit-keyframes shake-horizontal {
  0%, 100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%, 30%, 50%, 70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%, 40%, 60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
}
@keyframes shake-horizontal {
  0%, 100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%, 30%, 50%, 70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%, 40%, 60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2019-12-5 14:49:59
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation fade-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation cards
 * ----------------------------------------
 */
@keyframes cardScaleIn {
  from {
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(0px, 5px);
  }
}
@keyframes cardScaleOut {
  0% {
    transform: translate(0px, 5px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
@-webkit-keyframes cardScaleIn {
  from {
    -webkit-transform: translate(0px, 0px);
  }
  to {
    -webkit-transform: translate(0px, 5px);
  }
}
@-webkit-keyframes cardScaleOut {
  0% {
    -webkit-transform: translate(0px, 5px);
  }
  100% {
    -webkit-transform: translate(0px, 0px);
  }
}
/**
 * ----------------------------------------
 * animation extras cards
 * ----------------------------------------
 */
@keyframes cardExtrascaleIn {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100px);
  }
}
@keyframes cardExtrasScaleOut {
  0% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0);
  }
}
@-webkit-keyframes cardExtrasScaleIn {
  from {
    -webkit-transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(-100px);
  }
}
@-webkit-keyframes cardExtrasScaleOut {
  0% {
    -webkit-transform: translateY(-100px);
  }
  100% {
    -webkit-transform: translateY(0);
  }
}
.card {
  width: 320px;
  height: 512px;
}

.cell {
  width: calc(100% / 20);
  height: calc(100% / 20);
  position: absolute;
  z-index: 2;
}
.cell:nth-child(1) {
  left: calc(1 * calc(100% / 20) - calc(100% / 20));
  top: calc(1 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(1):hover ~ .poster {
  --sx: 45px;
  --sy: 90px;
  --ry: -15deg;
  --rx: 15deg;
}
.cell:nth-child(1):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-10px, 10px, 0px);
}
.cell:nth-child(2) {
  left: calc(2 * calc(100% / 20) - calc(100% / 20));
  top: calc(1 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(2):hover ~ .poster {
  --sx: 40.2631578947px;
  --sy: 90px;
  --ry: -13.4210526316deg;
  --rx: 15deg;
}
.cell:nth-child(2):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-8.9473684211px, 10px, 0px);
}
.cell:nth-child(3) {
  left: calc(3 * calc(100% / 20) - calc(100% / 20));
  top: calc(1 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(3):hover ~ .poster {
  --sx: 35.5263157895px;
  --sy: 90px;
  --ry: -11.8421052632deg;
  --rx: 15deg;
}
.cell:nth-child(3):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-7.8947368421px, 10px, 0px);
}
.cell:nth-child(4) {
  left: calc(4 * calc(100% / 20) - calc(100% / 20));
  top: calc(1 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(4):hover ~ .poster {
  --sx: 30.7894736842px;
  --sy: 90px;
  --ry: -10.2631578947deg;
  --rx: 15deg;
}
.cell:nth-child(4):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-6.8421052632px, 10px, 0px);
}
.cell:nth-child(5) {
  left: calc(5 * calc(100% / 20) - calc(100% / 20));
  top: calc(1 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(5):hover ~ .poster {
  --sx: 26.0526315789px;
  --sy: 90px;
  --ry: -8.6842105263deg;
  --rx: 15deg;
}
.cell:nth-child(5):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-5.7894736842px, 10px, 0px);
}
.cell:nth-child(6) {
  left: calc(6 * calc(100% / 20) - calc(100% / 20));
  top: calc(1 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(6):hover ~ .poster {
  --sx: 21.3157894737px;
  --sy: 90px;
  --ry: -7.1052631579deg;
  --rx: 15deg;
}
.cell:nth-child(6):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-4.7368421053px, 10px, 0px);
}
.cell:nth-child(7) {
  left: calc(7 * calc(100% / 20) - calc(100% / 20));
  top: calc(1 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(7):hover ~ .poster {
  --sx: 16.5789473684px;
  --sy: 90px;
  --ry: -5.5263157895deg;
  --rx: 15deg;
}
.cell:nth-child(7):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-3.6842105263px, 10px, 0px);
}
.cell:nth-child(8) {
  left: calc(8 * calc(100% / 20) - calc(100% / 20));
  top: calc(1 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(8):hover ~ .poster {
  --sx: 11.8421052632px;
  --sy: 90px;
  --ry: -3.9473684211deg;
  --rx: 15deg;
}
.cell:nth-child(8):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-2.6315789474px, 10px, 0px);
}
.cell:nth-child(9) {
  left: calc(9 * calc(100% / 20) - calc(100% / 20));
  top: calc(1 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(9):hover ~ .poster {
  --sx: 7.1052631579px;
  --sy: 90px;
  --ry: -2.3684210526deg;
  --rx: 15deg;
}
.cell:nth-child(9):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-1.5789473684px, 10px, 0px);
}
.cell:nth-child(10) {
  left: calc(10 * calc(100% / 20) - calc(100% / 20));
  top: calc(1 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(10):hover ~ .poster {
  --sx: 2.3684210526px;
  --sy: 90px;
  --ry: -0.7894736842deg;
  --rx: 15deg;
}
.cell:nth-child(10):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-0.5263157895px, 10px, 0px);
}
.cell:nth-child(11) {
  left: calc(11 * calc(100% / 20) - calc(100% / 20));
  top: calc(1 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(11):hover ~ .poster {
  --sx: -2.3684210526px;
  --sy: 90px;
  --ry: 0.7894736842deg;
  --rx: 15deg;
}
.cell:nth-child(11):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(0.5263157895px, 10px, 0px);
}
.cell:nth-child(12) {
  left: calc(12 * calc(100% / 20) - calc(100% / 20));
  top: calc(1 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(12):hover ~ .poster {
  --sx: -7.1052631579px;
  --sy: 90px;
  --ry: 2.3684210526deg;
  --rx: 15deg;
}
.cell:nth-child(12):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(1.5789473684px, 10px, 0px);
}
.cell:nth-child(13) {
  left: calc(13 * calc(100% / 20) - calc(100% / 20));
  top: calc(1 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(13):hover ~ .poster {
  --sx: -11.8421052632px;
  --sy: 90px;
  --ry: 3.9473684211deg;
  --rx: 15deg;
}
.cell:nth-child(13):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(2.6315789474px, 10px, 0px);
}
.cell:nth-child(14) {
  left: calc(14 * calc(100% / 20) - calc(100% / 20));
  top: calc(1 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(14):hover ~ .poster {
  --sx: -16.5789473684px;
  --sy: 90px;
  --ry: 5.5263157895deg;
  --rx: 15deg;
}
.cell:nth-child(14):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(3.6842105263px, 10px, 0px);
}
.cell:nth-child(15) {
  left: calc(15 * calc(100% / 20) - calc(100% / 20));
  top: calc(1 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(15):hover ~ .poster {
  --sx: -21.3157894737px;
  --sy: 90px;
  --ry: 7.1052631579deg;
  --rx: 15deg;
}
.cell:nth-child(15):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(4.7368421053px, 10px, 0px);
}
.cell:nth-child(16) {
  left: calc(16 * calc(100% / 20) - calc(100% / 20));
  top: calc(1 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(16):hover ~ .poster {
  --sx: -26.0526315789px;
  --sy: 90px;
  --ry: 8.6842105263deg;
  --rx: 15deg;
}
.cell:nth-child(16):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(5.7894736842px, 10px, 0px);
}
.cell:nth-child(17) {
  left: calc(17 * calc(100% / 20) - calc(100% / 20));
  top: calc(1 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(17):hover ~ .poster {
  --sx: -30.7894736842px;
  --sy: 90px;
  --ry: 10.2631578947deg;
  --rx: 15deg;
}
.cell:nth-child(17):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(6.8421052632px, 10px, 0px);
}
.cell:nth-child(18) {
  left: calc(18 * calc(100% / 20) - calc(100% / 20));
  top: calc(1 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(18):hover ~ .poster {
  --sx: -35.5263157895px;
  --sy: 90px;
  --ry: 11.8421052632deg;
  --rx: 15deg;
}
.cell:nth-child(18):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(7.8947368421px, 10px, 0px);
}
.cell:nth-child(19) {
  left: calc(19 * calc(100% / 20) - calc(100% / 20));
  top: calc(1 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(19):hover ~ .poster {
  --sx: -40.2631578947px;
  --sy: 90px;
  --ry: 13.4210526316deg;
  --rx: 15deg;
}
.cell:nth-child(19):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(8.9473684211px, 10px, 0px);
}
.cell:nth-child(20) {
  left: calc(20 * calc(100% / 20) - calc(100% / 20));
  top: calc(1 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(20):hover ~ .poster {
  --sx: -45px;
  --sy: 90px;
  --ry: 15deg;
  --rx: 15deg;
}
.cell:nth-child(20):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(10px, 10px, 0px);
}
.cell:nth-child(21) {
  left: calc(1 * calc(100% / 20) - calc(100% / 20));
  top: calc(2 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(21):hover ~ .poster {
  --sx: 45px;
  --sy: 85.2631578947px;
  --ry: -15deg;
  --rx: 13.4210526316deg;
}
.cell:nth-child(21):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-10px, 8.9473684211px, 0px);
}
.cell:nth-child(22) {
  left: calc(2 * calc(100% / 20) - calc(100% / 20));
  top: calc(2 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(22):hover ~ .poster {
  --sx: 40.2631578947px;
  --sy: 85.2631578947px;
  --ry: -13.4210526316deg;
  --rx: 13.4210526316deg;
}
.cell:nth-child(22):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-8.9473684211px, 8.9473684211px, 0px);
}
.cell:nth-child(23) {
  left: calc(3 * calc(100% / 20) - calc(100% / 20));
  top: calc(2 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(23):hover ~ .poster {
  --sx: 35.5263157895px;
  --sy: 85.2631578947px;
  --ry: -11.8421052632deg;
  --rx: 13.4210526316deg;
}
.cell:nth-child(23):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-7.8947368421px, 8.9473684211px, 0px);
}
.cell:nth-child(24) {
  left: calc(4 * calc(100% / 20) - calc(100% / 20));
  top: calc(2 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(24):hover ~ .poster {
  --sx: 30.7894736842px;
  --sy: 85.2631578947px;
  --ry: -10.2631578947deg;
  --rx: 13.4210526316deg;
}
.cell:nth-child(24):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-6.8421052632px, 8.9473684211px, 0px);
}
.cell:nth-child(25) {
  left: calc(5 * calc(100% / 20) - calc(100% / 20));
  top: calc(2 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(25):hover ~ .poster {
  --sx: 26.0526315789px;
  --sy: 85.2631578947px;
  --ry: -8.6842105263deg;
  --rx: 13.4210526316deg;
}
.cell:nth-child(25):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-5.7894736842px, 8.9473684211px, 0px);
}
.cell:nth-child(26) {
  left: calc(6 * calc(100% / 20) - calc(100% / 20));
  top: calc(2 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(26):hover ~ .poster {
  --sx: 21.3157894737px;
  --sy: 85.2631578947px;
  --ry: -7.1052631579deg;
  --rx: 13.4210526316deg;
}
.cell:nth-child(26):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-4.7368421053px, 8.9473684211px, 0px);
}
.cell:nth-child(27) {
  left: calc(7 * calc(100% / 20) - calc(100% / 20));
  top: calc(2 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(27):hover ~ .poster {
  --sx: 16.5789473684px;
  --sy: 85.2631578947px;
  --ry: -5.5263157895deg;
  --rx: 13.4210526316deg;
}
.cell:nth-child(27):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-3.6842105263px, 8.9473684211px, 0px);
}
.cell:nth-child(28) {
  left: calc(8 * calc(100% / 20) - calc(100% / 20));
  top: calc(2 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(28):hover ~ .poster {
  --sx: 11.8421052632px;
  --sy: 85.2631578947px;
  --ry: -3.9473684211deg;
  --rx: 13.4210526316deg;
}
.cell:nth-child(28):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-2.6315789474px, 8.9473684211px, 0px);
}
.cell:nth-child(29) {
  left: calc(9 * calc(100% / 20) - calc(100% / 20));
  top: calc(2 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(29):hover ~ .poster {
  --sx: 7.1052631579px;
  --sy: 85.2631578947px;
  --ry: -2.3684210526deg;
  --rx: 13.4210526316deg;
}
.cell:nth-child(29):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-1.5789473684px, 8.9473684211px, 0px);
}
.cell:nth-child(30) {
  left: calc(10 * calc(100% / 20) - calc(100% / 20));
  top: calc(2 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(30):hover ~ .poster {
  --sx: 2.3684210526px;
  --sy: 85.2631578947px;
  --ry: -0.7894736842deg;
  --rx: 13.4210526316deg;
}
.cell:nth-child(30):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-0.5263157895px, 8.9473684211px, 0px);
}
.cell:nth-child(31) {
  left: calc(11 * calc(100% / 20) - calc(100% / 20));
  top: calc(2 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(31):hover ~ .poster {
  --sx: -2.3684210526px;
  --sy: 85.2631578947px;
  --ry: 0.7894736842deg;
  --rx: 13.4210526316deg;
}
.cell:nth-child(31):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(0.5263157895px, 8.9473684211px, 0px);
}
.cell:nth-child(32) {
  left: calc(12 * calc(100% / 20) - calc(100% / 20));
  top: calc(2 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(32):hover ~ .poster {
  --sx: -7.1052631579px;
  --sy: 85.2631578947px;
  --ry: 2.3684210526deg;
  --rx: 13.4210526316deg;
}
.cell:nth-child(32):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(1.5789473684px, 8.9473684211px, 0px);
}
.cell:nth-child(33) {
  left: calc(13 * calc(100% / 20) - calc(100% / 20));
  top: calc(2 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(33):hover ~ .poster {
  --sx: -11.8421052632px;
  --sy: 85.2631578947px;
  --ry: 3.9473684211deg;
  --rx: 13.4210526316deg;
}
.cell:nth-child(33):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(2.6315789474px, 8.9473684211px, 0px);
}
.cell:nth-child(34) {
  left: calc(14 * calc(100% / 20) - calc(100% / 20));
  top: calc(2 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(34):hover ~ .poster {
  --sx: -16.5789473684px;
  --sy: 85.2631578947px;
  --ry: 5.5263157895deg;
  --rx: 13.4210526316deg;
}
.cell:nth-child(34):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(3.6842105263px, 8.9473684211px, 0px);
}
.cell:nth-child(35) {
  left: calc(15 * calc(100% / 20) - calc(100% / 20));
  top: calc(2 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(35):hover ~ .poster {
  --sx: -21.3157894737px;
  --sy: 85.2631578947px;
  --ry: 7.1052631579deg;
  --rx: 13.4210526316deg;
}
.cell:nth-child(35):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(4.7368421053px, 8.9473684211px, 0px);
}
.cell:nth-child(36) {
  left: calc(16 * calc(100% / 20) - calc(100% / 20));
  top: calc(2 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(36):hover ~ .poster {
  --sx: -26.0526315789px;
  --sy: 85.2631578947px;
  --ry: 8.6842105263deg;
  --rx: 13.4210526316deg;
}
.cell:nth-child(36):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(5.7894736842px, 8.9473684211px, 0px);
}
.cell:nth-child(37) {
  left: calc(17 * calc(100% / 20) - calc(100% / 20));
  top: calc(2 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(37):hover ~ .poster {
  --sx: -30.7894736842px;
  --sy: 85.2631578947px;
  --ry: 10.2631578947deg;
  --rx: 13.4210526316deg;
}
.cell:nth-child(37):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(6.8421052632px, 8.9473684211px, 0px);
}
.cell:nth-child(38) {
  left: calc(18 * calc(100% / 20) - calc(100% / 20));
  top: calc(2 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(38):hover ~ .poster {
  --sx: -35.5263157895px;
  --sy: 85.2631578947px;
  --ry: 11.8421052632deg;
  --rx: 13.4210526316deg;
}
.cell:nth-child(38):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(7.8947368421px, 8.9473684211px, 0px);
}
.cell:nth-child(39) {
  left: calc(19 * calc(100% / 20) - calc(100% / 20));
  top: calc(2 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(39):hover ~ .poster {
  --sx: -40.2631578947px;
  --sy: 85.2631578947px;
  --ry: 13.4210526316deg;
  --rx: 13.4210526316deg;
}
.cell:nth-child(39):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(8.9473684211px, 8.9473684211px, 0px);
}
.cell:nth-child(40) {
  left: calc(20 * calc(100% / 20) - calc(100% / 20));
  top: calc(2 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(40):hover ~ .poster {
  --sx: -45px;
  --sy: 85.2631578947px;
  --ry: 15deg;
  --rx: 13.4210526316deg;
}
.cell:nth-child(40):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(10px, 8.9473684211px, 0px);
}
.cell:nth-child(41) {
  left: calc(1 * calc(100% / 20) - calc(100% / 20));
  top: calc(3 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(41):hover ~ .poster {
  --sx: 45px;
  --sy: 80.5263157895px;
  --ry: -15deg;
  --rx: 11.8421052632deg;
}
.cell:nth-child(41):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-10px, 7.8947368421px, 0px);
}
.cell:nth-child(42) {
  left: calc(2 * calc(100% / 20) - calc(100% / 20));
  top: calc(3 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(42):hover ~ .poster {
  --sx: 40.2631578947px;
  --sy: 80.5263157895px;
  --ry: -13.4210526316deg;
  --rx: 11.8421052632deg;
}
.cell:nth-child(42):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-8.9473684211px, 7.8947368421px, 0px);
}
.cell:nth-child(43) {
  left: calc(3 * calc(100% / 20) - calc(100% / 20));
  top: calc(3 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(43):hover ~ .poster {
  --sx: 35.5263157895px;
  --sy: 80.5263157895px;
  --ry: -11.8421052632deg;
  --rx: 11.8421052632deg;
}
.cell:nth-child(43):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-7.8947368421px, 7.8947368421px, 0px);
}
.cell:nth-child(44) {
  left: calc(4 * calc(100% / 20) - calc(100% / 20));
  top: calc(3 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(44):hover ~ .poster {
  --sx: 30.7894736842px;
  --sy: 80.5263157895px;
  --ry: -10.2631578947deg;
  --rx: 11.8421052632deg;
}
.cell:nth-child(44):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-6.8421052632px, 7.8947368421px, 0px);
}
.cell:nth-child(45) {
  left: calc(5 * calc(100% / 20) - calc(100% / 20));
  top: calc(3 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(45):hover ~ .poster {
  --sx: 26.0526315789px;
  --sy: 80.5263157895px;
  --ry: -8.6842105263deg;
  --rx: 11.8421052632deg;
}
.cell:nth-child(45):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-5.7894736842px, 7.8947368421px, 0px);
}
.cell:nth-child(46) {
  left: calc(6 * calc(100% / 20) - calc(100% / 20));
  top: calc(3 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(46):hover ~ .poster {
  --sx: 21.3157894737px;
  --sy: 80.5263157895px;
  --ry: -7.1052631579deg;
  --rx: 11.8421052632deg;
}
.cell:nth-child(46):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-4.7368421053px, 7.8947368421px, 0px);
}
.cell:nth-child(47) {
  left: calc(7 * calc(100% / 20) - calc(100% / 20));
  top: calc(3 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(47):hover ~ .poster {
  --sx: 16.5789473684px;
  --sy: 80.5263157895px;
  --ry: -5.5263157895deg;
  --rx: 11.8421052632deg;
}
.cell:nth-child(47):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-3.6842105263px, 7.8947368421px, 0px);
}
.cell:nth-child(48) {
  left: calc(8 * calc(100% / 20) - calc(100% / 20));
  top: calc(3 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(48):hover ~ .poster {
  --sx: 11.8421052632px;
  --sy: 80.5263157895px;
  --ry: -3.9473684211deg;
  --rx: 11.8421052632deg;
}
.cell:nth-child(48):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-2.6315789474px, 7.8947368421px, 0px);
}
.cell:nth-child(49) {
  left: calc(9 * calc(100% / 20) - calc(100% / 20));
  top: calc(3 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(49):hover ~ .poster {
  --sx: 7.1052631579px;
  --sy: 80.5263157895px;
  --ry: -2.3684210526deg;
  --rx: 11.8421052632deg;
}
.cell:nth-child(49):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-1.5789473684px, 7.8947368421px, 0px);
}
.cell:nth-child(50) {
  left: calc(10 * calc(100% / 20) - calc(100% / 20));
  top: calc(3 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(50):hover ~ .poster {
  --sx: 2.3684210526px;
  --sy: 80.5263157895px;
  --ry: -0.7894736842deg;
  --rx: 11.8421052632deg;
}
.cell:nth-child(50):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-0.5263157895px, 7.8947368421px, 0px);
}
.cell:nth-child(51) {
  left: calc(11 * calc(100% / 20) - calc(100% / 20));
  top: calc(3 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(51):hover ~ .poster {
  --sx: -2.3684210526px;
  --sy: 80.5263157895px;
  --ry: 0.7894736842deg;
  --rx: 11.8421052632deg;
}
.cell:nth-child(51):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(0.5263157895px, 7.8947368421px, 0px);
}
.cell:nth-child(52) {
  left: calc(12 * calc(100% / 20) - calc(100% / 20));
  top: calc(3 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(52):hover ~ .poster {
  --sx: -7.1052631579px;
  --sy: 80.5263157895px;
  --ry: 2.3684210526deg;
  --rx: 11.8421052632deg;
}
.cell:nth-child(52):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(1.5789473684px, 7.8947368421px, 0px);
}
.cell:nth-child(53) {
  left: calc(13 * calc(100% / 20) - calc(100% / 20));
  top: calc(3 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(53):hover ~ .poster {
  --sx: -11.8421052632px;
  --sy: 80.5263157895px;
  --ry: 3.9473684211deg;
  --rx: 11.8421052632deg;
}
.cell:nth-child(53):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(2.6315789474px, 7.8947368421px, 0px);
}
.cell:nth-child(54) {
  left: calc(14 * calc(100% / 20) - calc(100% / 20));
  top: calc(3 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(54):hover ~ .poster {
  --sx: -16.5789473684px;
  --sy: 80.5263157895px;
  --ry: 5.5263157895deg;
  --rx: 11.8421052632deg;
}
.cell:nth-child(54):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(3.6842105263px, 7.8947368421px, 0px);
}
.cell:nth-child(55) {
  left: calc(15 * calc(100% / 20) - calc(100% / 20));
  top: calc(3 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(55):hover ~ .poster {
  --sx: -21.3157894737px;
  --sy: 80.5263157895px;
  --ry: 7.1052631579deg;
  --rx: 11.8421052632deg;
}
.cell:nth-child(55):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(4.7368421053px, 7.8947368421px, 0px);
}
.cell:nth-child(56) {
  left: calc(16 * calc(100% / 20) - calc(100% / 20));
  top: calc(3 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(56):hover ~ .poster {
  --sx: -26.0526315789px;
  --sy: 80.5263157895px;
  --ry: 8.6842105263deg;
  --rx: 11.8421052632deg;
}
.cell:nth-child(56):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(5.7894736842px, 7.8947368421px, 0px);
}
.cell:nth-child(57) {
  left: calc(17 * calc(100% / 20) - calc(100% / 20));
  top: calc(3 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(57):hover ~ .poster {
  --sx: -30.7894736842px;
  --sy: 80.5263157895px;
  --ry: 10.2631578947deg;
  --rx: 11.8421052632deg;
}
.cell:nth-child(57):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(6.8421052632px, 7.8947368421px, 0px);
}
.cell:nth-child(58) {
  left: calc(18 * calc(100% / 20) - calc(100% / 20));
  top: calc(3 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(58):hover ~ .poster {
  --sx: -35.5263157895px;
  --sy: 80.5263157895px;
  --ry: 11.8421052632deg;
  --rx: 11.8421052632deg;
}
.cell:nth-child(58):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(7.8947368421px, 7.8947368421px, 0px);
}
.cell:nth-child(59) {
  left: calc(19 * calc(100% / 20) - calc(100% / 20));
  top: calc(3 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(59):hover ~ .poster {
  --sx: -40.2631578947px;
  --sy: 80.5263157895px;
  --ry: 13.4210526316deg;
  --rx: 11.8421052632deg;
}
.cell:nth-child(59):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(8.9473684211px, 7.8947368421px, 0px);
}
.cell:nth-child(60) {
  left: calc(20 * calc(100% / 20) - calc(100% / 20));
  top: calc(3 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(60):hover ~ .poster {
  --sx: -45px;
  --sy: 80.5263157895px;
  --ry: 15deg;
  --rx: 11.8421052632deg;
}
.cell:nth-child(60):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(10px, 7.8947368421px, 0px);
}
.cell:nth-child(61) {
  left: calc(1 * calc(100% / 20) - calc(100% / 20));
  top: calc(4 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(61):hover ~ .poster {
  --sx: 45px;
  --sy: 75.7894736842px;
  --ry: -15deg;
  --rx: 10.2631578947deg;
}
.cell:nth-child(61):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-10px, 6.8421052632px, 0px);
}
.cell:nth-child(62) {
  left: calc(2 * calc(100% / 20) - calc(100% / 20));
  top: calc(4 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(62):hover ~ .poster {
  --sx: 40.2631578947px;
  --sy: 75.7894736842px;
  --ry: -13.4210526316deg;
  --rx: 10.2631578947deg;
}
.cell:nth-child(62):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-8.9473684211px, 6.8421052632px, 0px);
}
.cell:nth-child(63) {
  left: calc(3 * calc(100% / 20) - calc(100% / 20));
  top: calc(4 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(63):hover ~ .poster {
  --sx: 35.5263157895px;
  --sy: 75.7894736842px;
  --ry: -11.8421052632deg;
  --rx: 10.2631578947deg;
}
.cell:nth-child(63):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-7.8947368421px, 6.8421052632px, 0px);
}
.cell:nth-child(64) {
  left: calc(4 * calc(100% / 20) - calc(100% / 20));
  top: calc(4 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(64):hover ~ .poster {
  --sx: 30.7894736842px;
  --sy: 75.7894736842px;
  --ry: -10.2631578947deg;
  --rx: 10.2631578947deg;
}
.cell:nth-child(64):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-6.8421052632px, 6.8421052632px, 0px);
}
.cell:nth-child(65) {
  left: calc(5 * calc(100% / 20) - calc(100% / 20));
  top: calc(4 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(65):hover ~ .poster {
  --sx: 26.0526315789px;
  --sy: 75.7894736842px;
  --ry: -8.6842105263deg;
  --rx: 10.2631578947deg;
}
.cell:nth-child(65):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-5.7894736842px, 6.8421052632px, 0px);
}
.cell:nth-child(66) {
  left: calc(6 * calc(100% / 20) - calc(100% / 20));
  top: calc(4 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(66):hover ~ .poster {
  --sx: 21.3157894737px;
  --sy: 75.7894736842px;
  --ry: -7.1052631579deg;
  --rx: 10.2631578947deg;
}
.cell:nth-child(66):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-4.7368421053px, 6.8421052632px, 0px);
}
.cell:nth-child(67) {
  left: calc(7 * calc(100% / 20) - calc(100% / 20));
  top: calc(4 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(67):hover ~ .poster {
  --sx: 16.5789473684px;
  --sy: 75.7894736842px;
  --ry: -5.5263157895deg;
  --rx: 10.2631578947deg;
}
.cell:nth-child(67):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-3.6842105263px, 6.8421052632px, 0px);
}
.cell:nth-child(68) {
  left: calc(8 * calc(100% / 20) - calc(100% / 20));
  top: calc(4 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(68):hover ~ .poster {
  --sx: 11.8421052632px;
  --sy: 75.7894736842px;
  --ry: -3.9473684211deg;
  --rx: 10.2631578947deg;
}
.cell:nth-child(68):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-2.6315789474px, 6.8421052632px, 0px);
}
.cell:nth-child(69) {
  left: calc(9 * calc(100% / 20) - calc(100% / 20));
  top: calc(4 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(69):hover ~ .poster {
  --sx: 7.1052631579px;
  --sy: 75.7894736842px;
  --ry: -2.3684210526deg;
  --rx: 10.2631578947deg;
}
.cell:nth-child(69):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-1.5789473684px, 6.8421052632px, 0px);
}
.cell:nth-child(70) {
  left: calc(10 * calc(100% / 20) - calc(100% / 20));
  top: calc(4 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(70):hover ~ .poster {
  --sx: 2.3684210526px;
  --sy: 75.7894736842px;
  --ry: -0.7894736842deg;
  --rx: 10.2631578947deg;
}
.cell:nth-child(70):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-0.5263157895px, 6.8421052632px, 0px);
}
.cell:nth-child(71) {
  left: calc(11 * calc(100% / 20) - calc(100% / 20));
  top: calc(4 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(71):hover ~ .poster {
  --sx: -2.3684210526px;
  --sy: 75.7894736842px;
  --ry: 0.7894736842deg;
  --rx: 10.2631578947deg;
}
.cell:nth-child(71):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(0.5263157895px, 6.8421052632px, 0px);
}
.cell:nth-child(72) {
  left: calc(12 * calc(100% / 20) - calc(100% / 20));
  top: calc(4 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(72):hover ~ .poster {
  --sx: -7.1052631579px;
  --sy: 75.7894736842px;
  --ry: 2.3684210526deg;
  --rx: 10.2631578947deg;
}
.cell:nth-child(72):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(1.5789473684px, 6.8421052632px, 0px);
}
.cell:nth-child(73) {
  left: calc(13 * calc(100% / 20) - calc(100% / 20));
  top: calc(4 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(73):hover ~ .poster {
  --sx: -11.8421052632px;
  --sy: 75.7894736842px;
  --ry: 3.9473684211deg;
  --rx: 10.2631578947deg;
}
.cell:nth-child(73):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(2.6315789474px, 6.8421052632px, 0px);
}
.cell:nth-child(74) {
  left: calc(14 * calc(100% / 20) - calc(100% / 20));
  top: calc(4 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(74):hover ~ .poster {
  --sx: -16.5789473684px;
  --sy: 75.7894736842px;
  --ry: 5.5263157895deg;
  --rx: 10.2631578947deg;
}
.cell:nth-child(74):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(3.6842105263px, 6.8421052632px, 0px);
}
.cell:nth-child(75) {
  left: calc(15 * calc(100% / 20) - calc(100% / 20));
  top: calc(4 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(75):hover ~ .poster {
  --sx: -21.3157894737px;
  --sy: 75.7894736842px;
  --ry: 7.1052631579deg;
  --rx: 10.2631578947deg;
}
.cell:nth-child(75):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(4.7368421053px, 6.8421052632px, 0px);
}
.cell:nth-child(76) {
  left: calc(16 * calc(100% / 20) - calc(100% / 20));
  top: calc(4 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(76):hover ~ .poster {
  --sx: -26.0526315789px;
  --sy: 75.7894736842px;
  --ry: 8.6842105263deg;
  --rx: 10.2631578947deg;
}
.cell:nth-child(76):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(5.7894736842px, 6.8421052632px, 0px);
}
.cell:nth-child(77) {
  left: calc(17 * calc(100% / 20) - calc(100% / 20));
  top: calc(4 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(77):hover ~ .poster {
  --sx: -30.7894736842px;
  --sy: 75.7894736842px;
  --ry: 10.2631578947deg;
  --rx: 10.2631578947deg;
}
.cell:nth-child(77):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(6.8421052632px, 6.8421052632px, 0px);
}
.cell:nth-child(78) {
  left: calc(18 * calc(100% / 20) - calc(100% / 20));
  top: calc(4 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(78):hover ~ .poster {
  --sx: -35.5263157895px;
  --sy: 75.7894736842px;
  --ry: 11.8421052632deg;
  --rx: 10.2631578947deg;
}
.cell:nth-child(78):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(7.8947368421px, 6.8421052632px, 0px);
}
.cell:nth-child(79) {
  left: calc(19 * calc(100% / 20) - calc(100% / 20));
  top: calc(4 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(79):hover ~ .poster {
  --sx: -40.2631578947px;
  --sy: 75.7894736842px;
  --ry: 13.4210526316deg;
  --rx: 10.2631578947deg;
}
.cell:nth-child(79):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(8.9473684211px, 6.8421052632px, 0px);
}
.cell:nth-child(80) {
  left: calc(20 * calc(100% / 20) - calc(100% / 20));
  top: calc(4 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(80):hover ~ .poster {
  --sx: -45px;
  --sy: 75.7894736842px;
  --ry: 15deg;
  --rx: 10.2631578947deg;
}
.cell:nth-child(80):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(10px, 6.8421052632px, 0px);
}
.cell:nth-child(81) {
  left: calc(1 * calc(100% / 20) - calc(100% / 20));
  top: calc(5 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(81):hover ~ .poster {
  --sx: 45px;
  --sy: 71.0526315789px;
  --ry: -15deg;
  --rx: 8.6842105263deg;
}
.cell:nth-child(81):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-10px, 5.7894736842px, 0px);
}
.cell:nth-child(82) {
  left: calc(2 * calc(100% / 20) - calc(100% / 20));
  top: calc(5 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(82):hover ~ .poster {
  --sx: 40.2631578947px;
  --sy: 71.0526315789px;
  --ry: -13.4210526316deg;
  --rx: 8.6842105263deg;
}
.cell:nth-child(82):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-8.9473684211px, 5.7894736842px, 0px);
}
.cell:nth-child(83) {
  left: calc(3 * calc(100% / 20) - calc(100% / 20));
  top: calc(5 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(83):hover ~ .poster {
  --sx: 35.5263157895px;
  --sy: 71.0526315789px;
  --ry: -11.8421052632deg;
  --rx: 8.6842105263deg;
}
.cell:nth-child(83):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-7.8947368421px, 5.7894736842px, 0px);
}
.cell:nth-child(84) {
  left: calc(4 * calc(100% / 20) - calc(100% / 20));
  top: calc(5 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(84):hover ~ .poster {
  --sx: 30.7894736842px;
  --sy: 71.0526315789px;
  --ry: -10.2631578947deg;
  --rx: 8.6842105263deg;
}
.cell:nth-child(84):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-6.8421052632px, 5.7894736842px, 0px);
}
.cell:nth-child(85) {
  left: calc(5 * calc(100% / 20) - calc(100% / 20));
  top: calc(5 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(85):hover ~ .poster {
  --sx: 26.0526315789px;
  --sy: 71.0526315789px;
  --ry: -8.6842105263deg;
  --rx: 8.6842105263deg;
}
.cell:nth-child(85):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-5.7894736842px, 5.7894736842px, 0px);
}
.cell:nth-child(86) {
  left: calc(6 * calc(100% / 20) - calc(100% / 20));
  top: calc(5 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(86):hover ~ .poster {
  --sx: 21.3157894737px;
  --sy: 71.0526315789px;
  --ry: -7.1052631579deg;
  --rx: 8.6842105263deg;
}
.cell:nth-child(86):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-4.7368421053px, 5.7894736842px, 0px);
}
.cell:nth-child(87) {
  left: calc(7 * calc(100% / 20) - calc(100% / 20));
  top: calc(5 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(87):hover ~ .poster {
  --sx: 16.5789473684px;
  --sy: 71.0526315789px;
  --ry: -5.5263157895deg;
  --rx: 8.6842105263deg;
}
.cell:nth-child(87):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-3.6842105263px, 5.7894736842px, 0px);
}
.cell:nth-child(88) {
  left: calc(8 * calc(100% / 20) - calc(100% / 20));
  top: calc(5 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(88):hover ~ .poster {
  --sx: 11.8421052632px;
  --sy: 71.0526315789px;
  --ry: -3.9473684211deg;
  --rx: 8.6842105263deg;
}
.cell:nth-child(88):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-2.6315789474px, 5.7894736842px, 0px);
}
.cell:nth-child(89) {
  left: calc(9 * calc(100% / 20) - calc(100% / 20));
  top: calc(5 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(89):hover ~ .poster {
  --sx: 7.1052631579px;
  --sy: 71.0526315789px;
  --ry: -2.3684210526deg;
  --rx: 8.6842105263deg;
}
.cell:nth-child(89):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-1.5789473684px, 5.7894736842px, 0px);
}
.cell:nth-child(90) {
  left: calc(10 * calc(100% / 20) - calc(100% / 20));
  top: calc(5 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(90):hover ~ .poster {
  --sx: 2.3684210526px;
  --sy: 71.0526315789px;
  --ry: -0.7894736842deg;
  --rx: 8.6842105263deg;
}
.cell:nth-child(90):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-0.5263157895px, 5.7894736842px, 0px);
}
.cell:nth-child(91) {
  left: calc(11 * calc(100% / 20) - calc(100% / 20));
  top: calc(5 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(91):hover ~ .poster {
  --sx: -2.3684210526px;
  --sy: 71.0526315789px;
  --ry: 0.7894736842deg;
  --rx: 8.6842105263deg;
}
.cell:nth-child(91):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(0.5263157895px, 5.7894736842px, 0px);
}
.cell:nth-child(92) {
  left: calc(12 * calc(100% / 20) - calc(100% / 20));
  top: calc(5 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(92):hover ~ .poster {
  --sx: -7.1052631579px;
  --sy: 71.0526315789px;
  --ry: 2.3684210526deg;
  --rx: 8.6842105263deg;
}
.cell:nth-child(92):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(1.5789473684px, 5.7894736842px, 0px);
}
.cell:nth-child(93) {
  left: calc(13 * calc(100% / 20) - calc(100% / 20));
  top: calc(5 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(93):hover ~ .poster {
  --sx: -11.8421052632px;
  --sy: 71.0526315789px;
  --ry: 3.9473684211deg;
  --rx: 8.6842105263deg;
}
.cell:nth-child(93):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(2.6315789474px, 5.7894736842px, 0px);
}
.cell:nth-child(94) {
  left: calc(14 * calc(100% / 20) - calc(100% / 20));
  top: calc(5 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(94):hover ~ .poster {
  --sx: -16.5789473684px;
  --sy: 71.0526315789px;
  --ry: 5.5263157895deg;
  --rx: 8.6842105263deg;
}
.cell:nth-child(94):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(3.6842105263px, 5.7894736842px, 0px);
}
.cell:nth-child(95) {
  left: calc(15 * calc(100% / 20) - calc(100% / 20));
  top: calc(5 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(95):hover ~ .poster {
  --sx: -21.3157894737px;
  --sy: 71.0526315789px;
  --ry: 7.1052631579deg;
  --rx: 8.6842105263deg;
}
.cell:nth-child(95):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(4.7368421053px, 5.7894736842px, 0px);
}
.cell:nth-child(96) {
  left: calc(16 * calc(100% / 20) - calc(100% / 20));
  top: calc(5 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(96):hover ~ .poster {
  --sx: -26.0526315789px;
  --sy: 71.0526315789px;
  --ry: 8.6842105263deg;
  --rx: 8.6842105263deg;
}
.cell:nth-child(96):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(5.7894736842px, 5.7894736842px, 0px);
}
.cell:nth-child(97) {
  left: calc(17 * calc(100% / 20) - calc(100% / 20));
  top: calc(5 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(97):hover ~ .poster {
  --sx: -30.7894736842px;
  --sy: 71.0526315789px;
  --ry: 10.2631578947deg;
  --rx: 8.6842105263deg;
}
.cell:nth-child(97):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(6.8421052632px, 5.7894736842px, 0px);
}
.cell:nth-child(98) {
  left: calc(18 * calc(100% / 20) - calc(100% / 20));
  top: calc(5 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(98):hover ~ .poster {
  --sx: -35.5263157895px;
  --sy: 71.0526315789px;
  --ry: 11.8421052632deg;
  --rx: 8.6842105263deg;
}
.cell:nth-child(98):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(7.8947368421px, 5.7894736842px, 0px);
}
.cell:nth-child(99) {
  left: calc(19 * calc(100% / 20) - calc(100% / 20));
  top: calc(5 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(99):hover ~ .poster {
  --sx: -40.2631578947px;
  --sy: 71.0526315789px;
  --ry: 13.4210526316deg;
  --rx: 8.6842105263deg;
}
.cell:nth-child(99):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(8.9473684211px, 5.7894736842px, 0px);
}
.cell:nth-child(100) {
  left: calc(20 * calc(100% / 20) - calc(100% / 20));
  top: calc(5 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(100):hover ~ .poster {
  --sx: -45px;
  --sy: 71.0526315789px;
  --ry: 15deg;
  --rx: 8.6842105263deg;
}
.cell:nth-child(100):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(10px, 5.7894736842px, 0px);
}
.cell:nth-child(101) {
  left: calc(1 * calc(100% / 20) - calc(100% / 20));
  top: calc(6 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(101):hover ~ .poster {
  --sx: 45px;
  --sy: 66.3157894737px;
  --ry: -15deg;
  --rx: 7.1052631579deg;
}
.cell:nth-child(101):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-10px, 4.7368421053px, 0px);
}
.cell:nth-child(102) {
  left: calc(2 * calc(100% / 20) - calc(100% / 20));
  top: calc(6 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(102):hover ~ .poster {
  --sx: 40.2631578947px;
  --sy: 66.3157894737px;
  --ry: -13.4210526316deg;
  --rx: 7.1052631579deg;
}
.cell:nth-child(102):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-8.9473684211px, 4.7368421053px, 0px);
}
.cell:nth-child(103) {
  left: calc(3 * calc(100% / 20) - calc(100% / 20));
  top: calc(6 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(103):hover ~ .poster {
  --sx: 35.5263157895px;
  --sy: 66.3157894737px;
  --ry: -11.8421052632deg;
  --rx: 7.1052631579deg;
}
.cell:nth-child(103):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-7.8947368421px, 4.7368421053px, 0px);
}
.cell:nth-child(104) {
  left: calc(4 * calc(100% / 20) - calc(100% / 20));
  top: calc(6 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(104):hover ~ .poster {
  --sx: 30.7894736842px;
  --sy: 66.3157894737px;
  --ry: -10.2631578947deg;
  --rx: 7.1052631579deg;
}
.cell:nth-child(104):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-6.8421052632px, 4.7368421053px, 0px);
}
.cell:nth-child(105) {
  left: calc(5 * calc(100% / 20) - calc(100% / 20));
  top: calc(6 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(105):hover ~ .poster {
  --sx: 26.0526315789px;
  --sy: 66.3157894737px;
  --ry: -8.6842105263deg;
  --rx: 7.1052631579deg;
}
.cell:nth-child(105):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-5.7894736842px, 4.7368421053px, 0px);
}
.cell:nth-child(106) {
  left: calc(6 * calc(100% / 20) - calc(100% / 20));
  top: calc(6 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(106):hover ~ .poster {
  --sx: 21.3157894737px;
  --sy: 66.3157894737px;
  --ry: -7.1052631579deg;
  --rx: 7.1052631579deg;
}
.cell:nth-child(106):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-4.7368421053px, 4.7368421053px, 0px);
}
.cell:nth-child(107) {
  left: calc(7 * calc(100% / 20) - calc(100% / 20));
  top: calc(6 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(107):hover ~ .poster {
  --sx: 16.5789473684px;
  --sy: 66.3157894737px;
  --ry: -5.5263157895deg;
  --rx: 7.1052631579deg;
}
.cell:nth-child(107):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-3.6842105263px, 4.7368421053px, 0px);
}
.cell:nth-child(108) {
  left: calc(8 * calc(100% / 20) - calc(100% / 20));
  top: calc(6 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(108):hover ~ .poster {
  --sx: 11.8421052632px;
  --sy: 66.3157894737px;
  --ry: -3.9473684211deg;
  --rx: 7.1052631579deg;
}
.cell:nth-child(108):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-2.6315789474px, 4.7368421053px, 0px);
}
.cell:nth-child(109) {
  left: calc(9 * calc(100% / 20) - calc(100% / 20));
  top: calc(6 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(109):hover ~ .poster {
  --sx: 7.1052631579px;
  --sy: 66.3157894737px;
  --ry: -2.3684210526deg;
  --rx: 7.1052631579deg;
}
.cell:nth-child(109):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-1.5789473684px, 4.7368421053px, 0px);
}
.cell:nth-child(110) {
  left: calc(10 * calc(100% / 20) - calc(100% / 20));
  top: calc(6 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(110):hover ~ .poster {
  --sx: 2.3684210526px;
  --sy: 66.3157894737px;
  --ry: -0.7894736842deg;
  --rx: 7.1052631579deg;
}
.cell:nth-child(110):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-0.5263157895px, 4.7368421053px, 0px);
}
.cell:nth-child(111) {
  left: calc(11 * calc(100% / 20) - calc(100% / 20));
  top: calc(6 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(111):hover ~ .poster {
  --sx: -2.3684210526px;
  --sy: 66.3157894737px;
  --ry: 0.7894736842deg;
  --rx: 7.1052631579deg;
}
.cell:nth-child(111):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(0.5263157895px, 4.7368421053px, 0px);
}
.cell:nth-child(112) {
  left: calc(12 * calc(100% / 20) - calc(100% / 20));
  top: calc(6 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(112):hover ~ .poster {
  --sx: -7.1052631579px;
  --sy: 66.3157894737px;
  --ry: 2.3684210526deg;
  --rx: 7.1052631579deg;
}
.cell:nth-child(112):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(1.5789473684px, 4.7368421053px, 0px);
}
.cell:nth-child(113) {
  left: calc(13 * calc(100% / 20) - calc(100% / 20));
  top: calc(6 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(113):hover ~ .poster {
  --sx: -11.8421052632px;
  --sy: 66.3157894737px;
  --ry: 3.9473684211deg;
  --rx: 7.1052631579deg;
}
.cell:nth-child(113):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(2.6315789474px, 4.7368421053px, 0px);
}
.cell:nth-child(114) {
  left: calc(14 * calc(100% / 20) - calc(100% / 20));
  top: calc(6 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(114):hover ~ .poster {
  --sx: -16.5789473684px;
  --sy: 66.3157894737px;
  --ry: 5.5263157895deg;
  --rx: 7.1052631579deg;
}
.cell:nth-child(114):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(3.6842105263px, 4.7368421053px, 0px);
}
.cell:nth-child(115) {
  left: calc(15 * calc(100% / 20) - calc(100% / 20));
  top: calc(6 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(115):hover ~ .poster {
  --sx: -21.3157894737px;
  --sy: 66.3157894737px;
  --ry: 7.1052631579deg;
  --rx: 7.1052631579deg;
}
.cell:nth-child(115):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(4.7368421053px, 4.7368421053px, 0px);
}
.cell:nth-child(116) {
  left: calc(16 * calc(100% / 20) - calc(100% / 20));
  top: calc(6 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(116):hover ~ .poster {
  --sx: -26.0526315789px;
  --sy: 66.3157894737px;
  --ry: 8.6842105263deg;
  --rx: 7.1052631579deg;
}
.cell:nth-child(116):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(5.7894736842px, 4.7368421053px, 0px);
}
.cell:nth-child(117) {
  left: calc(17 * calc(100% / 20) - calc(100% / 20));
  top: calc(6 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(117):hover ~ .poster {
  --sx: -30.7894736842px;
  --sy: 66.3157894737px;
  --ry: 10.2631578947deg;
  --rx: 7.1052631579deg;
}
.cell:nth-child(117):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(6.8421052632px, 4.7368421053px, 0px);
}
.cell:nth-child(118) {
  left: calc(18 * calc(100% / 20) - calc(100% / 20));
  top: calc(6 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(118):hover ~ .poster {
  --sx: -35.5263157895px;
  --sy: 66.3157894737px;
  --ry: 11.8421052632deg;
  --rx: 7.1052631579deg;
}
.cell:nth-child(118):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(7.8947368421px, 4.7368421053px, 0px);
}
.cell:nth-child(119) {
  left: calc(19 * calc(100% / 20) - calc(100% / 20));
  top: calc(6 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(119):hover ~ .poster {
  --sx: -40.2631578947px;
  --sy: 66.3157894737px;
  --ry: 13.4210526316deg;
  --rx: 7.1052631579deg;
}
.cell:nth-child(119):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(8.9473684211px, 4.7368421053px, 0px);
}
.cell:nth-child(120) {
  left: calc(20 * calc(100% / 20) - calc(100% / 20));
  top: calc(6 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(120):hover ~ .poster {
  --sx: -45px;
  --sy: 66.3157894737px;
  --ry: 15deg;
  --rx: 7.1052631579deg;
}
.cell:nth-child(120):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(10px, 4.7368421053px, 0px);
}
.cell:nth-child(121) {
  left: calc(1 * calc(100% / 20) - calc(100% / 20));
  top: calc(7 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(121):hover ~ .poster {
  --sx: 45px;
  --sy: 61.5789473684px;
  --ry: -15deg;
  --rx: 5.5263157895deg;
}
.cell:nth-child(121):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-10px, 3.6842105263px, 0px);
}
.cell:nth-child(122) {
  left: calc(2 * calc(100% / 20) - calc(100% / 20));
  top: calc(7 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(122):hover ~ .poster {
  --sx: 40.2631578947px;
  --sy: 61.5789473684px;
  --ry: -13.4210526316deg;
  --rx: 5.5263157895deg;
}
.cell:nth-child(122):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-8.9473684211px, 3.6842105263px, 0px);
}
.cell:nth-child(123) {
  left: calc(3 * calc(100% / 20) - calc(100% / 20));
  top: calc(7 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(123):hover ~ .poster {
  --sx: 35.5263157895px;
  --sy: 61.5789473684px;
  --ry: -11.8421052632deg;
  --rx: 5.5263157895deg;
}
.cell:nth-child(123):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-7.8947368421px, 3.6842105263px, 0px);
}
.cell:nth-child(124) {
  left: calc(4 * calc(100% / 20) - calc(100% / 20));
  top: calc(7 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(124):hover ~ .poster {
  --sx: 30.7894736842px;
  --sy: 61.5789473684px;
  --ry: -10.2631578947deg;
  --rx: 5.5263157895deg;
}
.cell:nth-child(124):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-6.8421052632px, 3.6842105263px, 0px);
}
.cell:nth-child(125) {
  left: calc(5 * calc(100% / 20) - calc(100% / 20));
  top: calc(7 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(125):hover ~ .poster {
  --sx: 26.0526315789px;
  --sy: 61.5789473684px;
  --ry: -8.6842105263deg;
  --rx: 5.5263157895deg;
}
.cell:nth-child(125):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-5.7894736842px, 3.6842105263px, 0px);
}
.cell:nth-child(126) {
  left: calc(6 * calc(100% / 20) - calc(100% / 20));
  top: calc(7 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(126):hover ~ .poster {
  --sx: 21.3157894737px;
  --sy: 61.5789473684px;
  --ry: -7.1052631579deg;
  --rx: 5.5263157895deg;
}
.cell:nth-child(126):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-4.7368421053px, 3.6842105263px, 0px);
}
.cell:nth-child(127) {
  left: calc(7 * calc(100% / 20) - calc(100% / 20));
  top: calc(7 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(127):hover ~ .poster {
  --sx: 16.5789473684px;
  --sy: 61.5789473684px;
  --ry: -5.5263157895deg;
  --rx: 5.5263157895deg;
}
.cell:nth-child(127):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-3.6842105263px, 3.6842105263px, 0px);
}
.cell:nth-child(128) {
  left: calc(8 * calc(100% / 20) - calc(100% / 20));
  top: calc(7 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(128):hover ~ .poster {
  --sx: 11.8421052632px;
  --sy: 61.5789473684px;
  --ry: -3.9473684211deg;
  --rx: 5.5263157895deg;
}
.cell:nth-child(128):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-2.6315789474px, 3.6842105263px, 0px);
}
.cell:nth-child(129) {
  left: calc(9 * calc(100% / 20) - calc(100% / 20));
  top: calc(7 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(129):hover ~ .poster {
  --sx: 7.1052631579px;
  --sy: 61.5789473684px;
  --ry: -2.3684210526deg;
  --rx: 5.5263157895deg;
}
.cell:nth-child(129):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-1.5789473684px, 3.6842105263px, 0px);
}
.cell:nth-child(130) {
  left: calc(10 * calc(100% / 20) - calc(100% / 20));
  top: calc(7 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(130):hover ~ .poster {
  --sx: 2.3684210526px;
  --sy: 61.5789473684px;
  --ry: -0.7894736842deg;
  --rx: 5.5263157895deg;
}
.cell:nth-child(130):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-0.5263157895px, 3.6842105263px, 0px);
}
.cell:nth-child(131) {
  left: calc(11 * calc(100% / 20) - calc(100% / 20));
  top: calc(7 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(131):hover ~ .poster {
  --sx: -2.3684210526px;
  --sy: 61.5789473684px;
  --ry: 0.7894736842deg;
  --rx: 5.5263157895deg;
}
.cell:nth-child(131):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(0.5263157895px, 3.6842105263px, 0px);
}
.cell:nth-child(132) {
  left: calc(12 * calc(100% / 20) - calc(100% / 20));
  top: calc(7 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(132):hover ~ .poster {
  --sx: -7.1052631579px;
  --sy: 61.5789473684px;
  --ry: 2.3684210526deg;
  --rx: 5.5263157895deg;
}
.cell:nth-child(132):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(1.5789473684px, 3.6842105263px, 0px);
}
.cell:nth-child(133) {
  left: calc(13 * calc(100% / 20) - calc(100% / 20));
  top: calc(7 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(133):hover ~ .poster {
  --sx: -11.8421052632px;
  --sy: 61.5789473684px;
  --ry: 3.9473684211deg;
  --rx: 5.5263157895deg;
}
.cell:nth-child(133):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(2.6315789474px, 3.6842105263px, 0px);
}
.cell:nth-child(134) {
  left: calc(14 * calc(100% / 20) - calc(100% / 20));
  top: calc(7 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(134):hover ~ .poster {
  --sx: -16.5789473684px;
  --sy: 61.5789473684px;
  --ry: 5.5263157895deg;
  --rx: 5.5263157895deg;
}
.cell:nth-child(134):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(3.6842105263px, 3.6842105263px, 0px);
}
.cell:nth-child(135) {
  left: calc(15 * calc(100% / 20) - calc(100% / 20));
  top: calc(7 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(135):hover ~ .poster {
  --sx: -21.3157894737px;
  --sy: 61.5789473684px;
  --ry: 7.1052631579deg;
  --rx: 5.5263157895deg;
}
.cell:nth-child(135):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(4.7368421053px, 3.6842105263px, 0px);
}
.cell:nth-child(136) {
  left: calc(16 * calc(100% / 20) - calc(100% / 20));
  top: calc(7 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(136):hover ~ .poster {
  --sx: -26.0526315789px;
  --sy: 61.5789473684px;
  --ry: 8.6842105263deg;
  --rx: 5.5263157895deg;
}
.cell:nth-child(136):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(5.7894736842px, 3.6842105263px, 0px);
}
.cell:nth-child(137) {
  left: calc(17 * calc(100% / 20) - calc(100% / 20));
  top: calc(7 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(137):hover ~ .poster {
  --sx: -30.7894736842px;
  --sy: 61.5789473684px;
  --ry: 10.2631578947deg;
  --rx: 5.5263157895deg;
}
.cell:nth-child(137):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(6.8421052632px, 3.6842105263px, 0px);
}
.cell:nth-child(138) {
  left: calc(18 * calc(100% / 20) - calc(100% / 20));
  top: calc(7 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(138):hover ~ .poster {
  --sx: -35.5263157895px;
  --sy: 61.5789473684px;
  --ry: 11.8421052632deg;
  --rx: 5.5263157895deg;
}
.cell:nth-child(138):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(7.8947368421px, 3.6842105263px, 0px);
}
.cell:nth-child(139) {
  left: calc(19 * calc(100% / 20) - calc(100% / 20));
  top: calc(7 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(139):hover ~ .poster {
  --sx: -40.2631578947px;
  --sy: 61.5789473684px;
  --ry: 13.4210526316deg;
  --rx: 5.5263157895deg;
}
.cell:nth-child(139):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(8.9473684211px, 3.6842105263px, 0px);
}
.cell:nth-child(140) {
  left: calc(20 * calc(100% / 20) - calc(100% / 20));
  top: calc(7 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(140):hover ~ .poster {
  --sx: -45px;
  --sy: 61.5789473684px;
  --ry: 15deg;
  --rx: 5.5263157895deg;
}
.cell:nth-child(140):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(10px, 3.6842105263px, 0px);
}
.cell:nth-child(141) {
  left: calc(1 * calc(100% / 20) - calc(100% / 20));
  top: calc(8 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(141):hover ~ .poster {
  --sx: 45px;
  --sy: 56.8421052632px;
  --ry: -15deg;
  --rx: 3.9473684211deg;
}
.cell:nth-child(141):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-10px, 2.6315789474px, 0px);
}
.cell:nth-child(142) {
  left: calc(2 * calc(100% / 20) - calc(100% / 20));
  top: calc(8 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(142):hover ~ .poster {
  --sx: 40.2631578947px;
  --sy: 56.8421052632px;
  --ry: -13.4210526316deg;
  --rx: 3.9473684211deg;
}
.cell:nth-child(142):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-8.9473684211px, 2.6315789474px, 0px);
}
.cell:nth-child(143) {
  left: calc(3 * calc(100% / 20) - calc(100% / 20));
  top: calc(8 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(143):hover ~ .poster {
  --sx: 35.5263157895px;
  --sy: 56.8421052632px;
  --ry: -11.8421052632deg;
  --rx: 3.9473684211deg;
}
.cell:nth-child(143):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-7.8947368421px, 2.6315789474px, 0px);
}
.cell:nth-child(144) {
  left: calc(4 * calc(100% / 20) - calc(100% / 20));
  top: calc(8 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(144):hover ~ .poster {
  --sx: 30.7894736842px;
  --sy: 56.8421052632px;
  --ry: -10.2631578947deg;
  --rx: 3.9473684211deg;
}
.cell:nth-child(144):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-6.8421052632px, 2.6315789474px, 0px);
}
.cell:nth-child(145) {
  left: calc(5 * calc(100% / 20) - calc(100% / 20));
  top: calc(8 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(145):hover ~ .poster {
  --sx: 26.0526315789px;
  --sy: 56.8421052632px;
  --ry: -8.6842105263deg;
  --rx: 3.9473684211deg;
}
.cell:nth-child(145):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-5.7894736842px, 2.6315789474px, 0px);
}
.cell:nth-child(146) {
  left: calc(6 * calc(100% / 20) - calc(100% / 20));
  top: calc(8 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(146):hover ~ .poster {
  --sx: 21.3157894737px;
  --sy: 56.8421052632px;
  --ry: -7.1052631579deg;
  --rx: 3.9473684211deg;
}
.cell:nth-child(146):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-4.7368421053px, 2.6315789474px, 0px);
}
.cell:nth-child(147) {
  left: calc(7 * calc(100% / 20) - calc(100% / 20));
  top: calc(8 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(147):hover ~ .poster {
  --sx: 16.5789473684px;
  --sy: 56.8421052632px;
  --ry: -5.5263157895deg;
  --rx: 3.9473684211deg;
}
.cell:nth-child(147):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-3.6842105263px, 2.6315789474px, 0px);
}
.cell:nth-child(148) {
  left: calc(8 * calc(100% / 20) - calc(100% / 20));
  top: calc(8 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(148):hover ~ .poster {
  --sx: 11.8421052632px;
  --sy: 56.8421052632px;
  --ry: -3.9473684211deg;
  --rx: 3.9473684211deg;
}
.cell:nth-child(148):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-2.6315789474px, 2.6315789474px, 0px);
}
.cell:nth-child(149) {
  left: calc(9 * calc(100% / 20) - calc(100% / 20));
  top: calc(8 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(149):hover ~ .poster {
  --sx: 7.1052631579px;
  --sy: 56.8421052632px;
  --ry: -2.3684210526deg;
  --rx: 3.9473684211deg;
}
.cell:nth-child(149):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-1.5789473684px, 2.6315789474px, 0px);
}
.cell:nth-child(150) {
  left: calc(10 * calc(100% / 20) - calc(100% / 20));
  top: calc(8 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(150):hover ~ .poster {
  --sx: 2.3684210526px;
  --sy: 56.8421052632px;
  --ry: -0.7894736842deg;
  --rx: 3.9473684211deg;
}
.cell:nth-child(150):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-0.5263157895px, 2.6315789474px, 0px);
}
.cell:nth-child(151) {
  left: calc(11 * calc(100% / 20) - calc(100% / 20));
  top: calc(8 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(151):hover ~ .poster {
  --sx: -2.3684210526px;
  --sy: 56.8421052632px;
  --ry: 0.7894736842deg;
  --rx: 3.9473684211deg;
}
.cell:nth-child(151):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(0.5263157895px, 2.6315789474px, 0px);
}
.cell:nth-child(152) {
  left: calc(12 * calc(100% / 20) - calc(100% / 20));
  top: calc(8 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(152):hover ~ .poster {
  --sx: -7.1052631579px;
  --sy: 56.8421052632px;
  --ry: 2.3684210526deg;
  --rx: 3.9473684211deg;
}
.cell:nth-child(152):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(1.5789473684px, 2.6315789474px, 0px);
}
.cell:nth-child(153) {
  left: calc(13 * calc(100% / 20) - calc(100% / 20));
  top: calc(8 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(153):hover ~ .poster {
  --sx: -11.8421052632px;
  --sy: 56.8421052632px;
  --ry: 3.9473684211deg;
  --rx: 3.9473684211deg;
}
.cell:nth-child(153):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(2.6315789474px, 2.6315789474px, 0px);
}
.cell:nth-child(154) {
  left: calc(14 * calc(100% / 20) - calc(100% / 20));
  top: calc(8 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(154):hover ~ .poster {
  --sx: -16.5789473684px;
  --sy: 56.8421052632px;
  --ry: 5.5263157895deg;
  --rx: 3.9473684211deg;
}
.cell:nth-child(154):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(3.6842105263px, 2.6315789474px, 0px);
}
.cell:nth-child(155) {
  left: calc(15 * calc(100% / 20) - calc(100% / 20));
  top: calc(8 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(155):hover ~ .poster {
  --sx: -21.3157894737px;
  --sy: 56.8421052632px;
  --ry: 7.1052631579deg;
  --rx: 3.9473684211deg;
}
.cell:nth-child(155):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(4.7368421053px, 2.6315789474px, 0px);
}
.cell:nth-child(156) {
  left: calc(16 * calc(100% / 20) - calc(100% / 20));
  top: calc(8 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(156):hover ~ .poster {
  --sx: -26.0526315789px;
  --sy: 56.8421052632px;
  --ry: 8.6842105263deg;
  --rx: 3.9473684211deg;
}
.cell:nth-child(156):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(5.7894736842px, 2.6315789474px, 0px);
}
.cell:nth-child(157) {
  left: calc(17 * calc(100% / 20) - calc(100% / 20));
  top: calc(8 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(157):hover ~ .poster {
  --sx: -30.7894736842px;
  --sy: 56.8421052632px;
  --ry: 10.2631578947deg;
  --rx: 3.9473684211deg;
}
.cell:nth-child(157):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(6.8421052632px, 2.6315789474px, 0px);
}
.cell:nth-child(158) {
  left: calc(18 * calc(100% / 20) - calc(100% / 20));
  top: calc(8 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(158):hover ~ .poster {
  --sx: -35.5263157895px;
  --sy: 56.8421052632px;
  --ry: 11.8421052632deg;
  --rx: 3.9473684211deg;
}
.cell:nth-child(158):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(7.8947368421px, 2.6315789474px, 0px);
}
.cell:nth-child(159) {
  left: calc(19 * calc(100% / 20) - calc(100% / 20));
  top: calc(8 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(159):hover ~ .poster {
  --sx: -40.2631578947px;
  --sy: 56.8421052632px;
  --ry: 13.4210526316deg;
  --rx: 3.9473684211deg;
}
.cell:nth-child(159):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(8.9473684211px, 2.6315789474px, 0px);
}
.cell:nth-child(160) {
  left: calc(20 * calc(100% / 20) - calc(100% / 20));
  top: calc(8 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(160):hover ~ .poster {
  --sx: -45px;
  --sy: 56.8421052632px;
  --ry: 15deg;
  --rx: 3.9473684211deg;
}
.cell:nth-child(160):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(10px, 2.6315789474px, 0px);
}
.cell:nth-child(161) {
  left: calc(1 * calc(100% / 20) - calc(100% / 20));
  top: calc(9 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(161):hover ~ .poster {
  --sx: 45px;
  --sy: 52.1052631579px;
  --ry: -15deg;
  --rx: 2.3684210526deg;
}
.cell:nth-child(161):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-10px, 1.5789473684px, 0px);
}
.cell:nth-child(162) {
  left: calc(2 * calc(100% / 20) - calc(100% / 20));
  top: calc(9 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(162):hover ~ .poster {
  --sx: 40.2631578947px;
  --sy: 52.1052631579px;
  --ry: -13.4210526316deg;
  --rx: 2.3684210526deg;
}
.cell:nth-child(162):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-8.9473684211px, 1.5789473684px, 0px);
}
.cell:nth-child(163) {
  left: calc(3 * calc(100% / 20) - calc(100% / 20));
  top: calc(9 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(163):hover ~ .poster {
  --sx: 35.5263157895px;
  --sy: 52.1052631579px;
  --ry: -11.8421052632deg;
  --rx: 2.3684210526deg;
}
.cell:nth-child(163):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-7.8947368421px, 1.5789473684px, 0px);
}
.cell:nth-child(164) {
  left: calc(4 * calc(100% / 20) - calc(100% / 20));
  top: calc(9 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(164):hover ~ .poster {
  --sx: 30.7894736842px;
  --sy: 52.1052631579px;
  --ry: -10.2631578947deg;
  --rx: 2.3684210526deg;
}
.cell:nth-child(164):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-6.8421052632px, 1.5789473684px, 0px);
}
.cell:nth-child(165) {
  left: calc(5 * calc(100% / 20) - calc(100% / 20));
  top: calc(9 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(165):hover ~ .poster {
  --sx: 26.0526315789px;
  --sy: 52.1052631579px;
  --ry: -8.6842105263deg;
  --rx: 2.3684210526deg;
}
.cell:nth-child(165):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-5.7894736842px, 1.5789473684px, 0px);
}
.cell:nth-child(166) {
  left: calc(6 * calc(100% / 20) - calc(100% / 20));
  top: calc(9 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(166):hover ~ .poster {
  --sx: 21.3157894737px;
  --sy: 52.1052631579px;
  --ry: -7.1052631579deg;
  --rx: 2.3684210526deg;
}
.cell:nth-child(166):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-4.7368421053px, 1.5789473684px, 0px);
}
.cell:nth-child(167) {
  left: calc(7 * calc(100% / 20) - calc(100% / 20));
  top: calc(9 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(167):hover ~ .poster {
  --sx: 16.5789473684px;
  --sy: 52.1052631579px;
  --ry: -5.5263157895deg;
  --rx: 2.3684210526deg;
}
.cell:nth-child(167):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-3.6842105263px, 1.5789473684px, 0px);
}
.cell:nth-child(168) {
  left: calc(8 * calc(100% / 20) - calc(100% / 20));
  top: calc(9 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(168):hover ~ .poster {
  --sx: 11.8421052632px;
  --sy: 52.1052631579px;
  --ry: -3.9473684211deg;
  --rx: 2.3684210526deg;
}
.cell:nth-child(168):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-2.6315789474px, 1.5789473684px, 0px);
}
.cell:nth-child(169) {
  left: calc(9 * calc(100% / 20) - calc(100% / 20));
  top: calc(9 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(169):hover ~ .poster {
  --sx: 7.1052631579px;
  --sy: 52.1052631579px;
  --ry: -2.3684210526deg;
  --rx: 2.3684210526deg;
}
.cell:nth-child(169):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-1.5789473684px, 1.5789473684px, 0px);
}
.cell:nth-child(170) {
  left: calc(10 * calc(100% / 20) - calc(100% / 20));
  top: calc(9 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(170):hover ~ .poster {
  --sx: 2.3684210526px;
  --sy: 52.1052631579px;
  --ry: -0.7894736842deg;
  --rx: 2.3684210526deg;
}
.cell:nth-child(170):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-0.5263157895px, 1.5789473684px, 0px);
}
.cell:nth-child(171) {
  left: calc(11 * calc(100% / 20) - calc(100% / 20));
  top: calc(9 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(171):hover ~ .poster {
  --sx: -2.3684210526px;
  --sy: 52.1052631579px;
  --ry: 0.7894736842deg;
  --rx: 2.3684210526deg;
}
.cell:nth-child(171):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(0.5263157895px, 1.5789473684px, 0px);
}
.cell:nth-child(172) {
  left: calc(12 * calc(100% / 20) - calc(100% / 20));
  top: calc(9 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(172):hover ~ .poster {
  --sx: -7.1052631579px;
  --sy: 52.1052631579px;
  --ry: 2.3684210526deg;
  --rx: 2.3684210526deg;
}
.cell:nth-child(172):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(1.5789473684px, 1.5789473684px, 0px);
}
.cell:nth-child(173) {
  left: calc(13 * calc(100% / 20) - calc(100% / 20));
  top: calc(9 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(173):hover ~ .poster {
  --sx: -11.8421052632px;
  --sy: 52.1052631579px;
  --ry: 3.9473684211deg;
  --rx: 2.3684210526deg;
}
.cell:nth-child(173):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(2.6315789474px, 1.5789473684px, 0px);
}
.cell:nth-child(174) {
  left: calc(14 * calc(100% / 20) - calc(100% / 20));
  top: calc(9 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(174):hover ~ .poster {
  --sx: -16.5789473684px;
  --sy: 52.1052631579px;
  --ry: 5.5263157895deg;
  --rx: 2.3684210526deg;
}
.cell:nth-child(174):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(3.6842105263px, 1.5789473684px, 0px);
}
.cell:nth-child(175) {
  left: calc(15 * calc(100% / 20) - calc(100% / 20));
  top: calc(9 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(175):hover ~ .poster {
  --sx: -21.3157894737px;
  --sy: 52.1052631579px;
  --ry: 7.1052631579deg;
  --rx: 2.3684210526deg;
}
.cell:nth-child(175):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(4.7368421053px, 1.5789473684px, 0px);
}
.cell:nth-child(176) {
  left: calc(16 * calc(100% / 20) - calc(100% / 20));
  top: calc(9 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(176):hover ~ .poster {
  --sx: -26.0526315789px;
  --sy: 52.1052631579px;
  --ry: 8.6842105263deg;
  --rx: 2.3684210526deg;
}
.cell:nth-child(176):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(5.7894736842px, 1.5789473684px, 0px);
}
.cell:nth-child(177) {
  left: calc(17 * calc(100% / 20) - calc(100% / 20));
  top: calc(9 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(177):hover ~ .poster {
  --sx: -30.7894736842px;
  --sy: 52.1052631579px;
  --ry: 10.2631578947deg;
  --rx: 2.3684210526deg;
}
.cell:nth-child(177):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(6.8421052632px, 1.5789473684px, 0px);
}
.cell:nth-child(178) {
  left: calc(18 * calc(100% / 20) - calc(100% / 20));
  top: calc(9 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(178):hover ~ .poster {
  --sx: -35.5263157895px;
  --sy: 52.1052631579px;
  --ry: 11.8421052632deg;
  --rx: 2.3684210526deg;
}
.cell:nth-child(178):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(7.8947368421px, 1.5789473684px, 0px);
}
.cell:nth-child(179) {
  left: calc(19 * calc(100% / 20) - calc(100% / 20));
  top: calc(9 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(179):hover ~ .poster {
  --sx: -40.2631578947px;
  --sy: 52.1052631579px;
  --ry: 13.4210526316deg;
  --rx: 2.3684210526deg;
}
.cell:nth-child(179):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(8.9473684211px, 1.5789473684px, 0px);
}
.cell:nth-child(180) {
  left: calc(20 * calc(100% / 20) - calc(100% / 20));
  top: calc(9 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(180):hover ~ .poster {
  --sx: -45px;
  --sy: 52.1052631579px;
  --ry: 15deg;
  --rx: 2.3684210526deg;
}
.cell:nth-child(180):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(10px, 1.5789473684px, 0px);
}
.cell:nth-child(181) {
  left: calc(1 * calc(100% / 20) - calc(100% / 20));
  top: calc(10 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(181):hover ~ .poster {
  --sx: 45px;
  --sy: 47.3684210526px;
  --ry: -15deg;
  --rx: 0.7894736842deg;
}
.cell:nth-child(181):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-10px, 0.5263157895px, 0px);
}
.cell:nth-child(182) {
  left: calc(2 * calc(100% / 20) - calc(100% / 20));
  top: calc(10 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(182):hover ~ .poster {
  --sx: 40.2631578947px;
  --sy: 47.3684210526px;
  --ry: -13.4210526316deg;
  --rx: 0.7894736842deg;
}
.cell:nth-child(182):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-8.9473684211px, 0.5263157895px, 0px);
}
.cell:nth-child(183) {
  left: calc(3 * calc(100% / 20) - calc(100% / 20));
  top: calc(10 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(183):hover ~ .poster {
  --sx: 35.5263157895px;
  --sy: 47.3684210526px;
  --ry: -11.8421052632deg;
  --rx: 0.7894736842deg;
}
.cell:nth-child(183):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-7.8947368421px, 0.5263157895px, 0px);
}
.cell:nth-child(184) {
  left: calc(4 * calc(100% / 20) - calc(100% / 20));
  top: calc(10 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(184):hover ~ .poster {
  --sx: 30.7894736842px;
  --sy: 47.3684210526px;
  --ry: -10.2631578947deg;
  --rx: 0.7894736842deg;
}
.cell:nth-child(184):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-6.8421052632px, 0.5263157895px, 0px);
}
.cell:nth-child(185) {
  left: calc(5 * calc(100% / 20) - calc(100% / 20));
  top: calc(10 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(185):hover ~ .poster {
  --sx: 26.0526315789px;
  --sy: 47.3684210526px;
  --ry: -8.6842105263deg;
  --rx: 0.7894736842deg;
}
.cell:nth-child(185):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-5.7894736842px, 0.5263157895px, 0px);
}
.cell:nth-child(186) {
  left: calc(6 * calc(100% / 20) - calc(100% / 20));
  top: calc(10 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(186):hover ~ .poster {
  --sx: 21.3157894737px;
  --sy: 47.3684210526px;
  --ry: -7.1052631579deg;
  --rx: 0.7894736842deg;
}
.cell:nth-child(186):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-4.7368421053px, 0.5263157895px, 0px);
}
.cell:nth-child(187) {
  left: calc(7 * calc(100% / 20) - calc(100% / 20));
  top: calc(10 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(187):hover ~ .poster {
  --sx: 16.5789473684px;
  --sy: 47.3684210526px;
  --ry: -5.5263157895deg;
  --rx: 0.7894736842deg;
}
.cell:nth-child(187):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-3.6842105263px, 0.5263157895px, 0px);
}
.cell:nth-child(188) {
  left: calc(8 * calc(100% / 20) - calc(100% / 20));
  top: calc(10 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(188):hover ~ .poster {
  --sx: 11.8421052632px;
  --sy: 47.3684210526px;
  --ry: -3.9473684211deg;
  --rx: 0.7894736842deg;
}
.cell:nth-child(188):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-2.6315789474px, 0.5263157895px, 0px);
}
.cell:nth-child(189) {
  left: calc(9 * calc(100% / 20) - calc(100% / 20));
  top: calc(10 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(189):hover ~ .poster {
  --sx: 7.1052631579px;
  --sy: 47.3684210526px;
  --ry: -2.3684210526deg;
  --rx: 0.7894736842deg;
}
.cell:nth-child(189):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-1.5789473684px, 0.5263157895px, 0px);
}
.cell:nth-child(190) {
  left: calc(10 * calc(100% / 20) - calc(100% / 20));
  top: calc(10 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(190):hover ~ .poster {
  --sx: 2.3684210526px;
  --sy: 47.3684210526px;
  --ry: -0.7894736842deg;
  --rx: 0.7894736842deg;
}
.cell:nth-child(190):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-0.5263157895px, 0.5263157895px, 0px);
}
.cell:nth-child(191) {
  left: calc(11 * calc(100% / 20) - calc(100% / 20));
  top: calc(10 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(191):hover ~ .poster {
  --sx: -2.3684210526px;
  --sy: 47.3684210526px;
  --ry: 0.7894736842deg;
  --rx: 0.7894736842deg;
}
.cell:nth-child(191):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(0.5263157895px, 0.5263157895px, 0px);
}
.cell:nth-child(192) {
  left: calc(12 * calc(100% / 20) - calc(100% / 20));
  top: calc(10 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(192):hover ~ .poster {
  --sx: -7.1052631579px;
  --sy: 47.3684210526px;
  --ry: 2.3684210526deg;
  --rx: 0.7894736842deg;
}
.cell:nth-child(192):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(1.5789473684px, 0.5263157895px, 0px);
}
.cell:nth-child(193) {
  left: calc(13 * calc(100% / 20) - calc(100% / 20));
  top: calc(10 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(193):hover ~ .poster {
  --sx: -11.8421052632px;
  --sy: 47.3684210526px;
  --ry: 3.9473684211deg;
  --rx: 0.7894736842deg;
}
.cell:nth-child(193):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(2.6315789474px, 0.5263157895px, 0px);
}
.cell:nth-child(194) {
  left: calc(14 * calc(100% / 20) - calc(100% / 20));
  top: calc(10 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(194):hover ~ .poster {
  --sx: -16.5789473684px;
  --sy: 47.3684210526px;
  --ry: 5.5263157895deg;
  --rx: 0.7894736842deg;
}
.cell:nth-child(194):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(3.6842105263px, 0.5263157895px, 0px);
}
.cell:nth-child(195) {
  left: calc(15 * calc(100% / 20) - calc(100% / 20));
  top: calc(10 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(195):hover ~ .poster {
  --sx: -21.3157894737px;
  --sy: 47.3684210526px;
  --ry: 7.1052631579deg;
  --rx: 0.7894736842deg;
}
.cell:nth-child(195):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(4.7368421053px, 0.5263157895px, 0px);
}
.cell:nth-child(196) {
  left: calc(16 * calc(100% / 20) - calc(100% / 20));
  top: calc(10 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(196):hover ~ .poster {
  --sx: -26.0526315789px;
  --sy: 47.3684210526px;
  --ry: 8.6842105263deg;
  --rx: 0.7894736842deg;
}
.cell:nth-child(196):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(5.7894736842px, 0.5263157895px, 0px);
}
.cell:nth-child(197) {
  left: calc(17 * calc(100% / 20) - calc(100% / 20));
  top: calc(10 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(197):hover ~ .poster {
  --sx: -30.7894736842px;
  --sy: 47.3684210526px;
  --ry: 10.2631578947deg;
  --rx: 0.7894736842deg;
}
.cell:nth-child(197):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(6.8421052632px, 0.5263157895px, 0px);
}
.cell:nth-child(198) {
  left: calc(18 * calc(100% / 20) - calc(100% / 20));
  top: calc(10 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(198):hover ~ .poster {
  --sx: -35.5263157895px;
  --sy: 47.3684210526px;
  --ry: 11.8421052632deg;
  --rx: 0.7894736842deg;
}
.cell:nth-child(198):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(7.8947368421px, 0.5263157895px, 0px);
}
.cell:nth-child(199) {
  left: calc(19 * calc(100% / 20) - calc(100% / 20));
  top: calc(10 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(199):hover ~ .poster {
  --sx: -40.2631578947px;
  --sy: 47.3684210526px;
  --ry: 13.4210526316deg;
  --rx: 0.7894736842deg;
}
.cell:nth-child(199):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(8.9473684211px, 0.5263157895px, 0px);
}
.cell:nth-child(200) {
  left: calc(20 * calc(100% / 20) - calc(100% / 20));
  top: calc(10 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(200):hover ~ .poster {
  --sx: -45px;
  --sy: 47.3684210526px;
  --ry: 15deg;
  --rx: 0.7894736842deg;
}
.cell:nth-child(200):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(10px, 0.5263157895px, 0px);
}
.cell:nth-child(201) {
  left: calc(1 * calc(100% / 20) - calc(100% / 20));
  top: calc(11 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(201):hover ~ .poster {
  --sx: 45px;
  --sy: 42.6315789474px;
  --ry: -15deg;
  --rx: -0.7894736842deg;
}
.cell:nth-child(201):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-10px, -0.5263157895px, 0px);
}
.cell:nth-child(202) {
  left: calc(2 * calc(100% / 20) - calc(100% / 20));
  top: calc(11 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(202):hover ~ .poster {
  --sx: 40.2631578947px;
  --sy: 42.6315789474px;
  --ry: -13.4210526316deg;
  --rx: -0.7894736842deg;
}
.cell:nth-child(202):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-8.9473684211px, -0.5263157895px, 0px);
}
.cell:nth-child(203) {
  left: calc(3 * calc(100% / 20) - calc(100% / 20));
  top: calc(11 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(203):hover ~ .poster {
  --sx: 35.5263157895px;
  --sy: 42.6315789474px;
  --ry: -11.8421052632deg;
  --rx: -0.7894736842deg;
}
.cell:nth-child(203):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-7.8947368421px, -0.5263157895px, 0px);
}
.cell:nth-child(204) {
  left: calc(4 * calc(100% / 20) - calc(100% / 20));
  top: calc(11 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(204):hover ~ .poster {
  --sx: 30.7894736842px;
  --sy: 42.6315789474px;
  --ry: -10.2631578947deg;
  --rx: -0.7894736842deg;
}
.cell:nth-child(204):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-6.8421052632px, -0.5263157895px, 0px);
}
.cell:nth-child(205) {
  left: calc(5 * calc(100% / 20) - calc(100% / 20));
  top: calc(11 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(205):hover ~ .poster {
  --sx: 26.0526315789px;
  --sy: 42.6315789474px;
  --ry: -8.6842105263deg;
  --rx: -0.7894736842deg;
}
.cell:nth-child(205):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-5.7894736842px, -0.5263157895px, 0px);
}
.cell:nth-child(206) {
  left: calc(6 * calc(100% / 20) - calc(100% / 20));
  top: calc(11 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(206):hover ~ .poster {
  --sx: 21.3157894737px;
  --sy: 42.6315789474px;
  --ry: -7.1052631579deg;
  --rx: -0.7894736842deg;
}
.cell:nth-child(206):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-4.7368421053px, -0.5263157895px, 0px);
}
.cell:nth-child(207) {
  left: calc(7 * calc(100% / 20) - calc(100% / 20));
  top: calc(11 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(207):hover ~ .poster {
  --sx: 16.5789473684px;
  --sy: 42.6315789474px;
  --ry: -5.5263157895deg;
  --rx: -0.7894736842deg;
}
.cell:nth-child(207):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-3.6842105263px, -0.5263157895px, 0px);
}
.cell:nth-child(208) {
  left: calc(8 * calc(100% / 20) - calc(100% / 20));
  top: calc(11 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(208):hover ~ .poster {
  --sx: 11.8421052632px;
  --sy: 42.6315789474px;
  --ry: -3.9473684211deg;
  --rx: -0.7894736842deg;
}
.cell:nth-child(208):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-2.6315789474px, -0.5263157895px, 0px);
}
.cell:nth-child(209) {
  left: calc(9 * calc(100% / 20) - calc(100% / 20));
  top: calc(11 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(209):hover ~ .poster {
  --sx: 7.1052631579px;
  --sy: 42.6315789474px;
  --ry: -2.3684210526deg;
  --rx: -0.7894736842deg;
}
.cell:nth-child(209):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-1.5789473684px, -0.5263157895px, 0px);
}
.cell:nth-child(210) {
  left: calc(10 * calc(100% / 20) - calc(100% / 20));
  top: calc(11 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(210):hover ~ .poster {
  --sx: 2.3684210526px;
  --sy: 42.6315789474px;
  --ry: -0.7894736842deg;
  --rx: -0.7894736842deg;
}
.cell:nth-child(210):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-0.5263157895px, -0.5263157895px, 0px);
}
.cell:nth-child(211) {
  left: calc(11 * calc(100% / 20) - calc(100% / 20));
  top: calc(11 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(211):hover ~ .poster {
  --sx: -2.3684210526px;
  --sy: 42.6315789474px;
  --ry: 0.7894736842deg;
  --rx: -0.7894736842deg;
}
.cell:nth-child(211):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(0.5263157895px, -0.5263157895px, 0px);
}
.cell:nth-child(212) {
  left: calc(12 * calc(100% / 20) - calc(100% / 20));
  top: calc(11 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(212):hover ~ .poster {
  --sx: -7.1052631579px;
  --sy: 42.6315789474px;
  --ry: 2.3684210526deg;
  --rx: -0.7894736842deg;
}
.cell:nth-child(212):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(1.5789473684px, -0.5263157895px, 0px);
}
.cell:nth-child(213) {
  left: calc(13 * calc(100% / 20) - calc(100% / 20));
  top: calc(11 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(213):hover ~ .poster {
  --sx: -11.8421052632px;
  --sy: 42.6315789474px;
  --ry: 3.9473684211deg;
  --rx: -0.7894736842deg;
}
.cell:nth-child(213):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(2.6315789474px, -0.5263157895px, 0px);
}
.cell:nth-child(214) {
  left: calc(14 * calc(100% / 20) - calc(100% / 20));
  top: calc(11 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(214):hover ~ .poster {
  --sx: -16.5789473684px;
  --sy: 42.6315789474px;
  --ry: 5.5263157895deg;
  --rx: -0.7894736842deg;
}
.cell:nth-child(214):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(3.6842105263px, -0.5263157895px, 0px);
}
.cell:nth-child(215) {
  left: calc(15 * calc(100% / 20) - calc(100% / 20));
  top: calc(11 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(215):hover ~ .poster {
  --sx: -21.3157894737px;
  --sy: 42.6315789474px;
  --ry: 7.1052631579deg;
  --rx: -0.7894736842deg;
}
.cell:nth-child(215):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(4.7368421053px, -0.5263157895px, 0px);
}
.cell:nth-child(216) {
  left: calc(16 * calc(100% / 20) - calc(100% / 20));
  top: calc(11 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(216):hover ~ .poster {
  --sx: -26.0526315789px;
  --sy: 42.6315789474px;
  --ry: 8.6842105263deg;
  --rx: -0.7894736842deg;
}
.cell:nth-child(216):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(5.7894736842px, -0.5263157895px, 0px);
}
.cell:nth-child(217) {
  left: calc(17 * calc(100% / 20) - calc(100% / 20));
  top: calc(11 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(217):hover ~ .poster {
  --sx: -30.7894736842px;
  --sy: 42.6315789474px;
  --ry: 10.2631578947deg;
  --rx: -0.7894736842deg;
}
.cell:nth-child(217):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(6.8421052632px, -0.5263157895px, 0px);
}
.cell:nth-child(218) {
  left: calc(18 * calc(100% / 20) - calc(100% / 20));
  top: calc(11 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(218):hover ~ .poster {
  --sx: -35.5263157895px;
  --sy: 42.6315789474px;
  --ry: 11.8421052632deg;
  --rx: -0.7894736842deg;
}
.cell:nth-child(218):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(7.8947368421px, -0.5263157895px, 0px);
}
.cell:nth-child(219) {
  left: calc(19 * calc(100% / 20) - calc(100% / 20));
  top: calc(11 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(219):hover ~ .poster {
  --sx: -40.2631578947px;
  --sy: 42.6315789474px;
  --ry: 13.4210526316deg;
  --rx: -0.7894736842deg;
}
.cell:nth-child(219):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(8.9473684211px, -0.5263157895px, 0px);
}
.cell:nth-child(220) {
  left: calc(20 * calc(100% / 20) - calc(100% / 20));
  top: calc(11 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(220):hover ~ .poster {
  --sx: -45px;
  --sy: 42.6315789474px;
  --ry: 15deg;
  --rx: -0.7894736842deg;
}
.cell:nth-child(220):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(10px, -0.5263157895px, 0px);
}
.cell:nth-child(221) {
  left: calc(1 * calc(100% / 20) - calc(100% / 20));
  top: calc(12 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(221):hover ~ .poster {
  --sx: 45px;
  --sy: 37.8947368421px;
  --ry: -15deg;
  --rx: -2.3684210526deg;
}
.cell:nth-child(221):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-10px, -1.5789473684px, 0px);
}
.cell:nth-child(222) {
  left: calc(2 * calc(100% / 20) - calc(100% / 20));
  top: calc(12 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(222):hover ~ .poster {
  --sx: 40.2631578947px;
  --sy: 37.8947368421px;
  --ry: -13.4210526316deg;
  --rx: -2.3684210526deg;
}
.cell:nth-child(222):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-8.9473684211px, -1.5789473684px, 0px);
}
.cell:nth-child(223) {
  left: calc(3 * calc(100% / 20) - calc(100% / 20));
  top: calc(12 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(223):hover ~ .poster {
  --sx: 35.5263157895px;
  --sy: 37.8947368421px;
  --ry: -11.8421052632deg;
  --rx: -2.3684210526deg;
}
.cell:nth-child(223):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-7.8947368421px, -1.5789473684px, 0px);
}
.cell:nth-child(224) {
  left: calc(4 * calc(100% / 20) - calc(100% / 20));
  top: calc(12 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(224):hover ~ .poster {
  --sx: 30.7894736842px;
  --sy: 37.8947368421px;
  --ry: -10.2631578947deg;
  --rx: -2.3684210526deg;
}
.cell:nth-child(224):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-6.8421052632px, -1.5789473684px, 0px);
}
.cell:nth-child(225) {
  left: calc(5 * calc(100% / 20) - calc(100% / 20));
  top: calc(12 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(225):hover ~ .poster {
  --sx: 26.0526315789px;
  --sy: 37.8947368421px;
  --ry: -8.6842105263deg;
  --rx: -2.3684210526deg;
}
.cell:nth-child(225):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-5.7894736842px, -1.5789473684px, 0px);
}
.cell:nth-child(226) {
  left: calc(6 * calc(100% / 20) - calc(100% / 20));
  top: calc(12 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(226):hover ~ .poster {
  --sx: 21.3157894737px;
  --sy: 37.8947368421px;
  --ry: -7.1052631579deg;
  --rx: -2.3684210526deg;
}
.cell:nth-child(226):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-4.7368421053px, -1.5789473684px, 0px);
}
.cell:nth-child(227) {
  left: calc(7 * calc(100% / 20) - calc(100% / 20));
  top: calc(12 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(227):hover ~ .poster {
  --sx: 16.5789473684px;
  --sy: 37.8947368421px;
  --ry: -5.5263157895deg;
  --rx: -2.3684210526deg;
}
.cell:nth-child(227):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-3.6842105263px, -1.5789473684px, 0px);
}
.cell:nth-child(228) {
  left: calc(8 * calc(100% / 20) - calc(100% / 20));
  top: calc(12 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(228):hover ~ .poster {
  --sx: 11.8421052632px;
  --sy: 37.8947368421px;
  --ry: -3.9473684211deg;
  --rx: -2.3684210526deg;
}
.cell:nth-child(228):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-2.6315789474px, -1.5789473684px, 0px);
}
.cell:nth-child(229) {
  left: calc(9 * calc(100% / 20) - calc(100% / 20));
  top: calc(12 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(229):hover ~ .poster {
  --sx: 7.1052631579px;
  --sy: 37.8947368421px;
  --ry: -2.3684210526deg;
  --rx: -2.3684210526deg;
}
.cell:nth-child(229):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-1.5789473684px, -1.5789473684px, 0px);
}
.cell:nth-child(230) {
  left: calc(10 * calc(100% / 20) - calc(100% / 20));
  top: calc(12 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(230):hover ~ .poster {
  --sx: 2.3684210526px;
  --sy: 37.8947368421px;
  --ry: -0.7894736842deg;
  --rx: -2.3684210526deg;
}
.cell:nth-child(230):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-0.5263157895px, -1.5789473684px, 0px);
}
.cell:nth-child(231) {
  left: calc(11 * calc(100% / 20) - calc(100% / 20));
  top: calc(12 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(231):hover ~ .poster {
  --sx: -2.3684210526px;
  --sy: 37.8947368421px;
  --ry: 0.7894736842deg;
  --rx: -2.3684210526deg;
}
.cell:nth-child(231):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(0.5263157895px, -1.5789473684px, 0px);
}
.cell:nth-child(232) {
  left: calc(12 * calc(100% / 20) - calc(100% / 20));
  top: calc(12 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(232):hover ~ .poster {
  --sx: -7.1052631579px;
  --sy: 37.8947368421px;
  --ry: 2.3684210526deg;
  --rx: -2.3684210526deg;
}
.cell:nth-child(232):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(1.5789473684px, -1.5789473684px, 0px);
}
.cell:nth-child(233) {
  left: calc(13 * calc(100% / 20) - calc(100% / 20));
  top: calc(12 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(233):hover ~ .poster {
  --sx: -11.8421052632px;
  --sy: 37.8947368421px;
  --ry: 3.9473684211deg;
  --rx: -2.3684210526deg;
}
.cell:nth-child(233):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(2.6315789474px, -1.5789473684px, 0px);
}
.cell:nth-child(234) {
  left: calc(14 * calc(100% / 20) - calc(100% / 20));
  top: calc(12 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(234):hover ~ .poster {
  --sx: -16.5789473684px;
  --sy: 37.8947368421px;
  --ry: 5.5263157895deg;
  --rx: -2.3684210526deg;
}
.cell:nth-child(234):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(3.6842105263px, -1.5789473684px, 0px);
}
.cell:nth-child(235) {
  left: calc(15 * calc(100% / 20) - calc(100% / 20));
  top: calc(12 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(235):hover ~ .poster {
  --sx: -21.3157894737px;
  --sy: 37.8947368421px;
  --ry: 7.1052631579deg;
  --rx: -2.3684210526deg;
}
.cell:nth-child(235):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(4.7368421053px, -1.5789473684px, 0px);
}
.cell:nth-child(236) {
  left: calc(16 * calc(100% / 20) - calc(100% / 20));
  top: calc(12 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(236):hover ~ .poster {
  --sx: -26.0526315789px;
  --sy: 37.8947368421px;
  --ry: 8.6842105263deg;
  --rx: -2.3684210526deg;
}
.cell:nth-child(236):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(5.7894736842px, -1.5789473684px, 0px);
}
.cell:nth-child(237) {
  left: calc(17 * calc(100% / 20) - calc(100% / 20));
  top: calc(12 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(237):hover ~ .poster {
  --sx: -30.7894736842px;
  --sy: 37.8947368421px;
  --ry: 10.2631578947deg;
  --rx: -2.3684210526deg;
}
.cell:nth-child(237):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(6.8421052632px, -1.5789473684px, 0px);
}
.cell:nth-child(238) {
  left: calc(18 * calc(100% / 20) - calc(100% / 20));
  top: calc(12 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(238):hover ~ .poster {
  --sx: -35.5263157895px;
  --sy: 37.8947368421px;
  --ry: 11.8421052632deg;
  --rx: -2.3684210526deg;
}
.cell:nth-child(238):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(7.8947368421px, -1.5789473684px, 0px);
}
.cell:nth-child(239) {
  left: calc(19 * calc(100% / 20) - calc(100% / 20));
  top: calc(12 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(239):hover ~ .poster {
  --sx: -40.2631578947px;
  --sy: 37.8947368421px;
  --ry: 13.4210526316deg;
  --rx: -2.3684210526deg;
}
.cell:nth-child(239):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(8.9473684211px, -1.5789473684px, 0px);
}
.cell:nth-child(240) {
  left: calc(20 * calc(100% / 20) - calc(100% / 20));
  top: calc(12 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(240):hover ~ .poster {
  --sx: -45px;
  --sy: 37.8947368421px;
  --ry: 15deg;
  --rx: -2.3684210526deg;
}
.cell:nth-child(240):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(10px, -1.5789473684px, 0px);
}
.cell:nth-child(241) {
  left: calc(1 * calc(100% / 20) - calc(100% / 20));
  top: calc(13 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(241):hover ~ .poster {
  --sx: 45px;
  --sy: 33.1578947368px;
  --ry: -15deg;
  --rx: -3.9473684211deg;
}
.cell:nth-child(241):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-10px, -2.6315789474px, 0px);
}
.cell:nth-child(242) {
  left: calc(2 * calc(100% / 20) - calc(100% / 20));
  top: calc(13 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(242):hover ~ .poster {
  --sx: 40.2631578947px;
  --sy: 33.1578947368px;
  --ry: -13.4210526316deg;
  --rx: -3.9473684211deg;
}
.cell:nth-child(242):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-8.9473684211px, -2.6315789474px, 0px);
}
.cell:nth-child(243) {
  left: calc(3 * calc(100% / 20) - calc(100% / 20));
  top: calc(13 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(243):hover ~ .poster {
  --sx: 35.5263157895px;
  --sy: 33.1578947368px;
  --ry: -11.8421052632deg;
  --rx: -3.9473684211deg;
}
.cell:nth-child(243):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-7.8947368421px, -2.6315789474px, 0px);
}
.cell:nth-child(244) {
  left: calc(4 * calc(100% / 20) - calc(100% / 20));
  top: calc(13 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(244):hover ~ .poster {
  --sx: 30.7894736842px;
  --sy: 33.1578947368px;
  --ry: -10.2631578947deg;
  --rx: -3.9473684211deg;
}
.cell:nth-child(244):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-6.8421052632px, -2.6315789474px, 0px);
}
.cell:nth-child(245) {
  left: calc(5 * calc(100% / 20) - calc(100% / 20));
  top: calc(13 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(245):hover ~ .poster {
  --sx: 26.0526315789px;
  --sy: 33.1578947368px;
  --ry: -8.6842105263deg;
  --rx: -3.9473684211deg;
}
.cell:nth-child(245):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-5.7894736842px, -2.6315789474px, 0px);
}
.cell:nth-child(246) {
  left: calc(6 * calc(100% / 20) - calc(100% / 20));
  top: calc(13 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(246):hover ~ .poster {
  --sx: 21.3157894737px;
  --sy: 33.1578947368px;
  --ry: -7.1052631579deg;
  --rx: -3.9473684211deg;
}
.cell:nth-child(246):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-4.7368421053px, -2.6315789474px, 0px);
}
.cell:nth-child(247) {
  left: calc(7 * calc(100% / 20) - calc(100% / 20));
  top: calc(13 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(247):hover ~ .poster {
  --sx: 16.5789473684px;
  --sy: 33.1578947368px;
  --ry: -5.5263157895deg;
  --rx: -3.9473684211deg;
}
.cell:nth-child(247):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-3.6842105263px, -2.6315789474px, 0px);
}
.cell:nth-child(248) {
  left: calc(8 * calc(100% / 20) - calc(100% / 20));
  top: calc(13 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(248):hover ~ .poster {
  --sx: 11.8421052632px;
  --sy: 33.1578947368px;
  --ry: -3.9473684211deg;
  --rx: -3.9473684211deg;
}
.cell:nth-child(248):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-2.6315789474px, -2.6315789474px, 0px);
}
.cell:nth-child(249) {
  left: calc(9 * calc(100% / 20) - calc(100% / 20));
  top: calc(13 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(249):hover ~ .poster {
  --sx: 7.1052631579px;
  --sy: 33.1578947368px;
  --ry: -2.3684210526deg;
  --rx: -3.9473684211deg;
}
.cell:nth-child(249):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-1.5789473684px, -2.6315789474px, 0px);
}
.cell:nth-child(250) {
  left: calc(10 * calc(100% / 20) - calc(100% / 20));
  top: calc(13 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(250):hover ~ .poster {
  --sx: 2.3684210526px;
  --sy: 33.1578947368px;
  --ry: -0.7894736842deg;
  --rx: -3.9473684211deg;
}
.cell:nth-child(250):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-0.5263157895px, -2.6315789474px, 0px);
}
.cell:nth-child(251) {
  left: calc(11 * calc(100% / 20) - calc(100% / 20));
  top: calc(13 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(251):hover ~ .poster {
  --sx: -2.3684210526px;
  --sy: 33.1578947368px;
  --ry: 0.7894736842deg;
  --rx: -3.9473684211deg;
}
.cell:nth-child(251):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(0.5263157895px, -2.6315789474px, 0px);
}
.cell:nth-child(252) {
  left: calc(12 * calc(100% / 20) - calc(100% / 20));
  top: calc(13 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(252):hover ~ .poster {
  --sx: -7.1052631579px;
  --sy: 33.1578947368px;
  --ry: 2.3684210526deg;
  --rx: -3.9473684211deg;
}
.cell:nth-child(252):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(1.5789473684px, -2.6315789474px, 0px);
}
.cell:nth-child(253) {
  left: calc(13 * calc(100% / 20) - calc(100% / 20));
  top: calc(13 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(253):hover ~ .poster {
  --sx: -11.8421052632px;
  --sy: 33.1578947368px;
  --ry: 3.9473684211deg;
  --rx: -3.9473684211deg;
}
.cell:nth-child(253):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(2.6315789474px, -2.6315789474px, 0px);
}
.cell:nth-child(254) {
  left: calc(14 * calc(100% / 20) - calc(100% / 20));
  top: calc(13 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(254):hover ~ .poster {
  --sx: -16.5789473684px;
  --sy: 33.1578947368px;
  --ry: 5.5263157895deg;
  --rx: -3.9473684211deg;
}
.cell:nth-child(254):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(3.6842105263px, -2.6315789474px, 0px);
}
.cell:nth-child(255) {
  left: calc(15 * calc(100% / 20) - calc(100% / 20));
  top: calc(13 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(255):hover ~ .poster {
  --sx: -21.3157894737px;
  --sy: 33.1578947368px;
  --ry: 7.1052631579deg;
  --rx: -3.9473684211deg;
}
.cell:nth-child(255):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(4.7368421053px, -2.6315789474px, 0px);
}
.cell:nth-child(256) {
  left: calc(16 * calc(100% / 20) - calc(100% / 20));
  top: calc(13 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(256):hover ~ .poster {
  --sx: -26.0526315789px;
  --sy: 33.1578947368px;
  --ry: 8.6842105263deg;
  --rx: -3.9473684211deg;
}
.cell:nth-child(256):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(5.7894736842px, -2.6315789474px, 0px);
}
.cell:nth-child(257) {
  left: calc(17 * calc(100% / 20) - calc(100% / 20));
  top: calc(13 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(257):hover ~ .poster {
  --sx: -30.7894736842px;
  --sy: 33.1578947368px;
  --ry: 10.2631578947deg;
  --rx: -3.9473684211deg;
}
.cell:nth-child(257):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(6.8421052632px, -2.6315789474px, 0px);
}
.cell:nth-child(258) {
  left: calc(18 * calc(100% / 20) - calc(100% / 20));
  top: calc(13 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(258):hover ~ .poster {
  --sx: -35.5263157895px;
  --sy: 33.1578947368px;
  --ry: 11.8421052632deg;
  --rx: -3.9473684211deg;
}
.cell:nth-child(258):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(7.8947368421px, -2.6315789474px, 0px);
}
.cell:nth-child(259) {
  left: calc(19 * calc(100% / 20) - calc(100% / 20));
  top: calc(13 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(259):hover ~ .poster {
  --sx: -40.2631578947px;
  --sy: 33.1578947368px;
  --ry: 13.4210526316deg;
  --rx: -3.9473684211deg;
}
.cell:nth-child(259):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(8.9473684211px, -2.6315789474px, 0px);
}
.cell:nth-child(260) {
  left: calc(20 * calc(100% / 20) - calc(100% / 20));
  top: calc(13 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(260):hover ~ .poster {
  --sx: -45px;
  --sy: 33.1578947368px;
  --ry: 15deg;
  --rx: -3.9473684211deg;
}
.cell:nth-child(260):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(10px, -2.6315789474px, 0px);
}
.cell:nth-child(261) {
  left: calc(1 * calc(100% / 20) - calc(100% / 20));
  top: calc(14 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(261):hover ~ .poster {
  --sx: 45px;
  --sy: 28.4210526316px;
  --ry: -15deg;
  --rx: -5.5263157895deg;
}
.cell:nth-child(261):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-10px, -3.6842105263px, 0px);
}
.cell:nth-child(262) {
  left: calc(2 * calc(100% / 20) - calc(100% / 20));
  top: calc(14 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(262):hover ~ .poster {
  --sx: 40.2631578947px;
  --sy: 28.4210526316px;
  --ry: -13.4210526316deg;
  --rx: -5.5263157895deg;
}
.cell:nth-child(262):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-8.9473684211px, -3.6842105263px, 0px);
}
.cell:nth-child(263) {
  left: calc(3 * calc(100% / 20) - calc(100% / 20));
  top: calc(14 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(263):hover ~ .poster {
  --sx: 35.5263157895px;
  --sy: 28.4210526316px;
  --ry: -11.8421052632deg;
  --rx: -5.5263157895deg;
}
.cell:nth-child(263):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-7.8947368421px, -3.6842105263px, 0px);
}
.cell:nth-child(264) {
  left: calc(4 * calc(100% / 20) - calc(100% / 20));
  top: calc(14 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(264):hover ~ .poster {
  --sx: 30.7894736842px;
  --sy: 28.4210526316px;
  --ry: -10.2631578947deg;
  --rx: -5.5263157895deg;
}
.cell:nth-child(264):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-6.8421052632px, -3.6842105263px, 0px);
}
.cell:nth-child(265) {
  left: calc(5 * calc(100% / 20) - calc(100% / 20));
  top: calc(14 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(265):hover ~ .poster {
  --sx: 26.0526315789px;
  --sy: 28.4210526316px;
  --ry: -8.6842105263deg;
  --rx: -5.5263157895deg;
}
.cell:nth-child(265):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-5.7894736842px, -3.6842105263px, 0px);
}
.cell:nth-child(266) {
  left: calc(6 * calc(100% / 20) - calc(100% / 20));
  top: calc(14 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(266):hover ~ .poster {
  --sx: 21.3157894737px;
  --sy: 28.4210526316px;
  --ry: -7.1052631579deg;
  --rx: -5.5263157895deg;
}
.cell:nth-child(266):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-4.7368421053px, -3.6842105263px, 0px);
}
.cell:nth-child(267) {
  left: calc(7 * calc(100% / 20) - calc(100% / 20));
  top: calc(14 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(267):hover ~ .poster {
  --sx: 16.5789473684px;
  --sy: 28.4210526316px;
  --ry: -5.5263157895deg;
  --rx: -5.5263157895deg;
}
.cell:nth-child(267):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-3.6842105263px, -3.6842105263px, 0px);
}
.cell:nth-child(268) {
  left: calc(8 * calc(100% / 20) - calc(100% / 20));
  top: calc(14 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(268):hover ~ .poster {
  --sx: 11.8421052632px;
  --sy: 28.4210526316px;
  --ry: -3.9473684211deg;
  --rx: -5.5263157895deg;
}
.cell:nth-child(268):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-2.6315789474px, -3.6842105263px, 0px);
}
.cell:nth-child(269) {
  left: calc(9 * calc(100% / 20) - calc(100% / 20));
  top: calc(14 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(269):hover ~ .poster {
  --sx: 7.1052631579px;
  --sy: 28.4210526316px;
  --ry: -2.3684210526deg;
  --rx: -5.5263157895deg;
}
.cell:nth-child(269):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-1.5789473684px, -3.6842105263px, 0px);
}
.cell:nth-child(270) {
  left: calc(10 * calc(100% / 20) - calc(100% / 20));
  top: calc(14 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(270):hover ~ .poster {
  --sx: 2.3684210526px;
  --sy: 28.4210526316px;
  --ry: -0.7894736842deg;
  --rx: -5.5263157895deg;
}
.cell:nth-child(270):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-0.5263157895px, -3.6842105263px, 0px);
}
.cell:nth-child(271) {
  left: calc(11 * calc(100% / 20) - calc(100% / 20));
  top: calc(14 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(271):hover ~ .poster {
  --sx: -2.3684210526px;
  --sy: 28.4210526316px;
  --ry: 0.7894736842deg;
  --rx: -5.5263157895deg;
}
.cell:nth-child(271):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(0.5263157895px, -3.6842105263px, 0px);
}
.cell:nth-child(272) {
  left: calc(12 * calc(100% / 20) - calc(100% / 20));
  top: calc(14 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(272):hover ~ .poster {
  --sx: -7.1052631579px;
  --sy: 28.4210526316px;
  --ry: 2.3684210526deg;
  --rx: -5.5263157895deg;
}
.cell:nth-child(272):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(1.5789473684px, -3.6842105263px, 0px);
}
.cell:nth-child(273) {
  left: calc(13 * calc(100% / 20) - calc(100% / 20));
  top: calc(14 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(273):hover ~ .poster {
  --sx: -11.8421052632px;
  --sy: 28.4210526316px;
  --ry: 3.9473684211deg;
  --rx: -5.5263157895deg;
}
.cell:nth-child(273):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(2.6315789474px, -3.6842105263px, 0px);
}
.cell:nth-child(274) {
  left: calc(14 * calc(100% / 20) - calc(100% / 20));
  top: calc(14 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(274):hover ~ .poster {
  --sx: -16.5789473684px;
  --sy: 28.4210526316px;
  --ry: 5.5263157895deg;
  --rx: -5.5263157895deg;
}
.cell:nth-child(274):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(3.6842105263px, -3.6842105263px, 0px);
}
.cell:nth-child(275) {
  left: calc(15 * calc(100% / 20) - calc(100% / 20));
  top: calc(14 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(275):hover ~ .poster {
  --sx: -21.3157894737px;
  --sy: 28.4210526316px;
  --ry: 7.1052631579deg;
  --rx: -5.5263157895deg;
}
.cell:nth-child(275):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(4.7368421053px, -3.6842105263px, 0px);
}
.cell:nth-child(276) {
  left: calc(16 * calc(100% / 20) - calc(100% / 20));
  top: calc(14 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(276):hover ~ .poster {
  --sx: -26.0526315789px;
  --sy: 28.4210526316px;
  --ry: 8.6842105263deg;
  --rx: -5.5263157895deg;
}
.cell:nth-child(276):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(5.7894736842px, -3.6842105263px, 0px);
}
.cell:nth-child(277) {
  left: calc(17 * calc(100% / 20) - calc(100% / 20));
  top: calc(14 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(277):hover ~ .poster {
  --sx: -30.7894736842px;
  --sy: 28.4210526316px;
  --ry: 10.2631578947deg;
  --rx: -5.5263157895deg;
}
.cell:nth-child(277):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(6.8421052632px, -3.6842105263px, 0px);
}
.cell:nth-child(278) {
  left: calc(18 * calc(100% / 20) - calc(100% / 20));
  top: calc(14 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(278):hover ~ .poster {
  --sx: -35.5263157895px;
  --sy: 28.4210526316px;
  --ry: 11.8421052632deg;
  --rx: -5.5263157895deg;
}
.cell:nth-child(278):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(7.8947368421px, -3.6842105263px, 0px);
}
.cell:nth-child(279) {
  left: calc(19 * calc(100% / 20) - calc(100% / 20));
  top: calc(14 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(279):hover ~ .poster {
  --sx: -40.2631578947px;
  --sy: 28.4210526316px;
  --ry: 13.4210526316deg;
  --rx: -5.5263157895deg;
}
.cell:nth-child(279):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(8.9473684211px, -3.6842105263px, 0px);
}
.cell:nth-child(280) {
  left: calc(20 * calc(100% / 20) - calc(100% / 20));
  top: calc(14 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(280):hover ~ .poster {
  --sx: -45px;
  --sy: 28.4210526316px;
  --ry: 15deg;
  --rx: -5.5263157895deg;
}
.cell:nth-child(280):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(10px, -3.6842105263px, 0px);
}
.cell:nth-child(281) {
  left: calc(1 * calc(100% / 20) - calc(100% / 20));
  top: calc(15 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(281):hover ~ .poster {
  --sx: 45px;
  --sy: 23.6842105263px;
  --ry: -15deg;
  --rx: -7.1052631579deg;
}
.cell:nth-child(281):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-10px, -4.7368421053px, 0px);
}
.cell:nth-child(282) {
  left: calc(2 * calc(100% / 20) - calc(100% / 20));
  top: calc(15 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(282):hover ~ .poster {
  --sx: 40.2631578947px;
  --sy: 23.6842105263px;
  --ry: -13.4210526316deg;
  --rx: -7.1052631579deg;
}
.cell:nth-child(282):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-8.9473684211px, -4.7368421053px, 0px);
}
.cell:nth-child(283) {
  left: calc(3 * calc(100% / 20) - calc(100% / 20));
  top: calc(15 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(283):hover ~ .poster {
  --sx: 35.5263157895px;
  --sy: 23.6842105263px;
  --ry: -11.8421052632deg;
  --rx: -7.1052631579deg;
}
.cell:nth-child(283):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-7.8947368421px, -4.7368421053px, 0px);
}
.cell:nth-child(284) {
  left: calc(4 * calc(100% / 20) - calc(100% / 20));
  top: calc(15 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(284):hover ~ .poster {
  --sx: 30.7894736842px;
  --sy: 23.6842105263px;
  --ry: -10.2631578947deg;
  --rx: -7.1052631579deg;
}
.cell:nth-child(284):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-6.8421052632px, -4.7368421053px, 0px);
}
.cell:nth-child(285) {
  left: calc(5 * calc(100% / 20) - calc(100% / 20));
  top: calc(15 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(285):hover ~ .poster {
  --sx: 26.0526315789px;
  --sy: 23.6842105263px;
  --ry: -8.6842105263deg;
  --rx: -7.1052631579deg;
}
.cell:nth-child(285):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-5.7894736842px, -4.7368421053px, 0px);
}
.cell:nth-child(286) {
  left: calc(6 * calc(100% / 20) - calc(100% / 20));
  top: calc(15 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(286):hover ~ .poster {
  --sx: 21.3157894737px;
  --sy: 23.6842105263px;
  --ry: -7.1052631579deg;
  --rx: -7.1052631579deg;
}
.cell:nth-child(286):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-4.7368421053px, -4.7368421053px, 0px);
}
.cell:nth-child(287) {
  left: calc(7 * calc(100% / 20) - calc(100% / 20));
  top: calc(15 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(287):hover ~ .poster {
  --sx: 16.5789473684px;
  --sy: 23.6842105263px;
  --ry: -5.5263157895deg;
  --rx: -7.1052631579deg;
}
.cell:nth-child(287):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-3.6842105263px, -4.7368421053px, 0px);
}
.cell:nth-child(288) {
  left: calc(8 * calc(100% / 20) - calc(100% / 20));
  top: calc(15 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(288):hover ~ .poster {
  --sx: 11.8421052632px;
  --sy: 23.6842105263px;
  --ry: -3.9473684211deg;
  --rx: -7.1052631579deg;
}
.cell:nth-child(288):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-2.6315789474px, -4.7368421053px, 0px);
}
.cell:nth-child(289) {
  left: calc(9 * calc(100% / 20) - calc(100% / 20));
  top: calc(15 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(289):hover ~ .poster {
  --sx: 7.1052631579px;
  --sy: 23.6842105263px;
  --ry: -2.3684210526deg;
  --rx: -7.1052631579deg;
}
.cell:nth-child(289):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-1.5789473684px, -4.7368421053px, 0px);
}
.cell:nth-child(290) {
  left: calc(10 * calc(100% / 20) - calc(100% / 20));
  top: calc(15 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(290):hover ~ .poster {
  --sx: 2.3684210526px;
  --sy: 23.6842105263px;
  --ry: -0.7894736842deg;
  --rx: -7.1052631579deg;
}
.cell:nth-child(290):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-0.5263157895px, -4.7368421053px, 0px);
}
.cell:nth-child(291) {
  left: calc(11 * calc(100% / 20) - calc(100% / 20));
  top: calc(15 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(291):hover ~ .poster {
  --sx: -2.3684210526px;
  --sy: 23.6842105263px;
  --ry: 0.7894736842deg;
  --rx: -7.1052631579deg;
}
.cell:nth-child(291):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(0.5263157895px, -4.7368421053px, 0px);
}
.cell:nth-child(292) {
  left: calc(12 * calc(100% / 20) - calc(100% / 20));
  top: calc(15 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(292):hover ~ .poster {
  --sx: -7.1052631579px;
  --sy: 23.6842105263px;
  --ry: 2.3684210526deg;
  --rx: -7.1052631579deg;
}
.cell:nth-child(292):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(1.5789473684px, -4.7368421053px, 0px);
}
.cell:nth-child(293) {
  left: calc(13 * calc(100% / 20) - calc(100% / 20));
  top: calc(15 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(293):hover ~ .poster {
  --sx: -11.8421052632px;
  --sy: 23.6842105263px;
  --ry: 3.9473684211deg;
  --rx: -7.1052631579deg;
}
.cell:nth-child(293):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(2.6315789474px, -4.7368421053px, 0px);
}
.cell:nth-child(294) {
  left: calc(14 * calc(100% / 20) - calc(100% / 20));
  top: calc(15 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(294):hover ~ .poster {
  --sx: -16.5789473684px;
  --sy: 23.6842105263px;
  --ry: 5.5263157895deg;
  --rx: -7.1052631579deg;
}
.cell:nth-child(294):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(3.6842105263px, -4.7368421053px, 0px);
}
.cell:nth-child(295) {
  left: calc(15 * calc(100% / 20) - calc(100% / 20));
  top: calc(15 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(295):hover ~ .poster {
  --sx: -21.3157894737px;
  --sy: 23.6842105263px;
  --ry: 7.1052631579deg;
  --rx: -7.1052631579deg;
}
.cell:nth-child(295):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(4.7368421053px, -4.7368421053px, 0px);
}
.cell:nth-child(296) {
  left: calc(16 * calc(100% / 20) - calc(100% / 20));
  top: calc(15 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(296):hover ~ .poster {
  --sx: -26.0526315789px;
  --sy: 23.6842105263px;
  --ry: 8.6842105263deg;
  --rx: -7.1052631579deg;
}
.cell:nth-child(296):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(5.7894736842px, -4.7368421053px, 0px);
}
.cell:nth-child(297) {
  left: calc(17 * calc(100% / 20) - calc(100% / 20));
  top: calc(15 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(297):hover ~ .poster {
  --sx: -30.7894736842px;
  --sy: 23.6842105263px;
  --ry: 10.2631578947deg;
  --rx: -7.1052631579deg;
}
.cell:nth-child(297):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(6.8421052632px, -4.7368421053px, 0px);
}
.cell:nth-child(298) {
  left: calc(18 * calc(100% / 20) - calc(100% / 20));
  top: calc(15 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(298):hover ~ .poster {
  --sx: -35.5263157895px;
  --sy: 23.6842105263px;
  --ry: 11.8421052632deg;
  --rx: -7.1052631579deg;
}
.cell:nth-child(298):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(7.8947368421px, -4.7368421053px, 0px);
}
.cell:nth-child(299) {
  left: calc(19 * calc(100% / 20) - calc(100% / 20));
  top: calc(15 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(299):hover ~ .poster {
  --sx: -40.2631578947px;
  --sy: 23.6842105263px;
  --ry: 13.4210526316deg;
  --rx: -7.1052631579deg;
}
.cell:nth-child(299):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(8.9473684211px, -4.7368421053px, 0px);
}
.cell:nth-child(300) {
  left: calc(20 * calc(100% / 20) - calc(100% / 20));
  top: calc(15 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(300):hover ~ .poster {
  --sx: -45px;
  --sy: 23.6842105263px;
  --ry: 15deg;
  --rx: -7.1052631579deg;
}
.cell:nth-child(300):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(10px, -4.7368421053px, 0px);
}
.cell:nth-child(301) {
  left: calc(1 * calc(100% / 20) - calc(100% / 20));
  top: calc(16 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(301):hover ~ .poster {
  --sx: 45px;
  --sy: 18.9473684211px;
  --ry: -15deg;
  --rx: -8.6842105263deg;
}
.cell:nth-child(301):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-10px, -5.7894736842px, 0px);
}
.cell:nth-child(302) {
  left: calc(2 * calc(100% / 20) - calc(100% / 20));
  top: calc(16 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(302):hover ~ .poster {
  --sx: 40.2631578947px;
  --sy: 18.9473684211px;
  --ry: -13.4210526316deg;
  --rx: -8.6842105263deg;
}
.cell:nth-child(302):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-8.9473684211px, -5.7894736842px, 0px);
}
.cell:nth-child(303) {
  left: calc(3 * calc(100% / 20) - calc(100% / 20));
  top: calc(16 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(303):hover ~ .poster {
  --sx: 35.5263157895px;
  --sy: 18.9473684211px;
  --ry: -11.8421052632deg;
  --rx: -8.6842105263deg;
}
.cell:nth-child(303):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-7.8947368421px, -5.7894736842px, 0px);
}
.cell:nth-child(304) {
  left: calc(4 * calc(100% / 20) - calc(100% / 20));
  top: calc(16 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(304):hover ~ .poster {
  --sx: 30.7894736842px;
  --sy: 18.9473684211px;
  --ry: -10.2631578947deg;
  --rx: -8.6842105263deg;
}
.cell:nth-child(304):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-6.8421052632px, -5.7894736842px, 0px);
}
.cell:nth-child(305) {
  left: calc(5 * calc(100% / 20) - calc(100% / 20));
  top: calc(16 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(305):hover ~ .poster {
  --sx: 26.0526315789px;
  --sy: 18.9473684211px;
  --ry: -8.6842105263deg;
  --rx: -8.6842105263deg;
}
.cell:nth-child(305):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-5.7894736842px, -5.7894736842px, 0px);
}
.cell:nth-child(306) {
  left: calc(6 * calc(100% / 20) - calc(100% / 20));
  top: calc(16 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(306):hover ~ .poster {
  --sx: 21.3157894737px;
  --sy: 18.9473684211px;
  --ry: -7.1052631579deg;
  --rx: -8.6842105263deg;
}
.cell:nth-child(306):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-4.7368421053px, -5.7894736842px, 0px);
}
.cell:nth-child(307) {
  left: calc(7 * calc(100% / 20) - calc(100% / 20));
  top: calc(16 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(307):hover ~ .poster {
  --sx: 16.5789473684px;
  --sy: 18.9473684211px;
  --ry: -5.5263157895deg;
  --rx: -8.6842105263deg;
}
.cell:nth-child(307):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-3.6842105263px, -5.7894736842px, 0px);
}
.cell:nth-child(308) {
  left: calc(8 * calc(100% / 20) - calc(100% / 20));
  top: calc(16 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(308):hover ~ .poster {
  --sx: 11.8421052632px;
  --sy: 18.9473684211px;
  --ry: -3.9473684211deg;
  --rx: -8.6842105263deg;
}
.cell:nth-child(308):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-2.6315789474px, -5.7894736842px, 0px);
}
.cell:nth-child(309) {
  left: calc(9 * calc(100% / 20) - calc(100% / 20));
  top: calc(16 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(309):hover ~ .poster {
  --sx: 7.1052631579px;
  --sy: 18.9473684211px;
  --ry: -2.3684210526deg;
  --rx: -8.6842105263deg;
}
.cell:nth-child(309):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-1.5789473684px, -5.7894736842px, 0px);
}
.cell:nth-child(310) {
  left: calc(10 * calc(100% / 20) - calc(100% / 20));
  top: calc(16 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(310):hover ~ .poster {
  --sx: 2.3684210526px;
  --sy: 18.9473684211px;
  --ry: -0.7894736842deg;
  --rx: -8.6842105263deg;
}
.cell:nth-child(310):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-0.5263157895px, -5.7894736842px, 0px);
}
.cell:nth-child(311) {
  left: calc(11 * calc(100% / 20) - calc(100% / 20));
  top: calc(16 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(311):hover ~ .poster {
  --sx: -2.3684210526px;
  --sy: 18.9473684211px;
  --ry: 0.7894736842deg;
  --rx: -8.6842105263deg;
}
.cell:nth-child(311):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(0.5263157895px, -5.7894736842px, 0px);
}
.cell:nth-child(312) {
  left: calc(12 * calc(100% / 20) - calc(100% / 20));
  top: calc(16 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(312):hover ~ .poster {
  --sx: -7.1052631579px;
  --sy: 18.9473684211px;
  --ry: 2.3684210526deg;
  --rx: -8.6842105263deg;
}
.cell:nth-child(312):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(1.5789473684px, -5.7894736842px, 0px);
}
.cell:nth-child(313) {
  left: calc(13 * calc(100% / 20) - calc(100% / 20));
  top: calc(16 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(313):hover ~ .poster {
  --sx: -11.8421052632px;
  --sy: 18.9473684211px;
  --ry: 3.9473684211deg;
  --rx: -8.6842105263deg;
}
.cell:nth-child(313):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(2.6315789474px, -5.7894736842px, 0px);
}
.cell:nth-child(314) {
  left: calc(14 * calc(100% / 20) - calc(100% / 20));
  top: calc(16 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(314):hover ~ .poster {
  --sx: -16.5789473684px;
  --sy: 18.9473684211px;
  --ry: 5.5263157895deg;
  --rx: -8.6842105263deg;
}
.cell:nth-child(314):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(3.6842105263px, -5.7894736842px, 0px);
}
.cell:nth-child(315) {
  left: calc(15 * calc(100% / 20) - calc(100% / 20));
  top: calc(16 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(315):hover ~ .poster {
  --sx: -21.3157894737px;
  --sy: 18.9473684211px;
  --ry: 7.1052631579deg;
  --rx: -8.6842105263deg;
}
.cell:nth-child(315):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(4.7368421053px, -5.7894736842px, 0px);
}
.cell:nth-child(316) {
  left: calc(16 * calc(100% / 20) - calc(100% / 20));
  top: calc(16 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(316):hover ~ .poster {
  --sx: -26.0526315789px;
  --sy: 18.9473684211px;
  --ry: 8.6842105263deg;
  --rx: -8.6842105263deg;
}
.cell:nth-child(316):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(5.7894736842px, -5.7894736842px, 0px);
}
.cell:nth-child(317) {
  left: calc(17 * calc(100% / 20) - calc(100% / 20));
  top: calc(16 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(317):hover ~ .poster {
  --sx: -30.7894736842px;
  --sy: 18.9473684211px;
  --ry: 10.2631578947deg;
  --rx: -8.6842105263deg;
}
.cell:nth-child(317):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(6.8421052632px, -5.7894736842px, 0px);
}
.cell:nth-child(318) {
  left: calc(18 * calc(100% / 20) - calc(100% / 20));
  top: calc(16 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(318):hover ~ .poster {
  --sx: -35.5263157895px;
  --sy: 18.9473684211px;
  --ry: 11.8421052632deg;
  --rx: -8.6842105263deg;
}
.cell:nth-child(318):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(7.8947368421px, -5.7894736842px, 0px);
}
.cell:nth-child(319) {
  left: calc(19 * calc(100% / 20) - calc(100% / 20));
  top: calc(16 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(319):hover ~ .poster {
  --sx: -40.2631578947px;
  --sy: 18.9473684211px;
  --ry: 13.4210526316deg;
  --rx: -8.6842105263deg;
}
.cell:nth-child(319):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(8.9473684211px, -5.7894736842px, 0px);
}
.cell:nth-child(320) {
  left: calc(20 * calc(100% / 20) - calc(100% / 20));
  top: calc(16 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(320):hover ~ .poster {
  --sx: -45px;
  --sy: 18.9473684211px;
  --ry: 15deg;
  --rx: -8.6842105263deg;
}
.cell:nth-child(320):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(10px, -5.7894736842px, 0px);
}
.cell:nth-child(321) {
  left: calc(1 * calc(100% / 20) - calc(100% / 20));
  top: calc(17 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(321):hover ~ .poster {
  --sx: 45px;
  --sy: 14.2105263158px;
  --ry: -15deg;
  --rx: -10.2631578947deg;
}
.cell:nth-child(321):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-10px, -6.8421052632px, 0px);
}
.cell:nth-child(322) {
  left: calc(2 * calc(100% / 20) - calc(100% / 20));
  top: calc(17 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(322):hover ~ .poster {
  --sx: 40.2631578947px;
  --sy: 14.2105263158px;
  --ry: -13.4210526316deg;
  --rx: -10.2631578947deg;
}
.cell:nth-child(322):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-8.9473684211px, -6.8421052632px, 0px);
}
.cell:nth-child(323) {
  left: calc(3 * calc(100% / 20) - calc(100% / 20));
  top: calc(17 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(323):hover ~ .poster {
  --sx: 35.5263157895px;
  --sy: 14.2105263158px;
  --ry: -11.8421052632deg;
  --rx: -10.2631578947deg;
}
.cell:nth-child(323):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-7.8947368421px, -6.8421052632px, 0px);
}
.cell:nth-child(324) {
  left: calc(4 * calc(100% / 20) - calc(100% / 20));
  top: calc(17 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(324):hover ~ .poster {
  --sx: 30.7894736842px;
  --sy: 14.2105263158px;
  --ry: -10.2631578947deg;
  --rx: -10.2631578947deg;
}
.cell:nth-child(324):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-6.8421052632px, -6.8421052632px, 0px);
}
.cell:nth-child(325) {
  left: calc(5 * calc(100% / 20) - calc(100% / 20));
  top: calc(17 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(325):hover ~ .poster {
  --sx: 26.0526315789px;
  --sy: 14.2105263158px;
  --ry: -8.6842105263deg;
  --rx: -10.2631578947deg;
}
.cell:nth-child(325):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-5.7894736842px, -6.8421052632px, 0px);
}
.cell:nth-child(326) {
  left: calc(6 * calc(100% / 20) - calc(100% / 20));
  top: calc(17 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(326):hover ~ .poster {
  --sx: 21.3157894737px;
  --sy: 14.2105263158px;
  --ry: -7.1052631579deg;
  --rx: -10.2631578947deg;
}
.cell:nth-child(326):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-4.7368421053px, -6.8421052632px, 0px);
}
.cell:nth-child(327) {
  left: calc(7 * calc(100% / 20) - calc(100% / 20));
  top: calc(17 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(327):hover ~ .poster {
  --sx: 16.5789473684px;
  --sy: 14.2105263158px;
  --ry: -5.5263157895deg;
  --rx: -10.2631578947deg;
}
.cell:nth-child(327):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-3.6842105263px, -6.8421052632px, 0px);
}
.cell:nth-child(328) {
  left: calc(8 * calc(100% / 20) - calc(100% / 20));
  top: calc(17 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(328):hover ~ .poster {
  --sx: 11.8421052632px;
  --sy: 14.2105263158px;
  --ry: -3.9473684211deg;
  --rx: -10.2631578947deg;
}
.cell:nth-child(328):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-2.6315789474px, -6.8421052632px, 0px);
}
.cell:nth-child(329) {
  left: calc(9 * calc(100% / 20) - calc(100% / 20));
  top: calc(17 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(329):hover ~ .poster {
  --sx: 7.1052631579px;
  --sy: 14.2105263158px;
  --ry: -2.3684210526deg;
  --rx: -10.2631578947deg;
}
.cell:nth-child(329):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-1.5789473684px, -6.8421052632px, 0px);
}
.cell:nth-child(330) {
  left: calc(10 * calc(100% / 20) - calc(100% / 20));
  top: calc(17 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(330):hover ~ .poster {
  --sx: 2.3684210526px;
  --sy: 14.2105263158px;
  --ry: -0.7894736842deg;
  --rx: -10.2631578947deg;
}
.cell:nth-child(330):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-0.5263157895px, -6.8421052632px, 0px);
}
.cell:nth-child(331) {
  left: calc(11 * calc(100% / 20) - calc(100% / 20));
  top: calc(17 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(331):hover ~ .poster {
  --sx: -2.3684210526px;
  --sy: 14.2105263158px;
  --ry: 0.7894736842deg;
  --rx: -10.2631578947deg;
}
.cell:nth-child(331):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(0.5263157895px, -6.8421052632px, 0px);
}
.cell:nth-child(332) {
  left: calc(12 * calc(100% / 20) - calc(100% / 20));
  top: calc(17 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(332):hover ~ .poster {
  --sx: -7.1052631579px;
  --sy: 14.2105263158px;
  --ry: 2.3684210526deg;
  --rx: -10.2631578947deg;
}
.cell:nth-child(332):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(1.5789473684px, -6.8421052632px, 0px);
}
.cell:nth-child(333) {
  left: calc(13 * calc(100% / 20) - calc(100% / 20));
  top: calc(17 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(333):hover ~ .poster {
  --sx: -11.8421052632px;
  --sy: 14.2105263158px;
  --ry: 3.9473684211deg;
  --rx: -10.2631578947deg;
}
.cell:nth-child(333):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(2.6315789474px, -6.8421052632px, 0px);
}
.cell:nth-child(334) {
  left: calc(14 * calc(100% / 20) - calc(100% / 20));
  top: calc(17 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(334):hover ~ .poster {
  --sx: -16.5789473684px;
  --sy: 14.2105263158px;
  --ry: 5.5263157895deg;
  --rx: -10.2631578947deg;
}
.cell:nth-child(334):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(3.6842105263px, -6.8421052632px, 0px);
}
.cell:nth-child(335) {
  left: calc(15 * calc(100% / 20) - calc(100% / 20));
  top: calc(17 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(335):hover ~ .poster {
  --sx: -21.3157894737px;
  --sy: 14.2105263158px;
  --ry: 7.1052631579deg;
  --rx: -10.2631578947deg;
}
.cell:nth-child(335):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(4.7368421053px, -6.8421052632px, 0px);
}
.cell:nth-child(336) {
  left: calc(16 * calc(100% / 20) - calc(100% / 20));
  top: calc(17 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(336):hover ~ .poster {
  --sx: -26.0526315789px;
  --sy: 14.2105263158px;
  --ry: 8.6842105263deg;
  --rx: -10.2631578947deg;
}
.cell:nth-child(336):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(5.7894736842px, -6.8421052632px, 0px);
}
.cell:nth-child(337) {
  left: calc(17 * calc(100% / 20) - calc(100% / 20));
  top: calc(17 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(337):hover ~ .poster {
  --sx: -30.7894736842px;
  --sy: 14.2105263158px;
  --ry: 10.2631578947deg;
  --rx: -10.2631578947deg;
}
.cell:nth-child(337):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(6.8421052632px, -6.8421052632px, 0px);
}
.cell:nth-child(338) {
  left: calc(18 * calc(100% / 20) - calc(100% / 20));
  top: calc(17 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(338):hover ~ .poster {
  --sx: -35.5263157895px;
  --sy: 14.2105263158px;
  --ry: 11.8421052632deg;
  --rx: -10.2631578947deg;
}
.cell:nth-child(338):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(7.8947368421px, -6.8421052632px, 0px);
}
.cell:nth-child(339) {
  left: calc(19 * calc(100% / 20) - calc(100% / 20));
  top: calc(17 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(339):hover ~ .poster {
  --sx: -40.2631578947px;
  --sy: 14.2105263158px;
  --ry: 13.4210526316deg;
  --rx: -10.2631578947deg;
}
.cell:nth-child(339):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(8.9473684211px, -6.8421052632px, 0px);
}
.cell:nth-child(340) {
  left: calc(20 * calc(100% / 20) - calc(100% / 20));
  top: calc(17 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(340):hover ~ .poster {
  --sx: -45px;
  --sy: 14.2105263158px;
  --ry: 15deg;
  --rx: -10.2631578947deg;
}
.cell:nth-child(340):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(10px, -6.8421052632px, 0px);
}
.cell:nth-child(341) {
  left: calc(1 * calc(100% / 20) - calc(100% / 20));
  top: calc(18 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(341):hover ~ .poster {
  --sx: 45px;
  --sy: 9.4736842105px;
  --ry: -15deg;
  --rx: -11.8421052632deg;
}
.cell:nth-child(341):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-10px, -7.8947368421px, 0px);
}
.cell:nth-child(342) {
  left: calc(2 * calc(100% / 20) - calc(100% / 20));
  top: calc(18 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(342):hover ~ .poster {
  --sx: 40.2631578947px;
  --sy: 9.4736842105px;
  --ry: -13.4210526316deg;
  --rx: -11.8421052632deg;
}
.cell:nth-child(342):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-8.9473684211px, -7.8947368421px, 0px);
}
.cell:nth-child(343) {
  left: calc(3 * calc(100% / 20) - calc(100% / 20));
  top: calc(18 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(343):hover ~ .poster {
  --sx: 35.5263157895px;
  --sy: 9.4736842105px;
  --ry: -11.8421052632deg;
  --rx: -11.8421052632deg;
}
.cell:nth-child(343):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-7.8947368421px, -7.8947368421px, 0px);
}
.cell:nth-child(344) {
  left: calc(4 * calc(100% / 20) - calc(100% / 20));
  top: calc(18 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(344):hover ~ .poster {
  --sx: 30.7894736842px;
  --sy: 9.4736842105px;
  --ry: -10.2631578947deg;
  --rx: -11.8421052632deg;
}
.cell:nth-child(344):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-6.8421052632px, -7.8947368421px, 0px);
}
.cell:nth-child(345) {
  left: calc(5 * calc(100% / 20) - calc(100% / 20));
  top: calc(18 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(345):hover ~ .poster {
  --sx: 26.0526315789px;
  --sy: 9.4736842105px;
  --ry: -8.6842105263deg;
  --rx: -11.8421052632deg;
}
.cell:nth-child(345):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-5.7894736842px, -7.8947368421px, 0px);
}
.cell:nth-child(346) {
  left: calc(6 * calc(100% / 20) - calc(100% / 20));
  top: calc(18 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(346):hover ~ .poster {
  --sx: 21.3157894737px;
  --sy: 9.4736842105px;
  --ry: -7.1052631579deg;
  --rx: -11.8421052632deg;
}
.cell:nth-child(346):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-4.7368421053px, -7.8947368421px, 0px);
}
.cell:nth-child(347) {
  left: calc(7 * calc(100% / 20) - calc(100% / 20));
  top: calc(18 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(347):hover ~ .poster {
  --sx: 16.5789473684px;
  --sy: 9.4736842105px;
  --ry: -5.5263157895deg;
  --rx: -11.8421052632deg;
}
.cell:nth-child(347):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-3.6842105263px, -7.8947368421px, 0px);
}
.cell:nth-child(348) {
  left: calc(8 * calc(100% / 20) - calc(100% / 20));
  top: calc(18 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(348):hover ~ .poster {
  --sx: 11.8421052632px;
  --sy: 9.4736842105px;
  --ry: -3.9473684211deg;
  --rx: -11.8421052632deg;
}
.cell:nth-child(348):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-2.6315789474px, -7.8947368421px, 0px);
}
.cell:nth-child(349) {
  left: calc(9 * calc(100% / 20) - calc(100% / 20));
  top: calc(18 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(349):hover ~ .poster {
  --sx: 7.1052631579px;
  --sy: 9.4736842105px;
  --ry: -2.3684210526deg;
  --rx: -11.8421052632deg;
}
.cell:nth-child(349):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-1.5789473684px, -7.8947368421px, 0px);
}
.cell:nth-child(350) {
  left: calc(10 * calc(100% / 20) - calc(100% / 20));
  top: calc(18 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(350):hover ~ .poster {
  --sx: 2.3684210526px;
  --sy: 9.4736842105px;
  --ry: -0.7894736842deg;
  --rx: -11.8421052632deg;
}
.cell:nth-child(350):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-0.5263157895px, -7.8947368421px, 0px);
}
.cell:nth-child(351) {
  left: calc(11 * calc(100% / 20) - calc(100% / 20));
  top: calc(18 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(351):hover ~ .poster {
  --sx: -2.3684210526px;
  --sy: 9.4736842105px;
  --ry: 0.7894736842deg;
  --rx: -11.8421052632deg;
}
.cell:nth-child(351):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(0.5263157895px, -7.8947368421px, 0px);
}
.cell:nth-child(352) {
  left: calc(12 * calc(100% / 20) - calc(100% / 20));
  top: calc(18 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(352):hover ~ .poster {
  --sx: -7.1052631579px;
  --sy: 9.4736842105px;
  --ry: 2.3684210526deg;
  --rx: -11.8421052632deg;
}
.cell:nth-child(352):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(1.5789473684px, -7.8947368421px, 0px);
}
.cell:nth-child(353) {
  left: calc(13 * calc(100% / 20) - calc(100% / 20));
  top: calc(18 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(353):hover ~ .poster {
  --sx: -11.8421052632px;
  --sy: 9.4736842105px;
  --ry: 3.9473684211deg;
  --rx: -11.8421052632deg;
}
.cell:nth-child(353):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(2.6315789474px, -7.8947368421px, 0px);
}
.cell:nth-child(354) {
  left: calc(14 * calc(100% / 20) - calc(100% / 20));
  top: calc(18 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(354):hover ~ .poster {
  --sx: -16.5789473684px;
  --sy: 9.4736842105px;
  --ry: 5.5263157895deg;
  --rx: -11.8421052632deg;
}
.cell:nth-child(354):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(3.6842105263px, -7.8947368421px, 0px);
}
.cell:nth-child(355) {
  left: calc(15 * calc(100% / 20) - calc(100% / 20));
  top: calc(18 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(355):hover ~ .poster {
  --sx: -21.3157894737px;
  --sy: 9.4736842105px;
  --ry: 7.1052631579deg;
  --rx: -11.8421052632deg;
}
.cell:nth-child(355):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(4.7368421053px, -7.8947368421px, 0px);
}
.cell:nth-child(356) {
  left: calc(16 * calc(100% / 20) - calc(100% / 20));
  top: calc(18 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(356):hover ~ .poster {
  --sx: -26.0526315789px;
  --sy: 9.4736842105px;
  --ry: 8.6842105263deg;
  --rx: -11.8421052632deg;
}
.cell:nth-child(356):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(5.7894736842px, -7.8947368421px, 0px);
}
.cell:nth-child(357) {
  left: calc(17 * calc(100% / 20) - calc(100% / 20));
  top: calc(18 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(357):hover ~ .poster {
  --sx: -30.7894736842px;
  --sy: 9.4736842105px;
  --ry: 10.2631578947deg;
  --rx: -11.8421052632deg;
}
.cell:nth-child(357):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(6.8421052632px, -7.8947368421px, 0px);
}
.cell:nth-child(358) {
  left: calc(18 * calc(100% / 20) - calc(100% / 20));
  top: calc(18 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(358):hover ~ .poster {
  --sx: -35.5263157895px;
  --sy: 9.4736842105px;
  --ry: 11.8421052632deg;
  --rx: -11.8421052632deg;
}
.cell:nth-child(358):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(7.8947368421px, -7.8947368421px, 0px);
}
.cell:nth-child(359) {
  left: calc(19 * calc(100% / 20) - calc(100% / 20));
  top: calc(18 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(359):hover ~ .poster {
  --sx: -40.2631578947px;
  --sy: 9.4736842105px;
  --ry: 13.4210526316deg;
  --rx: -11.8421052632deg;
}
.cell:nth-child(359):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(8.9473684211px, -7.8947368421px, 0px);
}
.cell:nth-child(360) {
  left: calc(20 * calc(100% / 20) - calc(100% / 20));
  top: calc(18 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(360):hover ~ .poster {
  --sx: -45px;
  --sy: 9.4736842105px;
  --ry: 15deg;
  --rx: -11.8421052632deg;
}
.cell:nth-child(360):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(10px, -7.8947368421px, 0px);
}
.cell:nth-child(361) {
  left: calc(1 * calc(100% / 20) - calc(100% / 20));
  top: calc(19 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(361):hover ~ .poster {
  --sx: 45px;
  --sy: 4.7368421053px;
  --ry: -15deg;
  --rx: -13.4210526316deg;
}
.cell:nth-child(361):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-10px, -8.9473684211px, 0px);
}
.cell:nth-child(362) {
  left: calc(2 * calc(100% / 20) - calc(100% / 20));
  top: calc(19 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(362):hover ~ .poster {
  --sx: 40.2631578947px;
  --sy: 4.7368421053px;
  --ry: -13.4210526316deg;
  --rx: -13.4210526316deg;
}
.cell:nth-child(362):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-8.9473684211px, -8.9473684211px, 0px);
}
.cell:nth-child(363) {
  left: calc(3 * calc(100% / 20) - calc(100% / 20));
  top: calc(19 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(363):hover ~ .poster {
  --sx: 35.5263157895px;
  --sy: 4.7368421053px;
  --ry: -11.8421052632deg;
  --rx: -13.4210526316deg;
}
.cell:nth-child(363):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-7.8947368421px, -8.9473684211px, 0px);
}
.cell:nth-child(364) {
  left: calc(4 * calc(100% / 20) - calc(100% / 20));
  top: calc(19 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(364):hover ~ .poster {
  --sx: 30.7894736842px;
  --sy: 4.7368421053px;
  --ry: -10.2631578947deg;
  --rx: -13.4210526316deg;
}
.cell:nth-child(364):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-6.8421052632px, -8.9473684211px, 0px);
}
.cell:nth-child(365) {
  left: calc(5 * calc(100% / 20) - calc(100% / 20));
  top: calc(19 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(365):hover ~ .poster {
  --sx: 26.0526315789px;
  --sy: 4.7368421053px;
  --ry: -8.6842105263deg;
  --rx: -13.4210526316deg;
}
.cell:nth-child(365):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-5.7894736842px, -8.9473684211px, 0px);
}
.cell:nth-child(366) {
  left: calc(6 * calc(100% / 20) - calc(100% / 20));
  top: calc(19 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(366):hover ~ .poster {
  --sx: 21.3157894737px;
  --sy: 4.7368421053px;
  --ry: -7.1052631579deg;
  --rx: -13.4210526316deg;
}
.cell:nth-child(366):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-4.7368421053px, -8.9473684211px, 0px);
}
.cell:nth-child(367) {
  left: calc(7 * calc(100% / 20) - calc(100% / 20));
  top: calc(19 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(367):hover ~ .poster {
  --sx: 16.5789473684px;
  --sy: 4.7368421053px;
  --ry: -5.5263157895deg;
  --rx: -13.4210526316deg;
}
.cell:nth-child(367):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-3.6842105263px, -8.9473684211px, 0px);
}
.cell:nth-child(368) {
  left: calc(8 * calc(100% / 20) - calc(100% / 20));
  top: calc(19 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(368):hover ~ .poster {
  --sx: 11.8421052632px;
  --sy: 4.7368421053px;
  --ry: -3.9473684211deg;
  --rx: -13.4210526316deg;
}
.cell:nth-child(368):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-2.6315789474px, -8.9473684211px, 0px);
}
.cell:nth-child(369) {
  left: calc(9 * calc(100% / 20) - calc(100% / 20));
  top: calc(19 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(369):hover ~ .poster {
  --sx: 7.1052631579px;
  --sy: 4.7368421053px;
  --ry: -2.3684210526deg;
  --rx: -13.4210526316deg;
}
.cell:nth-child(369):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-1.5789473684px, -8.9473684211px, 0px);
}
.cell:nth-child(370) {
  left: calc(10 * calc(100% / 20) - calc(100% / 20));
  top: calc(19 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(370):hover ~ .poster {
  --sx: 2.3684210526px;
  --sy: 4.7368421053px;
  --ry: -0.7894736842deg;
  --rx: -13.4210526316deg;
}
.cell:nth-child(370):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-0.5263157895px, -8.9473684211px, 0px);
}
.cell:nth-child(371) {
  left: calc(11 * calc(100% / 20) - calc(100% / 20));
  top: calc(19 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(371):hover ~ .poster {
  --sx: -2.3684210526px;
  --sy: 4.7368421053px;
  --ry: 0.7894736842deg;
  --rx: -13.4210526316deg;
}
.cell:nth-child(371):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(0.5263157895px, -8.9473684211px, 0px);
}
.cell:nth-child(372) {
  left: calc(12 * calc(100% / 20) - calc(100% / 20));
  top: calc(19 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(372):hover ~ .poster {
  --sx: -7.1052631579px;
  --sy: 4.7368421053px;
  --ry: 2.3684210526deg;
  --rx: -13.4210526316deg;
}
.cell:nth-child(372):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(1.5789473684px, -8.9473684211px, 0px);
}
.cell:nth-child(373) {
  left: calc(13 * calc(100% / 20) - calc(100% / 20));
  top: calc(19 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(373):hover ~ .poster {
  --sx: -11.8421052632px;
  --sy: 4.7368421053px;
  --ry: 3.9473684211deg;
  --rx: -13.4210526316deg;
}
.cell:nth-child(373):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(2.6315789474px, -8.9473684211px, 0px);
}
.cell:nth-child(374) {
  left: calc(14 * calc(100% / 20) - calc(100% / 20));
  top: calc(19 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(374):hover ~ .poster {
  --sx: -16.5789473684px;
  --sy: 4.7368421053px;
  --ry: 5.5263157895deg;
  --rx: -13.4210526316deg;
}
.cell:nth-child(374):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(3.6842105263px, -8.9473684211px, 0px);
}
.cell:nth-child(375) {
  left: calc(15 * calc(100% / 20) - calc(100% / 20));
  top: calc(19 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(375):hover ~ .poster {
  --sx: -21.3157894737px;
  --sy: 4.7368421053px;
  --ry: 7.1052631579deg;
  --rx: -13.4210526316deg;
}
.cell:nth-child(375):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(4.7368421053px, -8.9473684211px, 0px);
}
.cell:nth-child(376) {
  left: calc(16 * calc(100% / 20) - calc(100% / 20));
  top: calc(19 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(376):hover ~ .poster {
  --sx: -26.0526315789px;
  --sy: 4.7368421053px;
  --ry: 8.6842105263deg;
  --rx: -13.4210526316deg;
}
.cell:nth-child(376):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(5.7894736842px, -8.9473684211px, 0px);
}
.cell:nth-child(377) {
  left: calc(17 * calc(100% / 20) - calc(100% / 20));
  top: calc(19 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(377):hover ~ .poster {
  --sx: -30.7894736842px;
  --sy: 4.7368421053px;
  --ry: 10.2631578947deg;
  --rx: -13.4210526316deg;
}
.cell:nth-child(377):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(6.8421052632px, -8.9473684211px, 0px);
}
.cell:nth-child(378) {
  left: calc(18 * calc(100% / 20) - calc(100% / 20));
  top: calc(19 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(378):hover ~ .poster {
  --sx: -35.5263157895px;
  --sy: 4.7368421053px;
  --ry: 11.8421052632deg;
  --rx: -13.4210526316deg;
}
.cell:nth-child(378):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(7.8947368421px, -8.9473684211px, 0px);
}
.cell:nth-child(379) {
  left: calc(19 * calc(100% / 20) - calc(100% / 20));
  top: calc(19 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(379):hover ~ .poster {
  --sx: -40.2631578947px;
  --sy: 4.7368421053px;
  --ry: 13.4210526316deg;
  --rx: -13.4210526316deg;
}
.cell:nth-child(379):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(8.9473684211px, -8.9473684211px, 0px);
}
.cell:nth-child(380) {
  left: calc(20 * calc(100% / 20) - calc(100% / 20));
  top: calc(19 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(380):hover ~ .poster {
  --sx: -45px;
  --sy: 4.7368421053px;
  --ry: 15deg;
  --rx: -13.4210526316deg;
}
.cell:nth-child(380):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(10px, -8.9473684211px, 0px);
}
.cell:nth-child(381) {
  left: calc(1 * calc(100% / 20) - calc(100% / 20));
  top: calc(20 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(381):hover ~ .poster {
  --sx: 45px;
  --sy: 0px;
  --ry: -15deg;
  --rx: -15deg;
}
.cell:nth-child(381):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-10px, -10px, 0px);
}
.cell:nth-child(382) {
  left: calc(2 * calc(100% / 20) - calc(100% / 20));
  top: calc(20 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(382):hover ~ .poster {
  --sx: 40.2631578947px;
  --sy: 0px;
  --ry: -13.4210526316deg;
  --rx: -15deg;
}
.cell:nth-child(382):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-8.9473684211px, -10px, 0px);
}
.cell:nth-child(383) {
  left: calc(3 * calc(100% / 20) - calc(100% / 20));
  top: calc(20 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(383):hover ~ .poster {
  --sx: 35.5263157895px;
  --sy: 0px;
  --ry: -11.8421052632deg;
  --rx: -15deg;
}
.cell:nth-child(383):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-7.8947368421px, -10px, 0px);
}
.cell:nth-child(384) {
  left: calc(4 * calc(100% / 20) - calc(100% / 20));
  top: calc(20 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(384):hover ~ .poster {
  --sx: 30.7894736842px;
  --sy: 0px;
  --ry: -10.2631578947deg;
  --rx: -15deg;
}
.cell:nth-child(384):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-6.8421052632px, -10px, 0px);
}
.cell:nth-child(385) {
  left: calc(5 * calc(100% / 20) - calc(100% / 20));
  top: calc(20 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(385):hover ~ .poster {
  --sx: 26.0526315789px;
  --sy: 0px;
  --ry: -8.6842105263deg;
  --rx: -15deg;
}
.cell:nth-child(385):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-5.7894736842px, -10px, 0px);
}
.cell:nth-child(386) {
  left: calc(6 * calc(100% / 20) - calc(100% / 20));
  top: calc(20 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(386):hover ~ .poster {
  --sx: 21.3157894737px;
  --sy: 0px;
  --ry: -7.1052631579deg;
  --rx: -15deg;
}
.cell:nth-child(386):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-4.7368421053px, -10px, 0px);
}
.cell:nth-child(387) {
  left: calc(7 * calc(100% / 20) - calc(100% / 20));
  top: calc(20 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(387):hover ~ .poster {
  --sx: 16.5789473684px;
  --sy: 0px;
  --ry: -5.5263157895deg;
  --rx: -15deg;
}
.cell:nth-child(387):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-3.6842105263px, -10px, 0px);
}
.cell:nth-child(388) {
  left: calc(8 * calc(100% / 20) - calc(100% / 20));
  top: calc(20 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(388):hover ~ .poster {
  --sx: 11.8421052632px;
  --sy: 0px;
  --ry: -3.9473684211deg;
  --rx: -15deg;
}
.cell:nth-child(388):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-2.6315789474px, -10px, 0px);
}
.cell:nth-child(389) {
  left: calc(9 * calc(100% / 20) - calc(100% / 20));
  top: calc(20 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(389):hover ~ .poster {
  --sx: 7.1052631579px;
  --sy: 0px;
  --ry: -2.3684210526deg;
  --rx: -15deg;
}
.cell:nth-child(389):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-1.5789473684px, -10px, 0px);
}
.cell:nth-child(390) {
  left: calc(10 * calc(100% / 20) - calc(100% / 20));
  top: calc(20 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(390):hover ~ .poster {
  --sx: 2.3684210526px;
  --sy: 0px;
  --ry: -0.7894736842deg;
  --rx: -15deg;
}
.cell:nth-child(390):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(-0.5263157895px, -10px, 0px);
}
.cell:nth-child(391) {
  left: calc(11 * calc(100% / 20) - calc(100% / 20));
  top: calc(20 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(391):hover ~ .poster {
  --sx: -2.3684210526px;
  --sy: 0px;
  --ry: 0.7894736842deg;
  --rx: -15deg;
}
.cell:nth-child(391):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(0.5263157895px, -10px, 0px);
}
.cell:nth-child(392) {
  left: calc(12 * calc(100% / 20) - calc(100% / 20));
  top: calc(20 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(392):hover ~ .poster {
  --sx: -7.1052631579px;
  --sy: 0px;
  --ry: 2.3684210526deg;
  --rx: -15deg;
}
.cell:nth-child(392):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(1.5789473684px, -10px, 0px);
}
.cell:nth-child(393) {
  left: calc(13 * calc(100% / 20) - calc(100% / 20));
  top: calc(20 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(393):hover ~ .poster {
  --sx: -11.8421052632px;
  --sy: 0px;
  --ry: 3.9473684211deg;
  --rx: -15deg;
}
.cell:nth-child(393):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(2.6315789474px, -10px, 0px);
}
.cell:nth-child(394) {
  left: calc(14 * calc(100% / 20) - calc(100% / 20));
  top: calc(20 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(394):hover ~ .poster {
  --sx: -16.5789473684px;
  --sy: 0px;
  --ry: 5.5263157895deg;
  --rx: -15deg;
}
.cell:nth-child(394):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(3.6842105263px, -10px, 0px);
}
.cell:nth-child(395) {
  left: calc(15 * calc(100% / 20) - calc(100% / 20));
  top: calc(20 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(395):hover ~ .poster {
  --sx: -21.3157894737px;
  --sy: 0px;
  --ry: 7.1052631579deg;
  --rx: -15deg;
}
.cell:nth-child(395):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(4.7368421053px, -10px, 0px);
}
.cell:nth-child(396) {
  left: calc(16 * calc(100% / 20) - calc(100% / 20));
  top: calc(20 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(396):hover ~ .poster {
  --sx: -26.0526315789px;
  --sy: 0px;
  --ry: 8.6842105263deg;
  --rx: -15deg;
}
.cell:nth-child(396):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(5.7894736842px, -10px, 0px);
}
.cell:nth-child(397) {
  left: calc(17 * calc(100% / 20) - calc(100% / 20));
  top: calc(20 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(397):hover ~ .poster {
  --sx: -30.7894736842px;
  --sy: 0px;
  --ry: 10.2631578947deg;
  --rx: -15deg;
}
.cell:nth-child(397):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(6.8421052632px, -10px, 0px);
}
.cell:nth-child(398) {
  left: calc(18 * calc(100% / 20) - calc(100% / 20));
  top: calc(20 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(398):hover ~ .poster {
  --sx: -35.5263157895px;
  --sy: 0px;
  --ry: 11.8421052632deg;
  --rx: -15deg;
}
.cell:nth-child(398):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(7.8947368421px, -10px, 0px);
}
.cell:nth-child(399) {
  left: calc(19 * calc(100% / 20) - calc(100% / 20));
  top: calc(20 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(399):hover ~ .poster {
  --sx: -40.2631578947px;
  --sy: 0px;
  --ry: 13.4210526316deg;
  --rx: -15deg;
}
.cell:nth-child(399):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(8.9473684211px, -10px, 0px);
}
.cell:nth-child(400) {
  left: calc(20 * calc(100% / 20) - calc(100% / 20));
  top: calc(20 * calc(100% / 20) - calc(100% / 20));
}
.cell:nth-child(400):hover ~ .poster {
  --sx: -45px;
  --sy: 0px;
  --ry: 15deg;
  --rx: -15deg;
}
.cell:nth-child(400):hover ~ .poster > .poster__layer:nth-child(5) {
  transform: translate3d(10px, -10px, 0px);
}

.poster {
  --sx: 0;
  --sy: 45px;
  --rx: 0deg;
  --ry: 0deg;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  box-shadow: var(--sx) var(--sy) 100px rgba(0, 0, 0, 0.4);
  transform: perspective(1000px) rotateX(var(--rx)) rotateY(var(--ry)) rotateZ(0deg);
  transition: transform 0.15s linear;
  will-change: transform, box-shadow;
}
.poster:after {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  background: linear-gradient(0deg, red 0%, rgba(255, 0, 0, 0) 0%);
  content: "";
  pointer-events: none;
  z-index: 3;
}

.page-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 999999999;
}
.page-loading .page-loading--icon {
  background-color: var(--b1-color-primary);
  width: 96px;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0 16px 21px 0 rgba(0, 0, 0, 0.2);
}
.page-loading .page-loading--icon img {
  width: 48px;
  height: 48px;
}

.page-modal {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.75);
}

ngx-avatar .avatar-container .avatar-content {
  background-color: var(--b1-color-primary) !important;
}

.ngx-select_multiple {
  padding: 0.375rem 0.5rem !important;
  border-radius: 10px;
}
.ngx-select_multiple .ngx-select__selected-plural {
  background-color: var(--b1-color-primary);
  color: #fff;
  border: 0 !important;
  font-size: 0.65rem;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0.3rem 0.5rem;
}
.ngx-select_multiple .ngx-select__selected-plural .ngx-select__clear-icon {
  font-size: 10px;
  width: 10px;
  height: 12px;
  color: #fff;
}
.ngx-select_multiple .ngx-select__search.form-control {
  box-shadow: none !important;
  outline: none !important;
  font-size: 1rem !important;
  border-radius: 0;
  font-size: 1rem !important;
}
.ngx-select_multiple .ngx-select__search.form-control:focus, .ngx-select_multiple .ngx-select__search.form-control:active {
  box-shadow: none !important;
  outline: none !important;
}
.ngx-select_multiple .ngx-select__item-group .ngx-select__item:hover {
  background-color: var(--b1-color-primary) !important;
}
.ngx-select_multiple .ngx-select__item-group .ngx-select__item:active, .ngx-select_multiple .ngx-select__item-group .ngx-select__item:focus {
  background-color: var(--b1-color-primary-shade) !important;
}
.ngx-select_multiple .ngx-select__item-group .ngx-select__item_active {
  background-color: var(--b1-color-primary) !important;
}

.spinner-loader {
  animation: rotate 2s linear infinite;
  width: 1.4rem;
  height: 1.4rem;
}
.spinner-loader .path {
  stroke: #fff;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
.form-text {
  line-height: 1.2;
}

.dropdown-menu {
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.22);
  border: 0;
  min-width: 200px;
}
.dropdown-menu .dropdown-item {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 500;
}
.dropdown-menu .dropdown-item:active, .dropdown-menu .dropdown-item:focus {
  color: #fff;
}
.dropdown-menu .dropdown-item:active fa-icon, .dropdown-menu .dropdown-item:focus fa-icon {
  color: #fff;
}
.dropdown-menu .dropdown-item fa-icon {
  margin-right: 0.5rem;
  color: var(--b1-color-primary);
}

.conversation-status-badge {
  font-size: 0.65rem;
  font-weight: 600;
  padding: 0.25rem 0.4rem;
  border-radius: 22px;
}
.conversation-status-badge.finished {
  color: #000;
  background-color: var(--b1-color-success);
}
.conversation-status-badge.queued {
  color: #000;
  background-color: var(--b1-color-light);
}
.conversation-status-badge.in_progress {
  color: #000;
  background-color: var(--b1-color-warning);
}
.conversation-status-badge.canceled {
  color: #000;
  background-color: var(--b1-color-danger);
}

.breaks-chart .breaks-header {
  display: grid;
  grid-template-columns: 1fr 60px 1fr 1fr 50px;
  gap: 0.5rem;
  padding: 0 0.5rem;
  border-bottom: 2px solid var(--b1-color-light-shade);
}
.breaks-chart .breaks-header .breaks-header--col {
  font-size: 0.8rem;
  font-weight: 600;
  border: 0;
}
.breaks-chart .break-item {
  display: grid;
  grid-template-columns: 1fr 60px 1fr 1fr 50px;
  background-color: #fff;
  padding: 0.5rem;
  gap: 0.5rem;
  border-bottom: 1px solid var(--b1-color-light-shade);
}
.breaks-chart .break-item .break-item--col {
  font-size: 12px;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.chart-bullet {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.toast-container .ngx-toastr {
  min-width: 400px;
}

.popover {
  min-width: 300px;
  border: 0;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.22);
  font-family: "Montserrat", sans-serif;
}
.popover .popover-title,
.popover .popover-content {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
}
.popover .popover-title {
  color: #000;
  font-size: 0.75rem;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  padding: 1rem;
  background-color: transparent;
  border-bottom: 0;
}
.popover .popover-arrow {
  left: 17px !important;
}
.popover .form-actions {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem;
}
.popover .form-actions button {
  width: 100%;
}

.card-body {
  padding-top: 0px;
}

.accordion .card-body {
  padding-top: 0px;
}
.accordion .card {
  width: auto !important;
  height: auto !important;
}
.accordion .card .btn-link {
  text-decoration: none !important;
  color: #2c71df !important;
  font-weight: 600;
}
.accordion .card .btn-link:hover {
  box-shadow: none !important;
  background-color: transparent !important;
  background-image: none !important;
  color: #2c71df !important;
}
.accordion .card .btn-link:focus {
  text-decoration: none !important;
}