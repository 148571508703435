@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Bold Italic"), local("Montserrat-BoldItalic"),
    url("/assets/fonts/subset-Montserrat-BoldItalic.woff2") format("woff2"),
    url("/assets/fonts/subset-Montserrat-BoldItalic.woff") format("woff"),
    url("/assets/fonts/subset-Montserrat-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Thin"), local("Montserrat-Thin"),
    url("/assets/fonts/subset-Montserrat-Thin.woff2") format("woff2"),
    url("/assets/fonts/subset-Montserrat-Thin.woff") format("woff"),
    url("/assets/fonts/subset-Montserrat-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat ExtraLight"), local("Montserrat-ExtraLight"),
    url("/assets/fonts/subset-Montserrat-ExtraLight.woff2") format("woff2"),
    url("/assets/fonts/subset-Montserrat-ExtraLight.woff") format("woff"),
    url("/assets/fonts/subset-Montserrat-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Black"), local("Montserrat-Black"),
    url("/assets/fonts/subset-Montserrat-Black.woff2") format("woff2"),
    url("/assets/fonts/subset-Montserrat-Black.woff") format("woff"),
    url("/assets/fonts/subset-Montserrat-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Regular"), local("Montserrat-Regular"),
    url("/assets/fonts/subset-Montserrat-Regular.woff2") format("woff2"),
    url("/assets/fonts/subset-Montserrat-Regular.woff") format("woff"),
    url("/assets/fonts/subset-Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat ExtraBold Italic"), local("Montserrat-ExtraBoldItalic"),
    url("/assets/fonts/subset-Montserrat-ExtraBoldItalic.woff2") format("woff2"),
    url("/assets/fonts/subset-Montserrat-ExtraBoldItalic.woff") format("woff"),
    url("/assets/fonts/subset-Montserrat-ExtraBoldItalic.ttf")
      format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Black Italic"), local("Montserrat-BlackItalic"),
    url("/assets/fonts/subset-Montserrat-BlackItalic.woff2") format("woff2"),
    url("/assets/fonts/subset-Montserrat-BlackItalic.woff") format("woff"),
    url("/assets/fonts/subset-Montserrat-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Medium Italic"), local("Montserrat-MediumItalic"),
    url("/assets/fonts/subset-Montserrat-MediumItalic.woff2") format("woff2"),
    url("/assets/fonts/subset-Montserrat-MediumItalic.woff") format("woff"),
    url("/assets/fonts/subset-Montserrat-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Light Italic"), local("Montserrat-LightItalic"),
    url("/assets/fonts/subset-Montserrat-LightItalic.woff2") format("woff2"),
    url("/assets/fonts/subset-Montserrat-LightItalic.woff") format("woff"),
    url("/assets/fonts/subset-Montserrat-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Medium"), local("Montserrat-Medium"),
    url("/assets/fonts/subset-Montserrat-Medium.woff2") format("woff2"),
    url("/assets/fonts/subset-Montserrat-Medium.woff") format("woff"),
    url("/assets/fonts/subset-Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Italic"), local("Montserrat-Italic"),
    url("/assets/fonts/subset-Montserrat-Italic.woff2") format("woff2"),
    url("/assets/fonts/subset-Montserrat-Italic.woff") format("woff"),
    url("/assets/fonts/subset-Montserrat-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat ExtraBold"), local("Montserrat-ExtraBold"),
    url("/assets/fonts/subset-Montserrat-ExtraBold.woff2") format("woff2"),
    url("/assets/fonts/subset-Montserrat-ExtraBold.woff") format("woff"),
    url("/assets/fonts/subset-Montserrat-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat SemiBold Italic"), local("Montserrat-SemiBoldItalic"),
    url("/assets/fonts/subset-Montserrat-SemiBoldItalic.woff2") format("woff2"),
    url("/assets/fonts/subset-Montserrat-SemiBoldItalic.woff") format("woff"),
    url("/assets/fonts/subset-Montserrat-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat ExtraLight Italic"),
    local("Montserrat-ExtraLightItalic"),
    url("/assets/fonts/subset-Montserrat-ExtraLightItalic.woff2")
      format("woff2"),
    url("/assets/fonts/subset-Montserrat-ExtraLightItalic.woff") format("woff"),
    url("/assets/fonts/subset-Montserrat-ExtraLightItalic.ttf")
      format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Bold"), local("Montserrat-Bold"),
    url("/assets/fonts/subset-Montserrat-Bold.woff2") format("woff2"),
    url("/assets/fonts/subset-Montserrat-Bold.woff") format("woff"),
    url("/assets/fonts/subset-Montserrat-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Light"), local("Montserrat-Light"),
    url("/assets/fonts/subset-Montserrat-Light.woff2") format("woff2"),
    url("/assets/fonts/subset-Montserrat-Light.woff") format("woff"),
    url("/assets/fonts/subset-Montserrat-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Thin Italic"), local("Montserrat-ThinItalic"),
    url("/assets/fonts/subset-Montserrat-ThinItalic.woff2") format("woff2"),
    url("/assets/fonts/subset-Montserrat-ThinItalic.woff") format("woff"),
    url("/assets/fonts/subset-Montserrat-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"),
    url("/assets/fonts/subset-Montserrat-SemiBold.woff2") format("woff2"),
    url("/assets/fonts/subset-Montserrat-SemiBold.woff") format("woff"),
    url("/assets/fonts/subset-Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "icomoon";
  src: url("/assets/fonts/icomoon.eot?w7btq8");
  src: url("/assets/fonts/icomoon.eot?w7btq8#iefix") format("embedded-opentype"),
    url("/assets/fonts/icomoon.ttf?w7btq8") format("truetype"),
    url("/assets/fonts/icomoon.woff?w7btq8") format("woff"),
    url("/assets/fonts/icomoon.svg?w7btq8#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

:root {
  --b1-color-primary: #2c71df;
  --b1-color-primary-rgb: 44, 113, 223;
  --b1-color-primary-contrast: #ffffff;
  --b1-color-primary-contrast-rgb: 255, 255, 255;
  --b1-color-primary-shade: #2763c4;
  --b1-color-primary-tint: #417fe2;

  --b1-color-secondary: #4ba3f3;
  --b1-color-secondary-rgb: 75, 163, 243;
  --b1-color-secondary-contrast: #000000;
  --b1-color-secondary-contrast-rgb: 0, 0, 0;
  --b1-color-secondary-shade: #428fd6;
  --b1-color-secondary-tint: #5dacf4;

  --b1-color-tertiary: #183754;
  --b1-color-tertiary-rgb: 24, 55, 84;
  --b1-color-tertiary-contrast: #ffffff;
  --b1-color-tertiary-contrast-rgb: 255, 255, 255;
  --b1-color-tertiary-shade: #15304a;
  --b1-color-tertiary-tint: #2f4b65;

  --b1-color-success: #7ed421;
  --b1-color-success-rgb: 126, 212, 33;
  --b1-color-success-contrast: #000000;
  --b1-color-success-contrast-rgb: 0, 0, 0;
  --b1-color-success-shade: #6fbb1d;
  --b1-color-success-tint: #8bd837;

  --b1-color-warning: #ffc827;
  --b1-color-warning-rgb: 217, 140, 7;
  --b1-color-warning-contrast: #000000;
  --b1-color-warning-contrast-rgb: 0, 0, 0;
  --b1-color-warning-shade: #bf7b06;
  --b1-color-warning-tint: #dd9820;

  --b1-color-warning-2: #d98c07;

  --b1-color-danger: #d9160f;
  --b1-color-danger-rgb: 217, 22, 15;
  --b1-color-danger-contrast: #ffffff;
  --b1-color-danger-contrast-rgb: 255, 255, 255;
  --b1-color-danger-shade: #bf130d;
  --b1-color-danger-tint: #dd2d27;

  --b1-color-dark: #2b3035;
  --b1-color-dark-rgb: 43, 48, 53;
  --b1-color-dark-contrast: #ffffff;
  --b1-color-dark-contrast-rgb: 255, 255, 255;
  --b1-color-dark-shade: #262a2f;
  --b1-color-dark-tint: #404549;

  --b1-color-dark-high-opacity: rgba(0, 0, 0, 0.7);
  --b1-color-dark-medium-opacity: rgba(0, 0, 0, 0.5);
  --b1-color-dark-low-opacity: rgba(0, 0, 0, 0.3);

  --b1-color-medium: #7b7b7b;
  --b1-color-medium-rgb: 123, 123, 123;
  --b1-color-medium-contrast: #ffffff;
  --b1-color-medium-contrast-rgb: 255, 255, 255;
  --b1-color-medium-shade: #6c6c6c;
  --b1-color-medium-tint: #888888;

  --b1-color-light: #f4f5f8;
  --b1-color-light-rgb: 244, 244, 244;
  --b1-color-light-contrast: #000000;
  --b1-color-light-contrast-rgb: 0, 0, 0;
  --b1-color-light-shade: #d7d8da;
  --b1-color-light-tint: #f5f6f9;

  --b1-color-light-high-opacity: rgba(244, 245, 248, 0.8);
  --b1-color-light-medium-opacity: rgba(244, 245, 248, 0.5);
  --b1-color-light-low-opacity: rgba(244, 245, 248, 0.3);

  --b1-color-gray-1: #353a40;
  --b1-color-gray-2: #d7d7d7;
}

body {
  padding-right: 0 !important;
  font-size: 16px;
  font: 120% !important;
  font-family: "Montserrat", sans-serif !important;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  overflow: auto !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:before {
    content: "";
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: white;
    background: url("/assets/img/wallpapers/b1-670925-unsplash.jpg") no-repeat
      center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    will-change: transform;
    z-index: -2;
  }
}

// DEFAULTS
label {
  margin: 0;
  text-transform: uppercase;
  font-size: 0.6rem;
  font-weight: 700;
  letter-spacing: 0.5px;
}

a {
  text-transform: uppercase;
  font-size: 0.6rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  &.-icon {
    color: white;
    font-size: inherit;
    cursor: pointer;
    transition: all 300ms;
    &:hover {
      transform: scale(1.2);
    }
  }
  &.-name {
    color: white;
  }
}

ul {
  list-style: none;
  padding-inline-start: 0;
}

h3 {
  text-transform: uppercase;
  font-size: 1rem;
  color: white;
}

h4 {
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

p {
  font-weight: 500;
}

.modal-header {
  .modal-title {
    margin-bottom: 0;
    line-height: 1.5;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 0.5px;
  }
  .close {
    margin: 0;
    width: 30px;
    height: 30px;
    span {
      font-size: 1.3rem;
      text-align: center;
      padding-left: 2px;
    }
  }
}

:host .swal2-popup {
  .swal2-content {
    font-weight: 600;
  }
  .swal2-title {
    line-height: 1rem;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 700;
    letter-spacing: 0.5px;
  }
}

.mat-input-element {
  font-family: "Montserrat" !important;
}
.date-input {
  background: rgba(0, 0, 0, 0.7);
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  height: 50px;

  .mat-form-field-infix {
    border-top: 0 solid transparent !important;
  }
  .mat-form-field {
    font-weight: 600 !important;
    line-height: 30px !important;
  }

  .mat-icon-button {
    height: 30px !important;
    width: 30px !important;
    display: flex !important;
    margin-bottom: 0 !important;
    span {
      font-size: 1rem !important;
    }
  }
  .mat-form-field-underline {
    height: 0 !important;
  }
  .mat-form-field-hide-placeholder {
    font-size: 0.6rem !important;
    font-weight: 700 !important;
    letter-spacing: 0.5px !important;
    opacity: 1 !important;
  }
  .mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: 0.8em 0 !important;
    width: 130px !important;
  }
  input {
    font-size: 1rem !important;
    font-weight: 600 !important;
    height: 30px !important;
    padding: 0 0.5rem !important;
    text-align: center !important;

    &::placeholder {
      font-size: 0.6rem !important;
      font-weight: 700 !important;
      letter-spacing: 0.5px !important;
    }
    &:focus {
      outline: none;
      box-shadow: inherit !important;
    }
  }
  button {
    background-color: transparent !important;
  }
}

input,
textarea,
select {
  border-radius: 2rem;
  border: 0;
  height: 2.5rem;
  padding: 0 1rem;
  font-size: 0.85rem;
  font-weight: 500;
  background-color: var(--b1-color-light);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  &:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(183, 183, 183, 0.5);
  }
}
input:read-only,
textarea:read-only {
  background-color: rgba(183, 183, 183, 0.08);
  // color: white;
  padding: 0 1rem;
  font-weight: 700;
  &::placeholder {
    color: white;
    opacity: 1;
  }
}
textarea {
  border-radius: 1rem;
  width: 100%;
  min-height: 200px;
  height: inherit;
  padding: 1rem;
}

input[type="radio"] {
  height: inherit;
}
input[type="checkbox"] {
  height: inherit;
  padding: 0.3rem 0;
}

.h30w30 {
  width: 30px;
  height: 30px;
}

button,
a.button {
  height: 2.5rem;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: inherit;
  background-color: var(--b1-color-primary);
  text-align: center;
  color: white;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 0.55rem;
  font-weight: 800;
  padding: 0 0.75rem;
  position: relative;
  border: 0;
  transition: all 0.15s ease-in-out;
  &:hover {
    box-shadow: 0 16px 21px 0 rgba(0, 0, 0, 0.2);
    background-image: linear-gradient(
      180deg,
      var(--b1-color-primary),
      var(--b1-color-secondary-tint) 98%
    ) !important;
    // background-blend-mode: multiply;
    cursor: pointer;
    color: white !important;
    text-decoration: none;
  }
  &:focus {
    outline: none;
  }
}

.btn--25px-h {
  height: 1.56rem;
}

.btn--Orange {
  background-color: var(--b1-color-warning) !important;
  color: black !important;
}

.btn--White {
  background-color: white !important;
  color: black !important;
}

.btn--White-o {
  background-color: rgba(255, 255, 255, 0.1) !important;
  border: 2px solid white !important;
  color: white !important;
}

.btn--Danger {
  background-color: var(--b1-color-danger) !important;
  color: #fff !important;

  &:hover {
    background-color: var(--b1-color-danger) !important;
    background-image: linear-gradient(
      180deg,
      var(--b1-color-danger),
      var(--b1-color-danger-tint) 98%
    ) !important;
  }
}

.btn--Warning {
  background-color: var(--b1-color-warning) !important;
  color: var(--b1-color-warning-contrast) !important;

  &:hover {
    background-color: var(--b1-color-warning-tint) !important;
  }
}

.btn--Success {
  background-color: var(--b1-color-success-shade) !important;
  color: #fff !important;

  &:hover {
    background-color: var(--b1-color-success) !important;
  }
}

.btn--Circle {
  width: 2.5rem;
  height: 2.5rem;
}

.overflow-visible {
  overflow: visible;
}

//BASE LAYOUT - CSS GRID

.main--Grid {
  display: grid;
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
  grid-template-rows: [topmenu] 70px [body] 100%;
  grid-template-columns: [mainmenu] 65px [conteudo] 12fr;
  min-height: 100%;
  padding: 0.5rem;
  // overflow: scroll;
  // height: 100vh;
}

.top--Nav {
  grid-row: topmenu;
  grid-column: conteudo;
  padding-top: 0.3rem;
  z-index: 999;
}

.main--Menu {
  grid-row: topmenu;
  grid-column: mainmenu;
  margin: 0.3rem;
  position: sticky;
  left: 0;
  top: 0;
  bottom: 0;
}

.conteudo {
  grid-row: body;
  grid-column: conteudo;
  min-height: 100%;
  min-width: 0;
}

app-home {
  grid-row: body;
  grid-column: conteudo;
  min-height: 100%;
  position: relative;
}

app-filas {
  grid-row: topmenu;
  grid-column: conteudo;
  grid-column-start: span 2;
  min-height: 100%;
  position: relative;
}

app-operador {
  grid-row: body;
  grid-column: conteudo;
}

app-admin {
  grid-row: body;
  grid-column: conteudo;
}

.footer {
  grid-row: footer;
  grid-column: conteudo;
  grid-row-start: 3;
  grid-row-end: 3;
  display: none;
  align-items: center;
  justify-content: center;
  .footer--Text {
    color: white;
    text-transform: uppercase !important;
    font-size: 0.6rem;
    letter-spacing: 1px;
    font-weight: 600;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
  .logo--Footer {
    max-width: 60px;
    height: auto;
    padding-right: 1rem;
  }
}

//Icons

// 2020

.ico--Login {
  background-image: url("/assets/img/icos/ico-inicio.svg");
}
.ico--Inicio {
  background-image: url("/assets/img/icos/ico-inicio.svg");
}
.ico--Dashboard {
  background-image: url("/assets/img/icos/2020/ico-dashboard.svg");
}
.ico--Vendas {
  background-image: url("/assets/img/icos/2020/ico-vendas.svg");
}
.ico--Supervisao {
  background-image: url("/assets/img/icos/ico-supervisao.svg");
}
.ico--Tickets {
  background-image: url("/assets/img/icos/2020/ico-tickets.svg");
}
.ico--Contas {
  background-image: url("/assets/img/icos/2020/ico-conta.svg");
}
.ico--Contato {
  background-image: url("/assets/img/icos/2020/ico-contato.svg");
}
.ico--Sair {
  background-image: url("/assets/img/icos/2020/ico-sair.svg");
}
.ico--Busca {
  background-image: url("/assets/img/icos/ico-busca.svg");
}
.ico--Inbox {
  background-image: url("/assets/img/icos/2020/ico-inbox.svg");
}
.ico--Filtro {
  background-image: url("/assets/img/icos/ico-filtro.svg");
}
.ico--Calendario {
  background-image: url("/assets/img/icos/2020/ico-calendario.svg");
}
.ico--Push {
  background-image: url("/assets/img/icos/ico-push.svg");
}
.ico--Voz {
  background-image: url("/assets/img/icos/ico-voz.svg");
}
.ico--Marketing {
  background-image: url("/assets/img/icos/2020/ico-marketing.svg");
}
.ico--Campanhas {
  background-image: url("/assets/img/icos/ico-campanhas.svg");
}
.ico--Up {
  background-image: url("/assets/img/icos/ico-Up.svg");
}
.ico--AceiteVoz {
  background-image: url("/assets/img/icos/ico-aceite-voz.svg");
}
.ico--Exames {
  background-image: url("/assets/img/icos/ico-exames.svg");
}
.ico--Bpm {
  background-image: url("/assets/img/icos/2020/ico-bpm.svg");
}
.ico--Reload {
  background-image: url("/assets/img/icos/2020/ico-reload.svg");
}
.ico--Components {
  background-image: url("/assets/img/icos/2020/ico-components.svg");
}
.ico--Admin {
  background-image: url("/assets/img/icos/2020/ico-admin.svg");
}
.ico--Projetos {
  background-image: url("/assets/img/icos/2020/ico-projeto.svg");
}
.ico--Recording {
  background-image: url("/assets/img/icos/ico-recordings.svg");
}
.ico--Operador {
  background-image: url("/assets/img/icos/2020/ico-operador.svg");
}
.ico--Supervisor {
  background-image: url("/assets/img/icos/2020/ico-users.svg");
}
.ico--Lead {
  background-image: url("/assets/img/icos/2020/ico-lead.svg");
}
.ico--Monitoring {
  background-image: url("/assets/img/icos/2020/ico-monitoring.svg");
}
.ico--Copy {
  width: 26px;
  line-height: 26px;
  margin: 0 0 0 1.5px !important;
  font-family: "icomoon" !important;
  font-weight: 600;
  font-size: 1rem;
  display: block;
  transition: all 0.2s ease-in;
}
.ico--Copy:before {
  content: "\e900";
}

.icon.settings {
  font-size: 1rem;
  width: 24px;
  height: 24px;
  text-align: center;
  border: 1px solid transparent;
  &:hover {
    cursor: pointer;
    color: var(--b1-color-warning);
    animation: rotation 3s infinite linear;
    -webkit-animation: rotation 3s infinite linear;
  }
}
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

.user--Avatar {
  display: block;
  // width: 50px;
  // min-width: 50px;
  // height: 50px;
  background-color: transparent !important;
  background-size: contain;
  background-position: center;
  background-image: url("/assets/img/avatar.png");
  background-repeat: no-repeat;
  object-fit: cover;
  object-position: center;
  div {
    background-color: transparent !important;
  }
}

// Boxes

.box--white-default {
  background: white;
  border: 1px solid white;
  box-shadow: 0 2px 12px 0 rgba(var(--b1-color-dark-high-opacity));
  border-radius: 1rem;
  padding: 1px !important;
  // max-width: 430px;

  .box--DarkInner {
    background: rgba(0, 0, 0, 0.58) !important;
    color: white !important;

    hr {
      width: 100%;
      background-color: rgba(255, 255, 255, 0.1);
      color: rgba(255, 255, 255, 0.1);
      height: 1px;
      border: none;
    }
  }

  .input--Width {
    max-width: 320px;
    width: 320px;
  }
}

.box--black-default {
  background: var(--b1-color-dark-high-opacity);
  border: 1px solid var(--b1-color-dark-high-opacity);
  box-shadow: 0 16px 21px 0 rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  padding: 1px !important;
  // max-width: 430px;

  .box--Inner {
    background: var(--b1-color-dark-high-opacity);
    color: var(--b1-color-light);
    padding: 0.3rem;
    hr {
      width: 100%;
      background-color: var(--b1-color-light-low-opacity);
      color: var(--b1-color-light-low-opacity);
      height: 1px;
      border: none;
    }
  }
  input:read-only {
    color: white;
  }
}

.box--blue-primary {
  background: var(--b1-color-primary);
  border: 1px solid var(--b1-color-primary);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
  border-radius: 1rem;
  padding: 1px !important;

  .box--Inner {
    background-color: white;
    padding: 1rem;
  }

  .input--Width {
    max-width: 320px;
    width: 320px;
  }
}

.box--blue-secondary {
  background: var(--b1-color-secondary);
  border: 1px solid var(--b1-color-secondary);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
  border-radius: 1rem;
  padding: 1px !important;

  .input--Width {
    max-width: 320px;
    width: 320px;
  }
}

.box--green-default {
  background: var(--b1-color-success);
  border: 1px solid var(--b1-color-success);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  padding: 1px !important;

  .input--Width {
    max-width: 320px;
    width: 320px;
  }
}

.box--gray-default-dark {
  background-color: var(--b1-color-dark) !important;
  border: 1px solid var(--b1-color-dark);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
  border-radius: 1rem !important;
  padding: 1px !important;

  .box--Inner {
    background-color: var(--b1-color-medium);
    .input--Width {
      max-width: 320px;
      width: 320px;
    }
    color: white;
    label {
      color: white;
    }
  }
  input:read-only {
    color: white;
  }
}

.box--gray-default-medium {
  background-color: var(--b1-color-light) !important;
  border: 1px solid var(--b1-color-light);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
  border-radius: 1rem !important;
  padding: 1px !important;

  .box--Inner {
    background-color: var(--b1-color-medium);
    .input--Width {
      max-width: 320px;
      width: 320px;
    }
    color: white;
    label {
      color: white;
    }
  }
  input:read-only {
    color: white;
  }
}

.box--gray-default-light {
  background-color: var(--b1-color-dark) !important;
  border: 1px solid var(--b1-color-dark);
  box-shadow: 0 2px 12px 0 var(--b1-color-dark-low-opacity);
  border-radius: 1rem !important;
  .box--Inner {
    background-color: var(--b1-color-light);
    color: var(--b1-color-light-contrast);
    padding: 0.3rem;
  }
  input:read-only {
    color: var(--b1-color-dark);
  }
}

.box--ReddefaultAlpha {
  background-color: rgba(211, 87, 87, 0.6) !important;
  border: 1px solid var(--b1-color-danger);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
  border-radius: 1rem !important;
  padding: 1px !important;
  background-blend-mode: multiply;
  .box--Inner {
    background-color: var(--b1-color-light);
    .input--Width {
      max-width: 320px;
      width: 320px;
    }
  }
}

.box--Reddefault {
  background-color: var(--b1-color-danger) !important;
  border: 1px solid var(--b1-color-danger);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
  border-radius: 1rem !important;
  padding: 1px !important;
  .box--Inner {
    background-color: var(--b1-color-light);
    .input--Width {
      max-width: 320px;
      width: 320px;
    }
  }
}

.box--Inner {
  // background-color: white;
  // padding: 1rem;
  border-radius: 0.95rem;
  display: flex;
  justify-content: center;
}

.border--Radius {
  padding: 1rem;
  border-radius: 0.95rem;
}

.box--Title {
  font-size: 0.7rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  height: 2.5rem;
  color: white;
  display: flex;
  align-items: center;
  padding: 0 calc(1rem + 1px);
  overflow: hidden;
  label {
    margin: 0;
  }
  a {
    display: inline-block;
  }
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 0;
}

.name--Listed {
  text-transform: uppercase;
  background-color: white;
  color: var(--b1-color-secondary);
  font-weight: 700;
  font-size: 0.75rem;
  letter-spacing: 0.5px;
  border-radius: 20px;
  padding: 1px 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  display: block;
  text-align: center;
}

.fix-height {
  height: 2.5rem !important;
}

// Images

button:disabled {
  opacity: 0.7;
}

.modal--CreateLead-wrapper {
  max-width: 1280px !important;
  width: 100% !important;
  padding-left: 40px;
  padding-right: 40px;

  .modal-body {
    max-height: 75vh;
    overflow-x: auto;

    .form-box {
      border: 1px solid #dee2e6;
      padding: 1rem;
      border-radius: 15px;
      margin-bottom: 1rem;
    }
  }

  input:read-only {
    color: white;
  }
}

.tools fa-icon {
  color: #000 !important;
}

hr {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.1);
  height: 1px;
  border: none;
}

.b1-table {
  border-radius: 1rem;
  overflow: hidden;

  .header {
    th {
      border-top: 0;
    }
  }

  tr:hover {
    cursor: pointer;
  }
}
.drop-image {
  padding: 40px;
  border-radius: 15px;
  background: #f0f0f0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.1s all ease-in;

  &:hover {
    box-shadow: 0 2px 4px rgba(#000, 0.12);
  }

  h4 {
    font-size: 14px;
    font-weight: bold;
    color: #444;
  }

  input {
    display: none;
  }
}

.modal-title {
  color: #000;
}

.modal-create-lead-body,
.modal--Account-body,
.person-type-toggler,
.custom-toggler,
.btn-toggler {
  .btn-group-toggle {
    position: relative;

    label {
      background-color: #c9c8cd !important;
      color: #000 !important;
      font-size: 0.55rem !important;
      height: 28px;
      border-radius: 2rem !important;
      text-transform: uppercase;
      border: 0 !important;
      display: flex;
      align-items: center;
      font-weight: 800 !important;
      z-index: 4;
      justify-content: center !important;
      line-height: 1 !important;

      &.active {
        background-color: var(--b1-color-primary) !important;
        color: #fff !important;
        z-index: 5;
        &:first-of-type {
          padding-right: 0.9rem !important;
        }

        &:last-of-type {
          margin-left: -2rem !important;
          padding-left: 0.9rem !important;
        }
      }

      &:first-of-type {
        padding-right: 2.4rem !important;
      }
      &:last-of-type {
        margin-left: -2rem !important;
        padding-left: 2.4rem !important;
      }
    }
  }

  .section-title {
    label {
      padding-top: 0 !important;
      padding-left: 0 !important;
    }
  }
}

.readonly-field {
  color: #fff;
}

.text-white {
  color: #fff !important;
}

.tools {
  fa-icon {
    color: white;
    &:hover {
      cursor: pointer;
      color: var(--amarelo-neon);
    }
  }
}

.error-helper {
  color: var(--b1-color-danger);
  font-size: 0.8rem;
  margin-top: 0.5rem;
  display: block;
  padding-left: 0.5rem;
}

.cursor-pointer {
  cursor: pointer !important;
}

.tab-toggler {
  .btn-group-toggle {
    position: relative;

    label {
      background-color: #c9c8cd !important;
      color: #000 !important;
      font-size: 0.55rem !important;
      height: 28px;
      border-radius: 2rem !important;
      text-transform: uppercase;
      border: 0 !important;
      display: flex;
      align-items: center;
      font-weight: 800 !important;
      z-index: 4;
      justify-content: center !important;
      line-height: 1 !important;

      &.active {
        background-color: var(--b1-color-primary) !important;
        color: #fff !important;
        z-index: 5;
        &:first-of-type {
          padding-right: 0.9rem !important;
        }

        &:last-of-type {
          margin-left: -2rem !important;
          padding-left: 0.9rem !important;
        }
      }

      &:first-of-type {
        padding-right: 2.4rem !important;
      }
      &:last-of-type {
        margin-left: -2rem !important;
        padding-left: 2.4rem !important;
      }
    }
  }
}

.break {
  flex-basis: 100%;
  height: 0;
}

input.ng-invalid.ng-touched,
select.ng-invalid.ng-touched,
textarea.ng-invalid.ng-touched {
  border: 1px solid var(--b1-color-danger);
}

.form-group {
  label {
    display: block;
  }

  input,
  select {
    width: 100%;
  }
}

.form-group {
  label.has-error {
    color: var(--b1-color-danger) !important;
  }
}

.required {
  color: var(--b1-color-danger);
  font-weight: 600;
}

// ::ng-deep .box--Inner {
//   padding: 2rem 0;
// }

.formulario,
::ng-deep .formulario {
  width: 100%;
  max-width: 500px;
  margin: auto;
}

.dropdown-menu {
  li {
    .dropdown-item {
      padding: 0.75rem;
      font-size: 0.7rem;

      &:focus,
      &:active {
        background-color: var(--b1-color-primary) !important;
      }
    }
  }
}

bs-datepicker-container {
  z-index: 9999;
}

.orders-header {
  width: calc(100%);
  display: grid;
  grid-template-columns: [avatar] 50px [nome] 1fr [conta] 1fr [telefone] 1fr [tipo] 1fr;

  .orders-header--col {
    text-transform: uppercase;
    font-size: 0.6rem;
    font-weight: 700;
    letter-spacing: 0.5px;
    color: #fff;
    text-align: center;
    padding: 0.5rem;
    flex: 1;
  }
}

.oex-item {
  height: auto;
  margin: 2px auto;
  width: 100%;
  transition: all 0.15s ease-in-out;
  cursor: pointer;

  &.active,
  &:hover {
    .oex-item--inner {
      background-image: linear-gradient(
        180deg,
        var(--b1-color-primary),
        var(--b1-color-secondary-tint) 98%
      ) !important;
    }
  }

  &.expanded {
    height: 141px;

    .oex-item--inner {
      background-image: linear-gradient(
        180deg,
        var(--azul-quarteario),
        var(--b1-color-secondary) 98%
      ) !important;

      .info--expandable-info {
        display: block !important;
      }
    }
  }

  .oex-item--inner {
    display: flex;
    background-image: linear-gradient(180deg, #343a40 0%, #2b3035 98%);
    border-radius: 1rem;
    transition: all 0.15s ease-in-out;

    &.delayed {
      background-image: linear-gradient(180deg, black 0%, #2c2c2c 98%);
    }

    .info--expandable-info {
      display: none;
      position: relative;

      .info--steps {
        display: flex;

        .info--step {
          flex: 1;
          padding: 1.5rem 1rem;
          text-align: center;
          font-size: 0.6rem;
          font-weight: 700;
          letter-spacing: 0.5px;
          text-transform: uppercase;

          &:first-of-type {
            text-align: left;
          }

          &:last-of-type {
            text-align: right;
          }

          .step--title {
            color: #fff;

            fa-icon {
              margin-left: 0.5rem;
            }
          }

          .step--description {
            margin-top: 1rem;
            font-size: 0.6rem !important;
            line-height: 25px;
            position: relative;
            z-index: 5;

            .step--status {
              height: 25px;
              padding: 0 1rem;
              background-color: var(--amarelo-neon);
              border-radius: 25px;
              display: inline-block;
              box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
            }
          }
        }
      }

      .info--progress {
        background-color: #fff;
        position: relative;
        border-radius: 1rem;
        height: 20px;
        width: calc(100% - 1rem);
        margin: auto;
        margin-top: calc(1.5rem * (-1) - 22px);
        z-index: 2;

        .current-progress {
          background-color: var(--b1-color-success);
          height: 20px;
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 1rem;
        }
      }
    }

    .oex-item--info {
      flex: 1;
      // min-height: 65px;

      .info--details {
        display: grid;
        // grid-template-columns: [avatar] 50px [nome] 1fr [conta] 1fr [telefone] 1fr [tipo] 1fr;
        grid-template-columns: repeat(4, minmax(50px, 1fr));
        height: auto;

        .oex-item--col {
          color: black;
          align-items: center;
          text-transform: uppercase;
          font-size: 0.6rem;
          font-weight: 700;
          letter-spacing: 0.5px;
          padding: 0.5rem;
          display: flex;
          justify-content: center;
          word-wrap: break-word;

          button {
            background-color: transparent !important;
            color: #fff !important;
            padding: 0 !important;
            font-size: 16px !important;
          }

          &.col-avatar {
            grid-column: avatar;
          }

          &.col-name {
            justify-content: flex-start;
            grid-column: nome;
            background: white;
            border-bottom-left-radius: 0.5rem;
            border-top-left-radius: 0.5rem;
            margin: 0.2rem 0;
          }

          &.col-account {
            grid-column: conta;
            background: white;
            margin: 0.2rem 0;
          }

          &.col-phone {
            grid-column: telefone;
            grid-column: conta;
            background: white;
            margin: 0.2rem 0;
          }

          &.col-type {
            grid-column: tipo;
            background: white;
            border-bottom-right-radius: 0.5rem;
            border-top-right-radius: 0.5rem;
            margin: 0.2rem 0;
          }
        }
      }
    }

    .oex-item--actions {
      width: 150px;
      display: flex;
      flex-direction: column;
      border-top-right-radius: 1rem;
      border-bottom-right-radius: 1rem;
      overflow: hidden;

      .actions--status {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background-color: var(--b1-color-success);
        text-transform: uppercase;
        font-size: 0.6rem;
        font-weight: 700;
        letter-spacing: 0.5px;
        padding: 0.5rem 1rem;
        box-shadow: inset 0 2px 12px 0 rgba(0, 0, 0, 0.3);

        &.pending {
          background-color: var(--b1-color-warning) !important;
        }

        &.provided {
          background-color: var(--amarelo-neon) !important;
          color: black !important;
        }

        &.delayed {
          background-color: var(--b1-color-danger) !important;
        }

        &.finished {
          background-color: var(--b1-color-success) !important;
        }

        &.canceled {
          background-color: var(--b1-color-danger) !important;
        }
      }

      .actions--buttons {
        display: flex;
        background-color: #fff;
        padding: 0 1rem;
        justify-content: center;

        button {
          background-color: transparent !important;
          padding: 0.3rem 0.5rem !important;
          margin-right: 0.5rem !important;
          height: auto !important;
          font-size: 14px !important;
          color: var(--azul-terciario) !important;

          &:last-of-type {
            margin-right: 0;
          }

          &.cancel {
            color: var(--b1-color-danger) !important;
          }
        }
      }
    }
  }
}

// Lista Padrão B1

.b1-list {
  border-radius: 10px;
  padding: 3px;
  min-height: 52px;
  display: flex;
  align-items: center;
  margin: 0.15rem 0;
  background-color: rgba(0, 0, 0, 0.7);
  transition: background-color 0.5s ease, transform 0.25s ease;
  &:hover {
    background-color: rgba(0, 0, 0, 0.95);
    // transform: scale(1.005)  translateZ(0);
    backface-visibility: hidden;
    cursor: pointer;
  }
  .--avatar {
    width: 44px;
    min-width: 44px;
    height: 44px;
    margin: 0 0.3rem;
    border-radius: 22px;
    overflow: hidden;
    background-color: var(--b1-color-primary-contrast);
    img {
      width: 100%;
      height: auto;
    }
  }
  .--rows {
    width: 100%;
    background-color: white;
    display: grid;
    grid-template-columns: repeat(4, minmax(50px, 1fr));
    height: auto;
    text-transform: uppercase;
    color: black;
    border-radius: 7px;
    padding: 0.5rem 0.3rem;
    font-size: 0.6rem;
    font-weight: 700 !important;
    justify-items: center;
    align-items: center;
    .--cells {
      width: 100%;
      text-align: center;
      &.--name {
        text-align: left;
        padding-left: 0.5rem;
      }
      .display_name {
        font-size: 0.875rem !important;
        line-height: 1.1;
      }
    }
  }
}

.gray-round-background {
  letter-spacing: normal !important;
  height: auto;
  padding: 0.344rem 0.7rem !important;
  border-radius: 25px;
  text-align: center;
  background-color: var(--b1-color-light);
}

.modal--Details,
.formulario {
  .form-group {
    label {
      padding-left: 1rem;
    }
  }
}

ngx-avatar {
  .avatar-content {
    object-fit: cover;
    object-position: center;
  }
}

.simple-keyboard,
.name-keyboard {
  .hg-row {
    .hg-button {
      span {
        color: #252525 !important;
        font-weight: 600;
      }
    }
  }
}
